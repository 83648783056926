.storage-rates {
  &__main {
    position: relative;
    height: 30rem;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__main-content {
    height: 30rem;
    flex-grow: 1;
    width: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }
}
