.expenses-page {
  &__main-content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__list {
  }

  &__one-expense {
    //margin-left: auto;
    //width: calc(100% - 30rem);
    //height: 45rem;
    //flex-grow: 1;
    //display: flex;
    //flex-direction: column;
  }

  &__list._short {
    width: 30rem;
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
    height: 100%;
    border-right: 1px solid @c-grey-lighter;
  }

  @media (max-width: @breakpoints[ml]) {
    &__list._short {
      display: none;
    }
  }
}
