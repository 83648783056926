
          @WINDOW_SIZE_XS: 450px;
          @WINDOW_SIZE_XM: 575px;
          @WINDOW_SIZE_SM: 749px;
          @WINDOW_SIZE_MD: 980px;
          @WINDOW_SIZE_ML: 1100px;
          @WINDOW_SIZE_LG: 1340px;
          @WINDOW_SIZE_XL: 1600px;
          @WINDOW_SIZE_XXL: 1920px;
          @IS_CONSTRUCTION: true;
          @import "@/assets/less/variables/variables.less";
          @import "@/assets/less/mixins/mixins.less";
        
@import '~@/assets/less/variables/variables';
@import '~@/assets/less/mixins/mixins';

//table {
//  page-break-before: auto;
//  page-break-inside: auto;
//  page-break-after: auto;
//}
//
//tr {
//  page-break-before: auto;
//  page-break-inside: avoid;
//  page-break-after: auto;
//}
//
//thead {
//  display: table-header-group;
//}
//
//tfoot {
//  display: table-footer-group;
//}

.file-doc__content * {
  box-sizing: border-box;
  outline: none;
}

.file-doc__content .clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.file-doc__content .page-break-inside-avoid {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
}

//.file-doc__content .minus-margin-vertical {
//  margin: -8px 0;
//}
//
//.file-doc .minus-margin-vertical {
//  margin: 0;
//}

.file-doc {
  //width: 8.5in;
  zoom: var(--blank-preview-zoom, 1);
  width: @blank-width;
  //min-height: 0;
  min-height: @blank-height;
  margin: 0 auto;
  background: white;
  padding: @blank-side-top @blank-side-right @blank-side-bottom @blank-side-left;
  //padding: 0.5in 0.5in 0.5in 0.5in;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.25);
}

.file-doc__content {
  //padding: 0 0.5in 0 0.5in;
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;

  color: #1f1f1f;
  font-size: 12px;
  line-height: 1.33333333em;
  font-family: 'Open Sans', system-ui, sans-serif;
  white-space: normal;
  word-break: break-word;
}

.file-doc .file-doc__content {
  padding: 0;
  zoom: @blank-zoom;
}

.file-doc__content textarea {
  font-size: 12px;
  line-height: 1.33333333em;
  font-family: 'Open Sans', system-ui, sans-serif;
}

.file-doc__content {
  word-break: normal;
}

.file-doc__header {
  padding: 0;
}

.file-doc__header._bordered {
  padding-bottom: 15px;
  border-bottom: 1px dashed #b3b3b3;
}

.file-doc__header-left {
  width: 40%;
  float: left;
}

.file-doc__logo-wrap {
  margin-bottom: 2px;

  line-height: 0;
}

.file-doc__logo {
  display: inline-block;
  width: 130px;
  height: 40px;
  //max-height: 40px;
  object-fit: contain;
}

.file-doc__logo-subtitle {
  font-size: 12px;
}

.file-doc__header-middle {
  width: 20%;
  float: left;
  text-align: center;
}

.file-doc__header-right {
  float: left;
  width: 40%;

  text-align: right;
}

.file-doc__name {
  text-transform: uppercase;
  font-size: 18px;
}

.file-doc__sub-name {
  margin: 4px auto 0;
  width: 100%;

  font-size: 12px;
  line-height: 1.33333333em;
  color: #595959;
  /*text-align: right;*/
}

.file-doc__main {
  padding-top: 15px;
}

.file-doc-table {
  width: 100%;
  margin: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

.file-doc-table td {
  border: 1px solid #b3b3b3;
  padding: 2px 6px 2px;
  vertical-align: top;
  width: 8.33%;

  font-size: 12px;
  line-height: 1.33333333em;
  font-family: 'Open Sans', system-ui, sans-serif;

  //&:first-child {
  //  padding-left: 6px;
  //}
  //
  //&:last-child {
  //  padding-right: 6px;
  //}
}

.file-doc__content td[colspan='1'] {
  width: 8.33%;
}

.file-doc__content td[colspan='2'] {
  width: 16.66%;
}

/*.file-doc-table td:first-child,*/
/*.file-doc-table td:last-child,*/
/*.file-doc-table th:first-child,*/
/*.file-doc-table th:last-child {*/
/*  padding: 2px 6px 2px;*/
/*}*/

.file-doc__content td[colspan='3'] {
  width: 25%;
}

.file-doc__content td[colspan='4'] {
  width: 33.33%;
}

.file-doc__content td[colspan='5'] {
  width: 41.66%;
}

.file-doc__content td[colspan='6'] {
  width: 50%;
}

.file-doc-table .file-doc-table__head-cell {
  font-size: 10px;
  line-height: 1.33333333em;
  text-transform: uppercase;
  font-family: 'Open Sans', system-ui, sans-serif;
  color: #8f1b2c;
  height: 1px;
}

.file-doc-table__cell-title {
  margin-top: 6px;
  margin-bottom: 1px;
  font-size: 12px;
  line-height: 1.33333333em;
  font-family: 'Open Sans', system-ui, sans-serif;
  color: #8f1b2c;
}

.file-doc-table__cell-title:first-child {
  margin-top: 0;
}

.file-doc-table__cell-textarea {
  width: 100%;
  padding: 0;
  border-radius: 0;
  border: 0;
  min-height: 18px;

  font-size: 12px;
  line-height: 1.33333333em;
  font-family: 'Open Sans', system-ui, sans-serif;
  font-weight: normal;
}

.file-doc-table__for-withs td {
  line-height: 0;
  padding: 0;
  border: 0;
  opacity: 0;
  height: 1px;
}

.file-doc-table__editor {
  min-height: 18px;
}

.file-doc-table__editor b,
.file-doc-table__editor strong {
  font-weight: bold;
}

.file-doc-table__editor p {
  margin: 0;
}

.file-doc-table__editor .ProseMirror {
  background: #e4eefb;
}

.file-doc__table-title {
  margin: 10px 0 3px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
}

.file-doc__text {
  margin: 6px 0;

  font: inherit;
  font-size: 10px;
  line-height: 15px;
}

.file-doc__text:first-child {
  margin-top: 0;
}

.file-doc__text:last-child {
  margin-bottom: 0;
}

.file-doc__text > *:first-child {
  margin-top: 0;
}

.file-doc__text > *:last-child {
  margin-bottom: 0;
}

.file-doc__text._large {
  font-size: 12px;
  line-height: 1.4em;
}

.file-doc__inline-input-wrap {
  display: inline-block;
  margin: 3px 3px 5px;
  vertical-align: middle;
}

.file-doc__inline-input-wrap._sub-label {
  margin-top: 18px;
}

.file-doc__inline-input {
  width: 100%;
  padding: 2px 0;
  border-radius: 0;
  border: 0;
  outline: none;
  border-bottom: 1px solid #cccccc;

  height: 18px;

  font: inherit;
  line-height: inherit;
  vertical-align: baseline;
  text-align: center;
}

.file-doc .file-doc__inline-input {
  background: #e4eefb;
}

.file-doc__content .grey-border .file-doc__inline-input {
  border-color: #e0e0e0;
}

.file-doc .grey-border .file-doc__inline-input {
  border-color: #e4eefb;
}

.file-doc__inline-input .file-doc__inline-input {
  height: 20px;
}

.file-doc__inline-input-sub-label {
  text-align: center;
  font-size: 10px;
  line-height: 15px;
  color: #595959;
}

.file-doc__content .reset-font-size {
  font-size: 0;
}

.file-doc__content .set-font-size {
  font-size: 12px;
}

.file-doc-light-table {
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0;
  width: 100%;

  font: inherit;
}

.file-doc-light-table td {
  padding: 2px 0;
  width: 50%;
  border: 0;

  font: inherit;
}

.file-doc-light-table td:last-child {
  vertical-align: bottom;
}

.file-doc-light-table._long-name td:first-child {
  width: 55%;
}

.file-doc-light-table._long-name td:last-child {
  width: 45%;
}

.file-doc-light-table._short-name td:first-child {
  width: 35%;
}

.file-doc-light-table._short-name td:last-child {
  width: 65%;
}

.file-doc-light-table td:first-child {
  padding-right: 5px;

  text-align: right;
  color: #8f1b2c;
}

.file-doc-light-table__editor {
  min-height: 18px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;

  font: inherit;
}

.file-doc-light-table__editor._without-border {
  border-bottom: 0;
}

.file-doc-light-table__editor b,
.file-doc-light-table__editor strong {
  font-weight: bold;
}

.file-doc-light-table__editor p {
  margin: 0;

  font-size: 12px;
  line-height: 1.33333333em;
  font-family: 'Open Sans', system-ui, sans-serif;
}

.file-doc-light-table__editor .ProseMirror {
  background: #e4eefb;
}

.file-doc-light-table__name-editor {
  display: inline-block;
  width: auto;
  border-bottom: 0;
}

.file-doc__content .reset-table {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border: 0;
}

.file-doc__content .reset-table td {
  padding: 0;
  border: 0;
}

.file-doc-table__container-head-row td {
  background: #ebebeb;
}

.file-doc-table__container-head tr:last-child td {
  border-bottom: 0;
}

.file-doc-table__container-head .file-doc__inline-input {
  border-color: #cccccc !important;
  text-align: left;
}

.file-doc__content .commodity-photo {
  width: 80px;
  height: 80px;
  background: no-repeat center;
  background-size: contain;
}

.file-doc__section-title {
  margin: 20px 0 10px;
  padding: 0 0 2px;
  border-bottom: 1px solid #e0e0e0;

  font-size: 12px;
  font-weight: bold;
}

.file-doc__content .signature {
  width: 200px;
  margin-left: auto;
  padding-top: 20px;
}

.file-doc__content .signature._with-input {
  padding-top: 0;
}

.file-doc__content .signature__label {
  border-top: 1px solid #cccccc;
  text-align: center;
  font-size: 10px;
  line-height: 15px;
  color: #595959;
}

.file-doc__content .signature__input {
  /*border-radius: 0;*/
  /*border: 0;*/
  /*width: 100%;*/

  text-align: center;
  /*font-style: italic;*/
  /*!*font-family: 'Dancing Script', cursive;*!*/
  /*font-family: 'Dancing Script';*/
  /*font-size: 18px;*/
  /*font-weight: 400;*/
  /*color: @c-grey-darkest;*/
}

.file-doc__content .signature__input p {
  font: inherit;
}
