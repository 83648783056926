.g-bg-red-light {
  //background-color: @c-red-light !important;
}

.g-bg-red {
  background-color: @c-red !important;
}

.g-bg-green {
  background-color: @c-green !important;
}

.g-bg-blue {
  background-color: @c-blue !important;
}

.g-bg-yellow {
  background-color: @c-yellow !important;
}

.g-bg-black {
  background-color: @c-grey-darkest !important;
}

.g-bg-grey-lightest {
  background-color: @c-grey-lightest !important;
}

.g-bg-white {
  background-color: white !important;
}
