.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal__content,
.modal-leave-active .modal__content,
.modal-enter .v-lightbox__item,
.modal-leave-active .v-lightbox__item {
  transform: matrix(0.8, 0, 0, 0.8, 0, 0);
}

.zoom-in-enter-active,
.zoom-in-leave-active {
  transition: transform cubic-bezier(0.25, 0.8, 0.5, 1) @time,
    opacity cubic-bezier(0.25, 0.8, 0.5, 1) @time;
  transform-origin: center;
}

.zoom-in-enter,
.zoom-in-leave-to {
  opacity: 0;
  transform: matrix(0.8, 0, 0, 0.8, 0, 0);
}
