@media (max-width: @breakpoints[ml]) {
  .storage-rates-items {
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
      width: 100%;
      max-width: calc(100% / 2 - 8px);
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 12px 50px 12px 8px;
      margin-top: 10px;
      position: relative;
    }

    &__item-actions-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 6px;
      right: 0;
    }
    &__item-actions.actions {
      padding: 6px 12px;
    }
    &__item-checkbox {
      padding: 6px 12px;
    }

    &__item-block {
      + .storage-rates-items__item-block {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: @breakpoints[md]) {
  .storage-rates-items {
    &__item {
      font-size: @f-size-small;
      line-height: @f-lineheight-small;
    }
  }
}

@media (max-width: @breakpoints[sm]) {
  .storage-rates-items {
    &__item {
      width: 100%;
      max-width: 100%;
    }
  }
}
