.confirm-container-loading {
  position: relative;

  &__container-number {
    margin: 0 0 2rem;

    font-size: 1.6rem;
  }

  &__input {
    margin-bottom: 2rem;
  }

  &__alert {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    min-width: 9rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
