.users-list {
  &__table {
    width: 100%;
    margin: 0;

    & td._date {
      width: 10rem;
    }

    & td._name {
      width: 12rem;
    }

    & td._login {
      //width: 10rem;
    }

    & td._access {
      //width: 40%;
    }
  }

  &__access-list {
    margin: -0.5rem 0 0 -0.5rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__access {
    margin: 0.5rem 0 0 0.5rem;
    padding: 0.2rem 1rem;
    display: flex;
    align-items: center;
    background: @c-grey;
    border-radius: @radius;

    color: white;
    font-size: 1rem;
    font-weight: @w-semi-bold;

    & svg {
      fill: white;
      margin: -0.6rem 0.4rem -0.6rem 0;
    }

    &._primary-admin {
      background-color: @c-green;
    }

    &._admin {
      background-color: @c-blue;
    }

    &._see {
      background-color: @c-orange;
    }
  }
}
