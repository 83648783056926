@search-btn-width: 2.9rem;

.search {
  display: flex;
  border-radius: @radius;
  width: 22rem;
  border: 1px solid @c-grey-light;

  transition: @time;

  @media screen and (max-width: @breakpoints[sm]) {
    margin-right: 0 !important;
    width: 100% !important;
  }

  &._white {
    background-color: white;
  }

  &._light {
    border-color: @c-grey-lighter;
  }

  &__input-wrap {
    display: flex;
    width: 50%;
    flex-grow: 1;
    overflow: hidden;
    //border: 1px solid @c-grey-lighter;
  }

  &__ico {
    align-self: center;
    width: 1.8rem;
    height: 1.8rem;
    fill: @c-grey-lighter;
    margin-left: 1rem;
    flex-shrink: 0;
  }

  &__input {
    border: 0;
    border-radius: 0;
    width: 50%;
    flex-grow: 1;
    background: transparent;
  }

  &__btn {
    background: transparent;
    border-radius: 0;
    padding: 0.2rem;
    min-height: 0;
    border: 0;
    width: @search-btn-width;
    overflow: visible;
    flex-shrink: 0;

    transition: @time;

    &:hover {
      background-color: transparent;
    }

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: @c-grey-light;
    }

    &:hover svg {
      fill: @c-green;
    }

    &._cancel:hover svg {
      fill: @c-red-light;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -1px;
      width: 1px;
      height: 1.8rem;
      transform: translateY(-50%);
      background: linear-gradient(
        to bottom,
        fade(#ffffff, 0),
        fade(#9d9d9d, 50%),
        fade(#ffffff, 0)
      );
    }
  }

  //&__cancel {
  //  align-self: stretch;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  width: 1.6rem;
  //  margin-left: 0.2rem;
  //
  //  & svg {
  //    width: 0.8rem;
  //    height: 0.8rem;
  //    fill: @c-grey-light;
  //    transition: @time;
  //  }
  //
  //  &:hover svg {
  //    fill: @c-red
  //  }
  //}

  &._short._closed {
    width: @search-btn-width + 0.2rem;
  }

  &._short._closed &__btn::before {
    opacity: 0;
  }

  &._short &__input-wrap {
    width: 0;
  }
}
