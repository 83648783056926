.select-vendor {
  display: flex;

  &__select {
    width: 50%;
    flex: 1 1 auto;

    &:not(:last-child) .select__container {
      border-right-width: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__select._open ~ &__btn,
  &__input:hover ~ &__btn,
  &__select:not(._disabled):not(._error):hover ~ &__btn,
  &._add:hover &__input .input__input {
    border-color: #cdcccc;
    cursor: pointer;
  }

  &__input,
  &__input .input__input {
    width: 50%;
    flex: 1 1 auto;

    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__input .input__input {
    width: 100%;
  }

  &__btn {
    flex-shrink: 0;
    padding: 0.2rem;
    width: 2.9rem;
    height: 3.4rem;
    border-radius: 0 @radius @radius 0;
    background: transparent !important;
    border: 1px solid @c-grey-lighter;
    border-left-width: 0;
    overflow: visible;

    & svg {
      fill: @c-blue;
      width: 1.8rem;
      height: 1.8rem;

      transition: transform @time;
    }

    &._rotate svg {
      transition: transform @time-modal * 1.5;
    }

    &:hover svg {
      transform: scale(1.1);
    }

    &._rotate:hover svg {
      transform: rotate(180deg);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -1px;
      width: 1px;
      min-width: 1px;
      height: 1.8rem;
      transform: translateY(-50%);
      background: linear-gradient(
        to bottom,
        fade(@c-grey-darkest, 0),
        fade(@c-grey-darkest, 35%),
        fade(@c-grey-darkest, 0)
      );
    }
  }

  &__select._error ~ &__btn {
    border-color: @c-red-light;
  }

  &__select._disabled ~ &__btn {
    opacity: 0.6;
    cursor: not-allowed;
    //pointer-events: none;
  }

  &._add &__btn:before {
    display: none;
  }
}
