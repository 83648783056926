@gallery-previews-item-width: 24%;

.gallery-previews {
  position: relative;
  width: 100%;

  &__carousel {
    position: relative;
    width: 100%;
    border-radius: @radius;
    overflow: hidden;
  }

  &__list {
    width: 100%;
    display: flex;
  }

  &__item {
    position: relative;
    flex-shrink: 0;
    width: @gallery-previews-item-width;
    // padding-top: @gallery-previews-item-width;
    height: 7rem;
    margin-right: ((100% - @gallery-previews-item-width * 4) / 3);
    background: @c-grey-lightest;
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    &._no-items {
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: @w-semi-bold;
      color: @c-grey;
    }

    &.swiper-slide-thumb-active {
      border-color: @c-blue;
    }
  }

  &__no-items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    font-weight: @w-semi-bold;
    color: @c-grey;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-align: center;
  }

  &__item-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__checkbox {
    position: absolute;
    z-index: 2;
    top: 0.2rem;
    left: 0.2rem;
    opacity: 0.8;

    &._checked {
      opacity: 1;
    }

    & .checkbox__mark {
      border-color: white;
      background: fade(@c-grey-darker, 20);
    }
  }

  &__nav.btn {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    min-width: 4rem;
    min-height: 4rem;
    background-color: fade(white, 80%);

    &._disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &._lock {
      display: none;
    }

    &:not(._disabled):hover {
      background-color: fade(white, 100%);
    }

    &._left {
      left: 0;
      border-radius: 0 @radius @radius 0;
    }

    &._right {
      right: 0;
      border-radius: @radius 0 0 @radius;
    }

    & svg {
      fill: @c-grey;
      width: 1rem;
      height: 1.4rem;
    }

    &:not(._disabled):hover svg {
      fill: @c-blue;
    }

    &._left svg {
      transform: rotate(180deg);
    }

    &.swiper-button-lock {
      display: none;
    }
  }
}
