.new-booking-page {
  flex-grow: 1;

  &__main {
    //padding-right: @page-sides-padding;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__main-content {
    background: white;
    flex-grow: 1;
  }
}
