input,
textarea {
  display: inline-block;
  padding: @input-padding;
  margin: 0;
  border: 1px solid @c-grey-lighter;
  outline: none;
  border-radius: @radius;
  background: white;

  font-family: @f-font;
  font-size: @f-size-small;
  line-height: @f-lineheight-small;
  vertical-align: top;
  //font-weight: @w-normal;
  color: @c-grey-darker;

  transition: background-color @time, color @time, border-color @time;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: @c-grey-light;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: @c-grey-light;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: @c-grey-light;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: @c-grey-light;
  }

  &:autofill,
  &:-webkit-autofill {
    //background-color: inherit !important;
    //color: inherit;
    //font: inherit;
  }
}

textarea {
  resize: vertical;

  &._no-resize {
    resize: none;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
