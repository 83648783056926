//.fade-enter {
//  opacity: 0;
//}
//
//.fade-leave-active {
//  opacity: 0;
//}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
