.commodities-list {
  display: flex;
  flex-direction: column;

  &__header {
    //padding: 1rem 0;
    //position: sticky;
    //z-index: 32;
    //top: @header-height  * 2;
    //background: white;
  }

  &__header-row {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;

    border-bottom: 1px solid @c-grey-lighter;

    &._sticky {
      position: sticky;
      z-index: 32;
      top: @header-height;
      background: white;
    }
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search._full-width {
    width: 100%;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__filter {
    margin-top: -@customer-commodities-filter-item-marg;
    margin-left: -@customer-commodities-filter-item-marg;
  }

  &__filter-item {
    background: transparent;
    border: 1px solid @c-grey-lighter;
    min-height: 3rem;
    margin: @customer-commodities-filter-item-marg 0 0
      @customer-commodities-filter-item-marg;

    color: @c-grey-darker;
    font-weight: @w-regular;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: none;

    &:hover {
      border-color: @c-green;
      background: transparent;

      color: @c-green;
    }

    &._active {
      background-color: @c-green;
      border-color: @c-green;

      color: white;
    }

    & > span {
      margin-left: 0.4rem;
    }
  }

  // - - - -

  &__main {
    position: relative;
    //height: 30rem;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__groups-list {
    position: absolute;
    left: 0;
    top: 0;
    width: 20%;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid @c-grey-lighter;
    overflow-y: auto;

    &._full {
      width: 100%;
    }
  }

  &__groups-table {
    width: 100%;
    margin: 0;

    & th {
      padding-top: 1.2em;
      padding-bottom: 1.2em;
      background: white;

      &:last-child {
        text-align: right;
      }
    }

    & td {
      background-color: @c-grey-lightest;

      &:last-child {
        text-align: right;
      }
    }

    & tr._active-exact td,
    & tr._current-destination td {
      background-color: @c-blue-2;
    }

    & tbody tr {
      cursor: pointer;
    }
  }

  &__main-content {
    width: 80%;
    margin-left: auto;
    display: flex;
    flex-direction: column;

    &._full {
      width: 100%;
    }
  }

  &__table-wrap {
    //height: 25rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__scroll-block {
    //height: 20rem;
    flex-grow: 1;
    //overflow-y: scroll;
  }

  &__table {
    width: 100%;
    margin: 0;

    &._head {
      position: sticky;
      top: 10.5rem;
      z-index: 32;
    }

    & tr {
      display: flex;
    }

    & th,
    & td {
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }

    & td {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
    }

    & tbody tr {
      cursor: pointer;

      transition: @time;
    }

    & tbody tr:hover {
      background-color: @c-grey-lightest;
    }

    & tbody tr:hover .hide-particle__placeholder::before {
      opacity: 0;
    }

    & tbody tr:hover .hide-particle__placeholder::after {
      opacity: 1;
    }

    & th:first-child,
    & td:first-child {
      width: 20rem;
    }

    & th:nth-child(2),
    & td:nth-child(2) {
      width: 18rem;
      flex-grow: 1;
    }

    & th._doc-status-location,
    & td._doc-status-location {
      width: 16rem;
    }

    & th._status-location,
    & td._status-location {
      width: 16rem;
    }

    & th._note,
    & td._note {
      width: 6rem;
      align-items: center;
    }

    & th._actions,
    & td._actions {
      width: 5.4rem;
    }

    & th._destination,
    & td._destination {
      width: 18rem;
    }
  }

  &__main-content:not(._full) {
    .commodities-list__table {
      & th._destination,
      & td._destination {
        display: none;
      }
    }
  }

  &__scroll-block &__table {
    & th._actions,
    & td._actions {
      width: 5.4rem;
    }
  }

  &__cards {
    padding: 1.6rem;
    display: grid;
    gap: 1.4rem;
  }

  &__card {
    min-height: 9rem;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 1.1rem;
    border: thin solid #e0e0e0;
    border-radius: 0.4rem;
    background-color: white;
    min-width: 0;
  }

  &__card-actions {
    position: absolute;
    top: 1.4rem;
    right: 1.4rem;
  }

  &__card-actions-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;

    & > * {
      margin: 0;
    }
  }

  &__card-block {
    &:not(:first-child) {
      margin-top: 1rem;
    }

    &._row {
      display: flex;
    }

    &._top-spacing {
      margin-top: 1rem;
    }

    &._bottom-spacing {
      margin-bottom: 1rem;
    }

    &._bottom-spacing-2 {
      margin-bottom: 2rem;
    }
  }

  &__card-block-title {
    color: #626262;
    font-weight: 500;
  }

  &__card-block-inner {
    display: block;
    margin-left: 0;
    padding: 0.4rem 0 0.4rem 1rem;
    border-left: 0.2rem solid @c-grey;
  }

  &__card-block-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__card-image {
    margin-right: 1.5rem;
    width: 7rem;
    height: 5.5rem;
    object-fit: cover;
    background: @c-grey-light-2;
  }

  &__card-description-row {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  &__card-description-title {
    color: #808080;
    font-weight: 500;
  }

  &__card-description-value {
    &:not(:first-child) {
      margin-left: 0.4em;
    }

    &:not(:last-child, :first-child) {
      margin-right: 0.5em;
    }

    &._inner {
      display: block;
      margin-left: 0;
      padding: 0.4rem 0 0.4rem 1rem;
      border-left: thin solid @c-grey;
    }
  }

  // - - - -

  &__help-list {
    list-style: none;
    margin: 0;
    padding: 0;

    & li {
      margin: 0.6rem 0;
    }
  }

  &__group-amount {
    display: inline-block;
    min-width: 2.5rem;
    min-height: 2.5rem;
    background: white;
    border-radius: 50%;
    text-align: center;
    padding: 0.4rem 0.3rem 0;

    font-weight: @w-semi-bold;
    color: @c-grey-dark;
  }

  &__groups-table tr._active-exact &__group-amount {
    //background: @c-grey-lightest;
  }

  &__customer-profile {
    display: flex;
    align-items: center;
  }

  &__customer-photo-wrap {
    background: @c-grey-light-2;
    border-radius: 50%;
    overflow: hidden;
    width: 4.5rem;
    height: 4.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  &__customer-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // - - - -

  &__descr {
    display: flex;
    align-items: center;
  }

  &__photo-wrap {
    background: @c-grey-light-2;
    overflow: hidden;
    width: 4.5rem;
    height: 3.4rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__descr-main {
    flex-grow: 1;
  }

  &__vin {
    margin-top: 0.4rem;
    .no-wrap();
  }

  &__name,
  &__vin {
  }

  &__status {
    margin-bottom: 0.4rem;
  }

  // - - - -

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }

  &__group-btn {
    background: transparent;
    border: 1px solid @c-grey-lighter;
    text-transform: none;

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: fade(black, 65%);
    }

    & span {
      color: @c-grey-darker;
    }

    &._open,
    &:hover {
      background-color: @c-green-light;
      border-color: @c-green-light;

      & span {
        color: white;
      }
    }

    &._open svg,
    &:hover svg {
      fill: white;
    }
  }
}
