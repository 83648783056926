.photo-preview {
  background: @c-grey-light-2;
  overflow: hidden;
  width: 8rem;
  flex-shrink: 0;
  align-self: flex-start;
  position: relative;
  transition: box-shadow @time;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 75%;
  }

  &:not(._empty):hover {
    box-shadow: 0 0 1rem fade(@c-grey-darkest, 50);
  }

  &__photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    cursor: zoom-in;
  }
}
