.logs-list {
  width: 100%;

  &__table {
    width: 100%;
    margin: 0;

    & tr:last-child td {
      border-bottom: 0;
    }

    & th:nth-child(1),
    & td:nth-child(1) {
      width: 15.5rem;
    }
  }

  & a {
    text-decoration: none;
  }

  &__log-group {
    ul& {
      padding-left: 1.4rem;
    }

    ul& li::before {
      background-color: @c-grey;
    }

    &._added li::before {
      background-color: @c-green;
    }

    &._changed li::before {
      background-color: @c-orange;
    }

    &._removed li::before {
      background-color: @c-red;
    }
  }
}
