.file-commodity {
  width: 100%;
  min-width: 104rem;
  table-layout: fixed;

  &__table-section:last-child {
    & > tr > td {
      border-bottom: 0;
    }
  }

  &._editable &__cell._customer {
    width: 26rem;
  }

  &__toggle-cell {
    width: 5.2rem;
    vertical-align: top;
  }

  & > tbody > tr > td {
    background-color: @c-grey-lightest;
  }

  &__separator-row > td {
    background-color: white !important;
    padding: 0.5rem;
  }

  & > tbody:last-child &__separator-row {
    display: none;
  }

  &__commodity .hide-particle__placeholder::before {
    background: linear-gradient(
      to left,
      @c-grey-lightest,
      fade(@c-grey-lightest, 0)
    );
  }

  &__cell {
    vertical-align: top;

    &._commodity {
      padding: 0;
    }
  }

  //&__head-cell,
  //&__cell-content,
  //&__cell {
  //  border: 1px dashed red;
  //}

  &__head-cell._customer,
  &__cell._customer {
    width: 20rem;
  }

  &__head-cell._commodity-info,
  &__cell-content._commodity-info {
    width: auto;
    flex-grow: 1;
  }

  &__cell-content._commodity-info {
    width: 10rem;
    &:first-child {
      margin-left: 1rem;
    }
  }

  &__head-cell._docs,
  &__cell-content._docs {
    width: 15.7rem;
  }

  &._editable &__head-cell._docs,
  &._editable &__cell-content._docs {
    width: 20rem;
  }

  &__head-cell._status-location,
  &__cell-content._status-location {
    width: 16.4rem;
  }

  &._editable &__head-cell._status-location,
  &._editable &__cell-content._status-location {
    width: 16.4rem;
  }

  &__head-cell._note,
  &__cell-content._note {
    width: 5rem;
  }

  &._editable &__head-cell._note,
  &._editable &__cell-content._note {
    width: 6rem;
  }

  &__head-cell._options,
  &__cell-content._options {
    width: 5.2rem;
    flex-shrink: 0;
  }

  &._editable &__head-cell._options,
  &._editable &__cell-content._options {
    width: 9.7rem;
  }

  &__commodity-row {
    position: relative;
    display: flex;
    padding: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 1rem;
      right: 2rem;
      height: 1px;
      border-bottom: 1px dashed #dbdbdb;
      opacity: 0;

      transition: @time;
    }

    &._main::before {
      display: none;
    }
  }

  &__table-section._open &__commodity-row::before {
    opacity: 1;
  }

  &__cell-content {
    padding: 1.2rem 1rem;
    flex-shrink: 0;

    &:last-child {
      padding-right: 2rem;
    }
  }

  &__cell-content._options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__amount {
    display: inline-block;
    min-width: 1.7rem;
    height: 1.7rem;
    background: white;
    box-shadow: 0 0 0.7rem fade(#a2a2a2, 50);
    border-radius: 0.3rem;
    opacity: 1;

    text-align: center;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-semi-bold;

    transition: @time;

    &._absolute {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
    }
  }

  &__commodity {
    cursor: pointer;
  }

  &__table-section._open &__amount {
    opacity: 0;
  }

  &__cell-content {
    position: relative;
    //height: @filling-list-content-height;
  }

  &__cell-list &__cell-content {
    height: @filling-list-content-height + 1.3rem;
    padding-top: 1.3rem;
  }

  //&__cell-content::after {
  //  content: '';
  //  position: absolute;
  //  left: -1rem;
  //  top: calc(100% + 0.6rem);
  //  height: 0;
  //  width: calc(100% + 2rem);
  //  border-bottom: 1px dashed #dbdbdb;
  //  opacity: 0;
  //
  //  transition: opacity @time;
  //}
  //
  //&__table-section._open &__cell-content::after {
  //  opacity: 1;
  //}
  //
  //&__cell-content:last-child::after {
  //  display: none;
  //}

  // - -

  &__toggle {
    padding: 0;
    min-width: 2.2rem;
    min-height: 2.2rem;
    background: transparent;
    box-shadow: 0 0 0.6rem fade(#bbbbbb, 50);

    & svg {
      width: 0.8rem;
      height: 1rem;
      transform: rotate(90deg);
      fill: @c-grey-darker;
    }

    &:hover {
      background-color: @c-blue;
    }

    &:hover svg {
      fill: white;
    }
  }

  &__table-section._open &__toggle,
  &__card._open &__toggle {
    background: @c-blue;

    & svg {
      transform: rotate(270deg);
      fill: white;
    }
  }

  // - - - -

  &__table-tabs-row > td {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom-width: 0;
  }

  &__table-section._open &__table-tabs-row > td {
    border-bottom-width: 1px;
  }

  &__tabs-wrap {
    padding-bottom: 2rem;
  }

  &__tabs {
    padding: 1rem 0 0;
    background: transparent;
    border-color: @c-grey-lighter;
  }

  &__tabs-block {
    padding: 1rem 0 0rem;
  }

  // - -

  &__estimates-table {
    width: 100%;
    margin: 0;
  }

  &__light-btn {
    padding: 0;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    margin-right: 2.2rem;
    background: transparent;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-semi-bold;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;

      color: @c-blue;
    }
  }

  &__row {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    position: relative;

    &-val {
      font-size: @f-size-small;
      line-height: @f-lineheight-small;
      font-weight: @w-semi-bold;
      margin-right: 2rem;
    }

    &-btn {
      margin-right: 1rem;
    }
  }

  &__card {
    display: block;
    //padding: 1.1rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    background-color: white;
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    &:last-child {
      margin-bottom: 0;
    }

    &-head {
      display: flex;
      border-bottom: 1px solid #e0e0e0;
      padding: 1.5rem 1rem 1.5rem;
      background-color: #f2f2f2;
    }

    &-customer {
    }

    &-commodity {
      padding: 1rem;
      &:not(._first) {
        border-top: 1px dashed #e0e0e0;
      }

      .file-commodity__amount {
        right: auto;
        left: 0.2rem;
      }

      .hide-particle {
        &__placeholder:before {
          display: none;
        }
        &__overlay {
          display: none;
        }
      }
    }

    &-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(1),
      &:nth-child(2) {
        padding-right: 4rem;
      }
    }

    &-row-actions {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .notes-btn {
      //margin-bottom: 0;
    }

    .file-commodity__toggle {
    }

    .file-commodity__tabs-wrap {
      padding: 0;
      background-color: #f2f2f2;
    }

    .file-commodity__tabs {
      padding: 0 1rem;
      font-size: 1.2rem;
    }

    .file-customer-documents__content {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .file-commodity__tabs-block {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
