//@media (min-width: 576px) {
//  .container {
//    max-width: 540px;
//  }
//}
//
//@media (min-width: 768px) {
//  .container {
//    max-width: 720px;
//  }
//}
//
//@media (min-width: 992px) {
//  .container {
//    max-width: 960px;
//  }
//}
//
//@media (min-width: 1200px) {
//  .container {
//    max-width: 1140px;
//  }
//}
each(@breakpoints, {
  @media (max-width: @value) {
    .col-@{key} {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }

    .col-@{key}-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }

    .col-@{key}-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    .col-@{key}-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    .col-@{key}-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-@{key}-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .col-@{key}-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    .col-@{key}-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-@{key}-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    .col-@{key}-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .col-@{key}-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-@{key}-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    .col-@{key}-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    .col-@{key}-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .order-@{key}-first {
      -ms-flex-order: -1;
      order: -1;
    }

    .order-@{key}-last {
      -ms-flex-order: 13;
      order: 13;
    }

    .order-@{key}-0 {
      -ms-flex-order: 0;
      order: 0;
    }

    .order-@{key}-1 {
      -ms-flex-order: 1;
      order: 1;
    }

    .order-@{key}-2 {
      -ms-flex-order: 2;
      order: 2;
    }

    .order-@{key}-3 {
      -ms-flex-order: 3;
      order: 3;
    }

    .order-@{key}-4 {
      -ms-flex-order: 4;
      order: 4;
    }

    .order-@{key}-5 {
      -ms-flex-order: 5;
      order: 5;
    }

    .order-@{key}-6 {
      -ms-flex-order: 6;
      order: 6;
    }

    .order-@{key}-7 {
      -ms-flex-order: 7;
      order: 7;
    }

    .order-@{key}-8 {
      -ms-flex-order: 8;
      order: 8;
    }

    .order-@{key}-9 {
      -ms-flex-order: 9;
      order: 9;
    }

    .order-@{key}-10 {
      -ms-flex-order: 10;
      order: 10;
    }

    .order-@{key}-11 {
      -ms-flex-order: 11;
      order: 11;
    }

    .order-@{key}-12 {
      -ms-flex-order: 12;
      order: 12;
    }

    .offset-@{key}-0 {
      margin-left: 0;
    }

    .offset-@{key}-1 {
      margin-left: 8.333333%;
    }

    .offset-@{key}-2 {
      margin-left: 16.666667%;
    }

    .offset-@{key}-3 {
      margin-left: 25%;
    }

    .offset-@{key}-4 {
      margin-left: 33.333333%;
    }

    .offset-@{key}-5 {
      margin-left: 41.666667%;
    }

    .offset-@{key}-6 {
      margin-left: 50%;
    }

    .offset-@{key}-7 {
      margin-left: 58.333333%;
    }

    .offset-@{key}-8 {
      margin-left: 66.666667%;
    }

    .offset-@{key}-9 {
      margin-left: 75%;
    }

    .offset-@{key}-10 {
      margin-left: 83.333333%;
    }

    .offset-@{key}-11 {
      margin-left: 91.666667%;
    }
  }
});

@media (max-width: @breakpoints[sm]) {
  .row,
  [class*=' row-'],
  [class^='row-'] {
    --grid-offset-x: 0.5rem;
  }
}
