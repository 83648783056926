@import '~@/assets/less/variables/variables.less';
@import '~@/assets/less/mixins/mixins.less';



@size: 12px;
@line-height: @f-lineheight-small;

* {
  box-sizing: border-box;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 1.5em 0;
  line-height: 1.2em;
  font-weight: @w-semi-bold;
  letter-spacing: @f-letter-spacing;
}

h1,
.h1 {
  margin: 0.6em 0;
  font-size: 55px;
}

h2,
.h2 {
  margin: 0.8em 0;
  font-size: 48px;
}

h3,
.h3 {
  margin: 1.2em 0;
  text-transform: none;
  font-size: 26px;
}

h4,
.h4 {
  margin: @f-lineheight-small 0;
  font-size: 20px;
}

h5,
.h5 {
  font-size: 18px;
  text-transform: uppercase;
}

h6,
.h6 {
  font-size: 14px;
}

a {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
  transition: color @time;

  .hoverOnly({
    &:hover {
      text-decoration: none;
      color: @c-red;
    }
  });
}

address {
  font: inherit;
  color: @c-grey-dark;
  line-height: (24/14em);
}

///------

ul,
ol,
p,
table {
  margin: 1.25em 0;

  color: inherit;
  font-size: inherit;
}

.font() {
  color: black;
  font-size: 14px;
  line-height: 1.5;
  font-family: @f-font;
  font-weight: @f-weight;
  letter-spacing: @f-letter-spacing;
  white-space: normal;
  word-break: break-word;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.page-break-inside-avoid {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
}

b {
  font-weight: @w-semi-bold;
}

table {
  border-collapse: separate;
  border-spacing: 10px 2px;
  max-width: 100%;
  font-size: @size;
  line-height: @f-lineheight-small;
  color: @c-grey-darkest;
  text-align: left;
}

th,
td {
  padding: 0;
  border: 0;
  text-align: inherit;
  line-height: @f-lineheight-small;
  vertical-align: top;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

th {
  background: transparent;
  font-weight: @w-semi-bold;
}

tfoot {
  td {
    font-weight: @w-semi-bold;
  }
}

table {
  page-break-before: auto;
  page-break-inside: auto;
  page-break-after: auto;
}

tr {
  page-break-before: auto;
  page-break-inside: auto;
  page-break-after: auto;
}

thead {
  display: table-header-group;
}

tfoot {
  display: table-footer-group;
}

@import './blank-text';
@import '~@/assets/less/utilities/bg';
@import '~@/assets/less/utilities/colors';
@import '~@/assets/less/utilities/font-weight';
@import '~@/assets/less/utilities/text';
@import '~@/assets/less/utilities/text-transform';
@import '~@/assets/less/utilities/text-decoration';
@import '~@/assets/less/utilities/text-align';
@import '~@/assets/less/utilities/text-wrap';
@import '~@/assets/less/utilities/reset-button';
