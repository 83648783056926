.deposits-page {
  &__content {
    display: flex;
  }

  &__list {
    min-width: 29rem;
    flex: 1 1 29rem;
    border-right: 1px solid @c-grey-lighter;
  }

  &__one-deposit {
    flex: 1 0 calc(100% - 29rem);
  }
}
