.tooltip {
  position: relative;
  display: inline-block;

  &__trigger {
    display: inline-block;
  }

  &__ico {
    vertical-align: middle;
    width: 1.6rem;
    height: 1.6rem;
    margin-top: -0.5rem;
    margin-bottom: -0.2rem;
    fill: @c-blue;
  }

  &__dropdown {
    position: absolute;
    z-index: 41;
    top: calc(100% + 0.7rem);
    left: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.8);

    transition-duration: @time-modal;
    transition-property: visibility, opacity, transform;
    transition-delay: 0s;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__trigger:hover + &__dropdown {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.2s;
  }

  &__arrow {
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    pointer-events: none;
    z-index: 2;
    margin-bottom: -1px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.6rem;
      left: 0.5rem;
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg);
      transform-origin: center;
      background: white;
      box-shadow: 0 0 0.6rem fade(black, 15);
      border: 1px solid @c-grey-lighter;
    }
  }

  &__dropdown-inner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 1rem;
    box-shadow: 0 0.4rem 0.6rem fade(black, 15);
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    min-width: 18rem;
    text-align: center;
  }

  &._left &__dropdown-inner {
    left: auto;
    transform: none;
    right: 0;
  }

  &._right &__dropdown-inner {
    left: 0;
    transform: none;
  }

  &._top &__dropdown {
    top: auto;
    bottom: calc(100% + 0.7rem);
  }

  &._top &__dropdown-inner {
    top: auto;
    bottom: 0;
    //box-shadow: 0 -0.4rem 0.6rem fade(black, 15);
    //border-top-width: 1px;
    //border-bottom-width: 0;
  }

  &._top &__arrow {
    bottom: auto;
    top: 100%;
    margin-bottom: 0;
    margin-top: -1px;
    transform: translateX(-50%) scaleY(-1);
  }

  &._auto &__dropdown-inner {
    min-width: auto;
    width: auto;
    white-space: nowrap;
  }
}
