.photos-grid {
  flex-basis: 10%;
  display: grid;
  grid-auto-rows: 9.6rem;
  grid-gap: 2rem;

  &__img-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: @radius;
    overflow: hidden;
    background: @c-grey-lightest;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      grid-column: ~'1 / 3';
      grid-row: ~'1 / 3';
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: zoom-in;
  }

  &__checkbox {
    position: absolute;
    z-index: 5;
    top: 0.5rem;
    left: 0.5rem;

    opacity: 0.8;

    & .checkbox__mark {
      border-color: white;
    }

    &._checked,
    &:hover {
      opacity: 1;
    }
  }

  &__delete,
  &__edit {
    position: absolute;
    z-index: 5;
    top: 0.5rem;
    right: 0.5rem;
    width: 2rem;
    height: 2rem;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    background: transparent;
    padding: 0;
    box-shadow: none;
    cursor: pointer;

    transition: @time;

    & svg {
      fill: white;
    }

    &:hover {
      opacity: 1;
      border-color: @c-red-light;
      background-color: @c-red-light;
    }
  }

  &__edit {
    top: auto;
    bottom: 0.5rem;
    border-radius: @radius;
    opacity: 0;

    &:hover {
      border-color: @c-blue-light;
      background-color: @c-blue-light;
    }
  }

  &__img-wrap:hover &__edit {
    opacity: 1;
  }

  &__no-photos {
    color: @c-grey-light;
    font-weight: @w-bold;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
