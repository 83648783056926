.editor {
  border: 1px solid @c-grey-lighter;
  border-radius: @radius;
  position: relative;

  &__text .ProseMirror > *:last-child {
    margin-bottom: 0;
  }

  &__text .ProseMirror > *:first-child {
    margin-top: 0;
  }

  &__header {
    //display: flex;
    //flex-wrap: wrap;
    margin-top: -1px;
    border-bottom-color: white;
    border-radius: @radius @radius 0 0;
    overflow: hidden;
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__group {
    display: inline;
    float: left;
    //justify-content: center;
    //border-right: 1px solid @c-grey-lighter;
    //border-bottom: 1px solid @c-grey-lighter;
    //border-top: 1px solid @c-grey-lighter;
    //padding: 0 1px;
    //vertical-align: middle;
    //font-size: 2.4rem;
    //flex-grow: 1;
    //margin-right: -1px;
    //margin-bottom: -1px;
    //margin-top: -1px;

    &:last-child {
      //margin-right: -1px;
    }
  }

  &__btn {
    padding: 0.4rem;
    min-height: 3.4rem;
    min-width: 3.4rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //margin-right: 1px;
    border-bottom: 1px solid @c-grey-lighter;
    border-top: 1px solid @c-grey-lighter;
    //margin-top: -1px;
    margin-right: -1px;
    margin-bottom: -1px;

    &:last-child {
      //margin-right: 0;
      border-right: 1px solid @c-grey-lighter;
    }

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: @c-grey;
    }

    &._active,
    &:hover {
      background: @c-grey-lightest;
    }
  }

  &__content {
    //background: @c-grey-lightest;
    padding: 0.8rem;
    border-radius: 0 0 @radius @radius;
    position: relative;
  }

  &__header-switcher {
    position: absolute;
    right: 0.4rem;
    top: 0.4rem;
  }

  &__header-switcher ~ &__text {
    padding-right: 3rem;
  }

  &__text {
    a {
      cursor: auto;
    }
  }

  & .ProseMirror {
    min-height: 1.6rem;
  }
}

.menububble {
  position: absolute;
  display: flex;
  z-index: 20;
  background: @c-grey-darkest;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  font-size: 1.2rem;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__button {
    margin-right: 0.5rem;

    text-transform: none;

    &:last-child {
      margin-right: 0;
    }
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__input {
    font: inherit;
    border: none;
    background: transparent;
    color: @c-grey-lightest;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: -0.5rem;
    margin-top: -0.5rem;
    min-width: 25rem;
  }
}
