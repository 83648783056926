.expense-page {
  //padding-top: 3rem;

  &__btns-wrap {
    padding: 2rem 2rem 0;
  }

  &__section-title {
    margin: 0;
    padding: 0 2rem;

    font-size: 2rem;
  }

  &__expense {
    padding: 0 0 2rem;
  }

  &__main-param {
    margin: 0;

    font-size: 1.6rem;

    &._balance {
      font-size: 1.4rem;
    }
  }

  &__photos-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__photo-wrap {
    width: 8rem;
    height: 8rem;
    margin-right: 1rem;
    border-radius: @radius;
    border: 1px solid @c-grey-lighter;
    cursor: zoom-in;
    overflow: hidden;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: @breakpoints[ml]) {
    margin-left: 0;
    width: auto;
  }
}
