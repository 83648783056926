.user-panel {
  position: relative;
  width: auto;
  font-size: @f-size-small;
  line-height: @f-lineheight-small;

  &__trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  &__photo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    overflow: hidden;
    background: @c-grey-light-2;
    margin-right: 0.6rem;

    text-decoration: none;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__ico-placeholder {
    width: 1.4rem;
    height: 1.4rem;
    opacity: 0.25;
  }

  &__photo-placeholder {
    opacity: 0.5;
    font-size: 1.2rem;
    font-weight: @w-bold;
    text-transform: uppercase;
  }

  &__name {
    width: auto;
    margin: 0;
    display: flex;
    align-items: center;

    font-size: inherit;
    font-weight: @w-semi-bold;
  }

  &__name-arrow {
    transform: rotate(90deg);
    width: 0.6rem;
    height: 1rem;
    flex-shrink: 0;
    margin-left: 0.6rem;
    fill: @c-grey-dark;

    transition: @time;
  }

  &._open &__name-arrow {
    transform: rotate(270deg);
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 1rem);
    z-index: 41;
    right: -1.5rem;
    min-width: calc(100% + 3rem);
    display: flex;
    flex-direction: column;
    background: white;
    padding: 0.5rem 0;
    box-shadow: 0 0.4rem 0.6rem fade(black, 10);
    border: 1px solid @c-grey-lighter;
    //border-top: 0;
    margin: 1px 0;
    border-radius: @radius;
    transform-origin: center top;

    transition: @time-modal;
  }

  &__dropdown-link {
    .reset-button();
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    line-height: 1.2em;
    transition: background-color @time, color @time;

    &:focus,
    &:hover {
      background-color: @c-grey-lightest;
      color: @c-red;
    }
  }
}
