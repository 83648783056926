.g-w-regular {
  font-weight: @w-regular !important;
}

.g-w-semi-bold {
  font-weight: @w-semi-bold !important;
}

.g-w-medium {
  font-weight: @w-medium !important;
}

.g-w-bold {
  font-weight: @w-bold !important;
}
