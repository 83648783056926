.c-settings-persons {
  @media (max-width: @breakpoints[md]) {
    &__content-mob {
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
      padding-bottom: 2rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;

      .list-footer {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__actions-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: -1rem 0 -1rem 1rem;
    }

    &__list-mob {
      position: relative;

      &-item {
        padding: 1.1rem;
        background-color: white;
        border: 1px solid #e0e0e0;
        border-radius: 0.4rem;
        margin-bottom: 0.8rem;
        position: relative;
        font-size: 1.2rem;

        &:last-child {
          margin-bottom: 0;
        }

        &-params {
          display: flex;
          flex-wrap: wrap;
          margin-right: -1rem;

          & > div {
            flex-grow: 1;
            width: 40%;
            margin-top: 1rem;
            margin-right: 1rem;
            flex-basis: 28rem;
          }
        }

        &-top {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
