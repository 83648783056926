.person-info {
  padding: 1rem 2.5rem;

  &._no-padding {
    padding: 0;
  }

  &--separate-content {
    height: 20rem;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__no-customer {
    position: absolute;
    z-index: 21;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: fade(white, 60);
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
  }

  &__no-customer-title {
    padding: 1.4rem 2rem;
    border-radius: 3rem;
    background: @c-grey-lightest;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;

    &._inner {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__profile-wrap {
    display: flex;
  }

  &__header-content {
    display: flex;
  }

  &__photo-wrap {
    width: 5.4rem;
    height: 5.4rem;
    border-radius: 50%;
    background: @c-grey-light-2;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 1.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: zoom-in;

    transition: opacity @time;

    &:hover {
      opacity: 0.8;
    }
  }

  &__photo-placeholder {
    opacity: 0.5;

    font-size: 2rem;
    font-weight: @w-bold;
    text-transform: uppercase;
  }

  &__profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    //flex-wrap: wrap;
    //align-items: baseline;
    //align-content: center;
    margin-right: auto;
  }

  &._inactive &__profile,
  &._deleted &__profile {
    opacity: 0.6;
  }

  &__company-name {
    margin: 0 0 0.2rem;
    //width: 100%;

    font-size: @f-size;
    font-weight: @w-regular;
    color: @c-grey;
    text-transform: uppercase;
  }

  &__name {
    margin: 0;
    font-size: @f-size;
  }

  &__location {
    margin: 0 0 0 2.5rem;
  }

  &__account-number {
    width: 20%;
    flex-grow: 1;
    margin: 0 2rem 0 2rem;

    &-val {
      font-weight: 600;
    }

    .checkbox {
      margin-right: 2rem;
    }
  }

  &__vendor-categories {
    width: 20%;
    flex-grow: 1;
    margin: 0 2rem 0 2rem;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: none;
  }

  &__status {
    display: flex;
    align-items: center;
    margin: 0 2rem 0 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey-light;

    &::before {
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      display: inline-block;
      border-radius: 50%;
      background: currentColor;
      vertical-align: 1px;
      margin-right: 0.6rem;
    }
  }

  &__header._inner &__status {
    margin-right: 0;
  }

  &._active &__status {
    color: @c-green;
  }

  &._inactive &__status {
    color: @c-yellow;
  }

  &._deleted &__status {
    color: @c-red;
  }

  &__drop-menu {
    margin-left: 1rem;
  }

  &__section {
    padding: 1.8rem 0 0;
    border-top: 1px solid @c-grey-lighter;

    &:first-child {
      border-top: 0;
    }
  }

  &__toggle-sections &__section:first-child {
    border-top: 1px solid @c-grey-lighter;
  }

  &__toggle-btn {
    background: white;
    margin-top: -1px;
    border-radius: 0 0 @radius @radius;
    border-color: @c-grey-lighter;
    border-top: 0;
    align-self: center;
    min-width: 8rem;

    color: @c-grey;
    text-transform: lowercase;

    &:hover {
      background-color: @c-grey-lighter;

      color: @c-grey-darker;
    }

    & svg {
      width: 0.4rem;
      height: 1rem;
      transform: rotate(90deg);
      fill: @c-grey;
    }

    &:hover svg {
      fill: @c-grey-darker;
    }
  }

  &._short-info &__toggle-btn {
    & svg {
      transform: rotate(270deg);
    }
  }

  &__contact-wrap {
    position: relative;
    margin-bottom: 1.8rem;
    min-width: 200px;
  }

  &__contact-name {
    margin: 0 0 0.6rem;
    display: flex;
    // align-items: center;
    align-items: flex-start;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: none;
    font-weight: @w-regular;

    & svg {
      position: relative;
      margin-right: 0.5rem;
      width: 1rem;
      height: 1rem;
      fill: @c-green;
      flex-shrink: 0;
    }
  }

  &__contact,
  &__contact-table {
    margin: 0 0 0.6rem;
    background-color: transparent;

    font-size: @f-size-small;
    // line-height: @f-lineheight-small;
    font-weight: @w-semi-bold;

    &:last-child {
      margin-bottom: 0;
    }

    & a {
      font: inherit;
      color: inherit;
      text-decoration: none;
    }
  }

  &__contact {
    display: flex;

    &._error {
      color: @c-red-light;
    }

    &._accent {
      align-items: center;
      color: @c-orange-light;
    }

    &._middle {
      align-items: center;
    }

    & > span:first-child {
      margin-right: 0.3rem;
    }
  }

  &__contact-icos {
    align-self: center;
    display: flex;
    align-items: center;
  }

  &__contact-ico {
    display: flex;
    margin-left: 0.5rem;

    & svg {
      width: 2.4rem;
      height: 2.4rem;
      fill: @c-grey-light;
    }

    &._viber svg {
      fill: @c-viber;
    }

    &._whatsapp svg {
      fill: @c-whatsapp;
    }
  }

  &__link {
    .reset-button();
    cursor: pointer;

    transition: @time;

    &:hover {
      color: @c-blue;
    }
  }

  &__contact-table {
    width: 100%;

    & td {
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
    }

    & td:first-child {
      padding-left: 0;
      font-weight: @w-regular;
    }

    & td:last-child {
      padding-right: 0;
    }

    & tr:last-child td {
      padding-bottom: 0.8rem;
    }

    & tbody tr:last-child td {
      //border-bottom: 1px dashed @c-grey-light;
    }

    & tbody:last-child tr:last-child td {
      padding-bottom: 0;
      //border-bottom: 0
    }

    & tbody._error {
      color: @c-red-light;
    }

    & tbody._accent {
      color: @c-orange-light;
    }
  }

  &._inactive &__contact,
  &._deleted &__contact,
  &._inactive &__contact-name,
  &._deleted &__contact-name,
  &._deleted &__contact-table,
  &._inactive &__contact-table {
    opacity: 0.6;
  }

  &__map {
    position: absolute;
    top: -1.8rem;
    left: 0;
    width: calc(100% + 1.5rem);
    height: calc(100% + 3.6rem);
  }

  &__stamp-wrap {
    position: absolute;
    z-index: 20;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    max-width: 80rem;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    //padding-bottom: 3rem;
  }

  &__stamp {
    width: 100%;
    height: 70%;
    opacity: 0.5;
    transform: rotate(-15deg);
    flex-shrink: 0;
    display: flex;

    & svg {
      width: 100%;
      font-family: @f-font;
      font-weight: bold;
      font-size: inherit;
      fill: #fff;
      stroke: #000000;
      stroke-width: 0.5;
      stroke-opacity: 0.4;
    }
  }

  &__birthday-icon {
    margin-right: 0.8rem;

    & svg {
      width: 2rem;
      height: 2rem;
      fill: @c-orange-light;
    }
  }

  &__info-for-documents {
    font-size: 1.2rem;
    font-weight: @w-semi-bold;
  }

  &__mobile-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__sub-accounts {
    width: 100%;

    tr:not(._empty) {
      cursor: pointer;
      transition: background-color @time;

      &:hover {
        background-color: @c-grey-lightest;
      }
    }

    & td._actions {
      width: 5.4rem;
    }
  }
}
