.actions {
  position: relative;
  display: inline-flex;

  &__btn {
    border-color: @c-grey-lighter;
    .no-selection();
  }

  &__dropdown {
    position: absolute;
    z-index: 41;
    top: calc(100% + 0.4rem);
    left: 0;
    width: 100%;

    transition: @time-modal;
  }

  &__dropdown-inner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 0.5rem;
    box-shadow: 0 0.2rem 0.6rem fade(black, 25);
    //border: 1px solid @c-grey-lighter;
    border-top: 0;
    border-radius: @radius;
    display: flex;
    align-items: center;

    & > * {
      margin: 0 .8rem 0 0;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }

  &._left &__dropdown {
    top: 0;
    left: auto;
    right: calc(100% + 0.4rem);
    width: auto;
    height: 100%;
  }

  &._left &__dropdown-inner {
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);
    box-shadow: -0.2rem 0 0.6rem fade(black, 25);
  }
}
