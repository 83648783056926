.shipping-rates-destinations {
  &__groups-list {
    position: absolute;
    left: 0;
    top: 0;
    width: 20%;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid @c-grey-lighter;
    overflow-y: auto;
  }

  &__groups-table {
    width: 100%;
    margin: 0;

    & th {
      padding-top: 1.2em;
      padding-bottom: 1.2em;
      background: white;
      &:last-child {
        text-align: right;
      }
    }

    & td {
      background-color: @c-grey-lightest;

      &:last-child {
        text-align: right;
      }
    }

    & tr._active-exact td {
      background-color: @c-blue-2;
    }

    & tbody tr {
      cursor: pointer;
    }
  }

  &__main-content {
    height: 30rem;
    flex-grow: 1;
    width: 80%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }

  &__table-wrap {
    height: 25rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__scroll-block {
    //height: 20rem;
    flex-grow: 1;
    //overflow-y: scroll;
  }

  &__table {
    width: 100%;
    margin: 0;

    & tr {
      display: flex;
    }

    & th,
    & td {
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }

    & td {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
    }

    & tbody tr {
      cursor: pointer;

      transition: @time;
    }

    & tbody tr:hover {
      background-color: @c-grey-lightest;
    }

    & tbody tr:hover .hide-particle__placeholder::before {
      opacity: 0;
    }

    & tbody tr:hover .hide-particle__placeholder::after {
      opacity: 1;
    }

    //& th:nth-last-child(2),
    //& td:nth-last-child(2) {
    //  width: 10rem + @scroll-width;
    //  flex-grow: 1;
    //}
    & th:first-child,
    & td:first-child {
      width: 18rem;
    }

    & th:nth-child(2),
    & td:nth-child(2) {
      width: 20rem;
      flex-grow: 1;
    }

    & th:nth-last-child(2),
    & td:nth-last-child(2) {
      width: 4.4rem;
      align-items: center;
    }

    & th:last-child,
    & td:last-child {
      width: 5.4rem;
    }
  }

  // - - - -

  &__help-list {
    list-style: none;
    margin: 0;
    padding: 0;

    & li {
      margin: 0.6rem 0;
    }
  }

  &__group-amount {
    display: inline-block;
    min-width: 2.5rem;
    min-height: 2.5rem;
    background: white;
    border-radius: 50%;
    text-align: center;
    padding: 0.4rem 0.3rem 0;

    font-weight: @w-semi-bold;
    color: @c-grey-dark;
  }

  &__groups-table tr._active-exact &__group-amount {
    //background: @c-grey-lightest;
  }

  // -- search
  &__search-toggle {
    background: transparent;
    border: 1px solid @c-grey-lighter;

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: fade(black, 65%);
    }

    &._open,
    &:hover {
      background-color: @c-green-light;
      border-color: @c-green-light;
    }

    &._open svg,
    &:hover svg {
      fill: white;
    }
  }

  &__search-short-wrap {
    padding: 2rem;
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__search-short {
    width: 100%;
  }
}
