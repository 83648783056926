.notes-list {
  &__group {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__group-title {
    margin: 0 0 1rem;

    font-size: 1.6rem;
  }

  &__list ul {
    padding-left: 1.3rem;
  }

  &__list li {
    //display: flex;
  }

  &__date {
    //flex-shrink: 0;
    margin-left: 1rem;
    display: inline;
    float: right;
  }

  &__note {
    //display: inline;
    //flex: 1;
  }
}
