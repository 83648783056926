.alert {
  background: @c-grey-light;
  border-radius: @radius;
  overflow: hidden;
  font-size: @f-size-small;
  line-height: @f-lineheight-small;
  color: white;

  &._error {
    background-color: @c-red-light;
  }

  &._warning {
    background-color: @c-orange;
    //background-color: @c-yellow;
  }

  &._success {
    background-color: @c-green;
    //background-color: @c-yellow;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0.5rem 2rem;
  }

  &__list-item {
    position: relative;
    margin: 0.8rem 0;
    padding: 0 0 0 1rem;

    font: inherit;

    &:before {
      content: '';
      position: absolute;
      top: 0.6rem;
      left: 0;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background: white;
      opacity: 0.6;
    }
  }
}
