@import '~@/assets/css-blanks/blank-base.less';

@size: 12px;
@line-height: @f-lineheight-small;

* {
  box-sizing: border-box;
  outline: none;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.page-break-inside-avoid {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
}

table {
  width: 100%;
  margin: 0;
  table-layout: fixed;
  border-collapse: collapse;

  font: inherit;

  & th,
  & td {
    padding: 0;
    border: 0;
    background: transparent;

    font: inherit;
    text-align: left;
    vertical-align: top;
  }
}

.blank-commodity-label {
  zoom: var(--blank-preview-zoom, 1);
  width: @blank-width;
  min-height: @blank-height;
  margin: 0 auto;
  background: white;
  padding: @blank-side-top @blank-side-right @blank-side-bottom @blank-side-left;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.25);

  &__content {
    //padding: 0 @blank-side-right 0 @blank-side-left;
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-before: auto;

    color: @c-grey-darkest;
    font-size: @size;
    line-height: @line-height;
    font-family: 'Open Sans', system-ui, sans-serif;
    white-space: normal;
    word-break: break-word;
  }

  & &__content {
    padding: 0;
    zoom: @blank-zoom;
  }

  &__head {
    text-align: center;
  }

  &__logo {
    display: block;
    //height: auto;
    object-fit: contain;
    width: 180px;
    height: 55px;
    margin: 0 auto 10px;
  }

  &__main {
    padding: 20px 0 30px;
  }

  &__table {
    border: 0;
  }

  &__table td {
    border: 1px solid #e0e0e0;
    padding: 2px 6px 2px;
    vertical-align: top;
    width: 8.33%;

    font-size: 12px;
    line-height: @f-lineheight-small;
    font-family: 'Open Sans', system-ui, sans-serif;
  }

  &__editor {
    min-height: 18px;
    text-align: center;
  }

  &__editor b,
  &__editor strong {
    font-weight: bold;
  }

  &__editor p {
    margin: 0;
  }

  &__editor .ProseMirror {
    background: #e4eefb;
  }

  &__cell-title {
    margin-top: 6px;
    margin-bottom: 1px;
    font-size: 14px;
    line-height: @f-lineheight;
    font-family: 'Open Sans', system-ui, sans-serif;
    color: #808080;
  }

  &__cell-title:first-child {
    margin-top: 0;
  }

  &__notes {
    margin-top: 40px;
  }

  &__notes-title {
    margin-bottom: 20px;

    font-size: 20px;
    font-weight: 600;
  }

  &__note {
    position: relative;
    padding-left: 15px;
    margin-bottom: 10px;

    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: @c-grey-darkest;
    }
  }
}
