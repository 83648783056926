.info-block {
  @media (max-width: @breakpoints[ml]) {
    &__header,
    &__main,
    &__error-wrap,
    &__footer {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    &__group {
      width: calc(100% - 3.2rem);
    }

    &__table-wrap {
      overflow: visible;
    }
  }
  @media (max-width: @breakpoints[md]) {
  }
}
