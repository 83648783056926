.add-expense {
  position: relative;
  //&__table {
  //  width: 100%;
  //  margin: 0;
  //
  //  td {
  //    vertical-align: baseline;
  //
  //    &:first-child {
  //      width: 4.8rem;
  //    }
  //  }
  //
  //  th, td {
  //    &:last-child {
  //      text-align: right;
  //    }
  //
  //    &:first-child:last-child {
  //      text-align: center;
  //    }
  //  }
  //}
  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
  }

  &__btn {
    min-width: 9rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .expenses-table {
    thead {
      top: 0;
    }

    & col._checkbox {
      width: 4.8rem;
    }

    & col._category {
      width: 18rem;
    }

    & col._memo {
      width: 32.5rem;
    }

    & col._status {
      width: 13rem;
    }

    & col._payments {
      width: 20rem;
    }

    & col._vendor {
      width: 20rem;
    }

    & col._agent {
      width: 12rem;
    }

    & col._date {
      width: 13rem;
    }

    & col._toggle {
      width: 5.3rem;
    }
  }
}
