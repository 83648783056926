.commodity-show-section-estimates {
  &__table {
    margin: 0 -1.5rem;
    width: auto;
    max-width: none;

    &:last-child {
      margin-bottom: -1.5rem;
    }

    &:last-child tr:last-child td {
      border-bottom: 0;
    }

    & th {
      &._actions {
        width: 5.4rem;
      }
    }

    & td {
      transition: @time;
    }

    & tr {
      cursor: pointer;
    }

    & tr:hover td {
      background-color: @c-grey-lightest;
    }
  }

  &__pagination {
    margin-top: 1.5rem;
  }
}
