
          @WINDOW_SIZE_XS: 450px;
          @WINDOW_SIZE_XM: 575px;
          @WINDOW_SIZE_SM: 749px;
          @WINDOW_SIZE_MD: 980px;
          @WINDOW_SIZE_ML: 1100px;
          @WINDOW_SIZE_LG: 1340px;
          @WINDOW_SIZE_XL: 1600px;
          @WINDOW_SIZE_XXL: 1920px;
          @IS_CONSTRUCTION: true;
          @import "@/assets/less/variables/variables.less";
          @import "@/assets/less/mixins/mixins.less";
        

@import '~@/assets/css-blanks/blank-commodity-label';

.blank-commodity-label__editor .ProseMirror {
  background: #e4eefb;
}
