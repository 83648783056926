.customer-files {
  position: relative;
  background: white;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.8rem 2rem 1rem;
  }

  &__filter {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
  }

  &__filter-items {
    margin-top: -1rem;
    margin-left: -1rem;
  }

  &__filter-item {
    background: transparent;
    border: 1px solid @c-grey-lighter;
    min-height: 3rem;
    margin: 1rem 0 0 1rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    color: @c-grey-darker;
    font-weight: @w-regular;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: none;

    &:hover {
      border-color: @c-green;
      background: transparent;

      color: @c-green;
    }

    &:last-child {
      margin-right: 0;
    }

    &._active {
      background-color: @c-green;
      border-color: @c-green;

      color: white;
    }

    & > span:last-child {
      margin-left: 0.4rem;
    }
  }

  &__content-wrap {
    height: 20rem;
    flex-grow: 1;
    //display: flex;
    //flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
  }

  &__content {
  }

  .files-list {
    &__table {
      thead {
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }

    &__cards {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
