.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline {
  display: inline !important;
}

.d-flex {
  display: flex !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

each(@breakpoints, {
  @media (max-width: @value) {
    .d-@{key}-none {
      display: none !important
    }

    .d-@{key}-block {
      display: block !important
    }

    .d-@{key}-table {
      display: table !important
    }

    .d-@{key}-inline-block {
      display: inline-block !important
    }

    .d-@{key}-inline {
      display: inline !important
    }

    .d-@{key}-flex {
      display: flex !important
    }

    .d-@{key}-grid {
      display: grid !important
    }

    .d-@{key}-inline-flex {
      display: inline-flex !important
    }
  }
});
