.mail-modal {
  position: fixed;
  z-index: 61;
  right: 3rem;
  bottom: 0;
  width: 52rem;
  max-width: calc(100% - 3rem);
  background: white;
  border-radius: @radius;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.2);

  // - - - -

  &__head {
    display: flex;
    padding: 1.3rem 1.5rem;
    background-color: @c-grey-darker;
    border-radius: @radius @radius 0 0;
    cursor: pointer;

    color: white;
  }

  &__head-title {
    display: flex;
    align-items: center;

    margin: 0 auto 0 0;
    padding-right: 1.5rem;

    font-size: 1.4rem;
    font-weight: @w-semi-bold;
  }

  &__head-btn {
    .reset-button();
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

    transition: @time;

    &:last-child {
      margin-right: 0;
    }

    & svg {
      width: 1.4rem;
      height: 1.4rem;
      opacity: 0.5;
      fill: white;
    }

    &._toggle svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    &._close svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  // - - - -

  &__content {
    padding: 2.5rem 1.5rem 2rem;
    overflow-y: auto;
    max-height: calc(100vh - (5.5rem + 4.6rem + 4.9rem) - 5rem);
  }

  &__input-wrap {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    & .editor__content {
      padding: 1rem;
    }

    & .ProseMirror {
      min-height: 5.1rem;
    }
  }

  &__input {
    width: 10rem;
    flex-grow: 2;
  }

  &__input-delete-btn.btn,
  &__input-add-btn.btn {
    flex-shrink: 0;
    margin-top: 0.5rem;
    margin-left: 1rem;

    &:hover {
      background-color: @c-green-light;
    }

    & svg {
      width: 1rem;
      height: 1rem;
    }

    &:hover svg {
      fill: white;
    }
  }

  &__input-delete-btn.btn {
    & svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      background-color: @c-red;
    }
  }

  // - - - -

  &__files {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__files-upload {
  }

  &__files-list-wrap {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  &__files-list-title {
    margin: 2rem 0 0;

    font-size: 1.4rem;
  }

  &__files-list-item {
    min-width: 30rem;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: @c-grey-lightest;
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    padding: 0.2rem 1rem;
    margin-top: 1rem;
  }

  &__files-list-item-text {
    max-width: calc(100% - 2.8rem);

    .no-wrap();
    font-size: 1.2rem;
  }

  &__files-list-item-btn {
    .reset-button();
    flex-shrink: 0;
    cursor: pointer;
    width: 1.8rem;
    height: 1.8rem;
    background-color: #c7c7c7;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    transition: @time;

    &:hover {
      background-color: @c-red;
    }

    & svg {
      width: 0.8rem;
      height: 0.8rem;
      fill: white;
    }
  }

  // - - - -

  &__footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.5rem 1.5rem;

    &::before {
      content: '';
      pointer-events: none;
      position: absolute;
      left: 0;
      right: @scroll-width;
      bottom: 100%;
      height: 1.5rem;
      background: linear-gradient(to top, white, fade(white, 0));
    }
  }

  &__footer-btn {
    &._delete:hover {
      background-color: @c-red;
    }

    &._delete:hover svg {
      fill: white;
    }
  }

  // - - - -

  &._short {
    width: 20rem;
  }

  &._short &__head {
    padding: 1rem 1.5rem;
    //border-radius: @radius;
  }

  @media (max-width: @breakpoints[sm]) {
    right: 0;
    max-width: 100%;
  }
}
