.edit-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__photo-row {
    display: flex;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__photo-row-right-col {
    flex-grow: 1;
    width: 50%;
  }

  &__photo-container {
    max-width: 35%;
    width: 8.2rem;
    margin-right: 1.5rem;
    flex-shrink: 0;

    &._error {
      .edit-form__photo-wrap {
        outline: 1px solid @c-red;
      }
    }

    &._company {
      width: 20rem;

      .edit-form__photo-wrap {
        border-radius: 0;
        background: none;
        border: 1px dashed @c-grey-lighter;

        &::before {
          padding-top: 30%;
        }

        img {
          object-fit: contain;
        }
      }
    }

    &._project {
      width: 18rem;
      img {
        object-fit: contain;
      }
    }
  }

  &__photo-placeholder-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: @c-grey-lighter;
    }
  }

  &__photo-outer {
    position: relative;
  }

  &__photo-wrap {
    position: relative;
    width: 100%;
    //height: 8.2rem;
    background: @c-grey-light-2;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 1rem;

    &._square {
      border-radius: 0;
    }

    &::before {
      content: '';
      padding-top: 100%;
      display: block;
      width: 100%;
    }
  }

  &__photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__delete-photo,
  &__crop-photo {
    .reset-button();
    position: absolute;
    z-index: 5;
    right: 0.3rem;
    top: 0.3rem;
    padding: 0;
    background: @c-red;
    width: 2rem;
    height: 2rem;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    & svg {
      fill: white;
    }

    &:hover {
      background-color: @c-red-light;
    }
  }

  &__crop-photo {
    right: auto;
    left: 0.3rem;
    background: @c-grey;

    &:hover {
      background-color: @c-blue;
    }
  }

  &__photo-placeholder {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 61%;
    height: 68%;
    opacity: 0.25;
  }

  &__photo-wrap._square &__photo-placeholder {
    bottom: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 68%;
  }

  &__upload,
  &__file-upload {
    .reset-button();
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 4.6rem;
    border: 1px dashed @c-grey-light-2;
    border-radius: @radius;
    cursor: pointer;

    text-align: center;

    & input {
      display: none;
    }
  }

  &__upload-wrap {
    display: flex;
    justify-content: space-between;
    .edit-form {
      &__upload {
        flex-grow: 1;
        &:not(:last-child) {
          margin-right: 0.4rem;
        }
      }
    }
  }

  &__upload-ico,
  &__file-upload-ico {
    width: 1.8rem;
    height: 1.8rem;
    fill: @c-grey-light-2;
  }

  &__upload-note,
  &__file-upload-note {
    margin: 0.4rem 0 0;

    font-size: 1rem;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__main-info {
    padding: 1rem 0 3.5rem;
  }

  &__input-group {
    display: flex;
    align-items: flex-start;
  }

  &__col-input {
    width: 10%;
    flex-grow: 1;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    &.checkbox {
      flex-grow: 0;
      width: auto;
      margin-top: 0.6rem;
    }
  }

  //&__input-company {
  //  flex-grow: 1;
  //}
  //
  //&__input-lang {
  //  width: 16rem;
  //  flex-shrink: 0;
  //  margin-left: 1rem;
  //  max-width: 50%;
  //}

  &__input-group,
  &__input,
  &__input-wrap,
  &__checkbox-list-wrap {
    margin-bottom: 1.8rem;
    flex-grow: 1;

    &:last-child {
      margin-bottom: 0;
    }
  }

  //&__input-salutation {
  //  width: 12rem;
  //  max-width: 100%;
  //  flex-shrink: 0;
  //  margin-right: 1rem;
  //}

  &__input-wrap {
    display: flex;
  }

  &__checkbox-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.5rem;
    margin-left: -2rem;
  }

  &__checkbox {
    margin-top: 0.5rem;
    margin-left: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input-wrap &__input {
    margin-bottom: 0;
  }

  &__input-delete-btn.btn,
  &__input-add-btn.btn {
    border: 0 !important;
    background: @c-grey-lightest;
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: 0.5rem;
    margin-left: 1rem;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      background-color: @c-red-light !important;
    }
  }

  &__input-add-btn.btn {
    & svg {
      width: 1rem;
      height: 1rem;
    }

    &:hover {
      background-color: @c-green-light !important;
    }
  }

  &__section {
    margin: 1rem -2.4rem 0.2rem;
  }

  &__main-info + &__section,
  &__section + &__section {
    margin-top: 0;
  }

  &__section-head {
    padding: 1.3rem 2.4rem 1.2rem;
    background: @c-blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__section-title {
    margin: 0;
    width: 50%;
    flex-grow: 1;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: uppercase;
    color: white;
  }

  &__section-btn-toggle {
    margin: -1rem 0 -1rem auto;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      transform: rotate(90deg);

      transition: @time;
    }
  }

  &__section._open &__section-btn-toggle svg {
    transform: rotate(270deg);
  }

  &__section-delete-btn {
    background: transparent;
    border-color: white;
    margin-top: -0.6rem;
    margin-bottom: -0.6rem;

    &:hover {
      background-color: @c-red;
      border-color: @c-red;
    }
  }

  &__section-content {
    padding: 3rem 2.4rem;
  }

  &__contacts {
    padding: 2rem 0;
  }

  &__alert {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    min-width: 9rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__footer-separator {
    margin: auto;
    padding: 0 1rem;
  }

  &__create-password {
    .reset-button();
    border-bottom: 1px dotted @c-blue;
    cursor: pointer;

    font-size: 1.1rem;
    line-height: 1.2em;
    color: @c-blue;

    transition: @time;

    &:hover {
      border-bottom: fade(@c-blue, 0);
    }
  }

  // addition

  &__addition-section {
    min-height: 1px;
    position: relative;
    margin: 2.5rem 0 2rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -2.4rem;
      width: calc(100% + 4.8rem);
      border-bottom: 1px dashed #e3e3e3;
    }
  }

  &__addition-section._open {
    &::before {
      display: none;
    }
  }

  &__addition-btn-toggle {
    position: absolute;
    top: -0.8rem;
    left: -0.6rem;
    border-radius: 0;
    z-index: 2;
    background: white;
    padding: 0 0.6rem;
    min-height: 0;

    color: @c-grey;

    &:hover {
      background: white;

      color: @c-blue;
    }

    & svg {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 0.5rem;
      fill: @c-grey;
    }
  }

  &__addition-section._open &__addition-btn-toggle {
    left: 1.6rem;

    &:hover {
      color: @c-red;
    }

    &:hover svg {
      fill: @c-red;
    }
  }

  &__addition-content {
    padding: 2.5rem 2rem;
    border: 1px dashed @c-grey-lighter;
    border-radius: @radius;
  }

  &__group-title {
    margin: 1rem 0 1.5rem;

    font-size: @f-size;
  }

  //&__checkbox-block {
  //  display: flex;
  //  align-items: center;
  //}

  //&__checkbox-label {
  //  font-weight: 700;
  //  display: inline-block;
  //  margin-right: 1rem;
  //}

  &__placeholders {
    &-title {
      font-size: 1.8rem;
      margin: 0 0 0.8rem;
    }
    &-group {
      margin: 0.5rem -0.3rem;
      &:first-child {
        margin-top: -0.3rem;
      }
      &:last-child {
        margin-bottom: -0.3rem;
      }
      & > * {
        margin: 0.3rem;
      }
    }

    &-btn {
      text-transform: none;
      overflow: visible;
      .copied {
        position: absolute;
        white-space: nowrap;
        background-color: white;
        padding: 0.5rem;
        box-shadow: 0 0.4rem 0.6rem fade(black, 15);
        border-radius: @radius;
        bottom: 100%;
        margin-bottom: 0.5rem;
        color: @c-grey-darker;
        pointer-events: none;
        transform: translateY(50%);
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: @time-modal;

        &::after {
          position: absolute;
          content: '';
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-top: 0.4rem solid white;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
        }
      }

      &._copied {
        .copied {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  &__confirmed {
    font-size: 1.2rem;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &-ico {
      width: 1.8rem;
      height: 1.8rem;
      background-color: #aa0300;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      &._confirmed {
        background-color: #14a75d;
      }

      svg {
        width: 1rem;
        height: 1rem;
        fill: white;
      }
    }

    & > span {
      display: inline-block;
    }

    &-edit {
      .reset-button;
      cursor: pointer;
      vertical-align: middle;
      padding: 0.3rem;
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;

      color: @c-blue;

      &:hover {
        color: @c-blue-light;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: currentColor;
      }
    }

    .date-input {
      display: inline-flex;
    }
  }
}
