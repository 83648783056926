.commodity-full-edit {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  &__section {
    margin-bottom: 1px;
  }

  &__btn-save {
    position: sticky;
    z-index: 71;
    bottom: 2rem;
    //transform: translateX(-50%);
    min-width: 10rem;
    align-self: center;

    &:disabled {
      background-color: tint(@c-green, 65);
      opacity: 1;
    }
  }
}
