.bookings-page {
  &__main-content {
    position: relative;
    //height: 28rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    //flex-direction: column;
  }

  &__booking-list {
    min-width: 30rem;
    flex: 1 1 40rem;
    border-right: 1px solid @c-grey-lighter;
  }

  .booking-list__table {
    thead {
      position: sticky;
      top: 10.4rem;
      z-index: 32;
    }
  }

  &__one-booking {
    //margin-left: auto;
    //width: calc(100% - 30rem);
    //height: 45rem;
    //display: flex;
    //flex-direction: column;
    //
    //@media (max-width: @breakpoints[md]) {
    //  margin-left: 0;
    //  width: auto;
    //}
  }

  &__booking-list._short {
    width: 30rem;
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
    height: 100%;
  }
}
