
          @WINDOW_SIZE_XS: 450px;
          @WINDOW_SIZE_XM: 575px;
          @WINDOW_SIZE_SM: 749px;
          @WINDOW_SIZE_MD: 980px;
          @WINDOW_SIZE_ML: 1100px;
          @WINDOW_SIZE_LG: 1340px;
          @WINDOW_SIZE_XL: 1600px;
          @WINDOW_SIZE_XXL: 1920px;
          @IS_CONSTRUCTION: true;
          @import "@/assets/less/variables/variables.less";
          @import "@/assets/less/mixins/mixins.less";
        

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height var(--collapseDuration, 0.35s) ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width var(--collapseDuration, 0.35s) ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
