.main-header {
  background: white;
  padding: 0 @page-sides-padding;
  width: 100%;
  box-shadow: 0 0 0.5rem 0 fade(#cecece, 90);

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: @header-height;
    padding: 1rem 0;
  }

  &__logo {
    flex-shrink: 0;
    margin-right: auto;
    height: 3rem;
  }

  &__mail,
  &__help,
  &__setting-lists {
    .reset-button();
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;

    & svg {
      width: 2.2rem;
      height: 2.2rem;
      fill: @c-grey;

      transition: @time;
    }

    &:hover svg {
      fill: @c-blue;
    }
  }

  &__mail {
    margin-right: 2rem;
  }

  &__help {
    margin-right: 2rem;
  }

  &__separator {
    display: block;
    width: 1px;
    height: 2rem;
    background: @c-grey-lighter;
    margin: 0 2rem;
  }

  &__user-panel {
  }

  &__menu-btn {
    padding: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0;
    background: transparent;
    margin-left: 1.5rem;
    margin-right: -1rem;

    &:hover {
      background-color: @c-grey-lighter;
    }

    & svg {
      fill: @c-grey-darker;
      width: 2rem;
    }

    &:hover svg {
      fill: @c-blue-light;
    }
  }
}
