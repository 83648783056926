.commodity-delivery-rates-list {
  height: 100%;
  overflow: auto;
  &__header {
    z-index: 32;
    top: @header-height;
    background: white;
    padding: 2rem;
    display: flex;
    align-items: center;
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__table-wrap {
    position: relative;
  }

  &__table {
    width: 100%;
    margin: 0;

    & th._actions {
      width: 4.4rem;
    }

    & thead th {
      background-color: #d2d2d2;
      background-color: @c-grey-lighter;
      border-bottom: 0;
    }

    & tbody tr:nth-child(even) td {
      background-color: @c-grey-lightest;
    }

    & td:not(._no-link) {
      cursor: pointer;

      transition: @time;
    }
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }

  &__help-list {
    list-style: none;
    margin: 0;
    padding: 0;

    & li {
      margin: 0.6rem 0;
    }
  }
  &__notes-btn {
    position: relative;
    overflow: visible;

    &.btn svg {
      margin-right: 0;
    }
  }

  &__notes-btn-amount {
    position: absolute;
    top: -0.9rem;
    right: -0.9rem;
    background: @c-blue;
    min-width: 1.8rem;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;

    text-align: center;
    color: white;
  }
  // - - - -

  &._short &__header {
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__short-item {
    display: flex;
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    //background: @c-grey-lightest;

    cursor: pointer;

    &._selected {
      background-color: @c-blue-2;
    }
  }

  &__short-checkbox {
    flex-shrink: 0;
    margin-right: 2rem;
  }

  &__short-item-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
    width: 50%;
  }

  &__short-el {
    width: 48%;
    font-size: 1.2rem;

    .no-wrap();

    &._right {
      text-align: right;
    }
  }
}
