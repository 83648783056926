.address {
  width: 100%;

  &__row {
    display: flex;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__country {
    flex-grow: 1;
    min-width: 12rem;
    width: 20%;
  }

  &__input {
    width: 20%;
    flex-grow: 1;
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }

    &._code {
      flex-grow: 0;
      width: 10em;
    }
  }
}
