@import '~@/assets/css-blanks/blank-base.less';

@size: 12px;
@line-height: @f-lineheight-small;

* {
  box-sizing: border-box;
  outline: none;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.page-break-inside-avoid {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
}

table {
  width: 100%;
  margin: 0;
  table-layout: fixed;
  border-collapse: collapse;

  font: inherit;

  & th,
  & td {
    padding: 0;
    border: 0;
    background: transparent;

    font: inherit;
    text-align: left;
    vertical-align: top;
  }
}

.blank-invoice {
  zoom: var(--blank-preview-zoom, 1);
  width: @blank-width;
  min-height: @blank-height;
  margin: 0 auto;
  background: white;
  padding: @blank-side-top @blank-side-right @blank-side-bottom @blank-side-left;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.25);

  &__content {
    //padding: 0 @blank-side-right 0 @blank-side-left;
    //zoom: 0.8;
    //page-break-inside: avoid;
    //page-break-after: avoid;
    //page-break-before: avoid;

    color: @c-grey-darkest;
    font-size: @size;
    line-height: @line-height;
    font-family: 'Open Sans', system-ui, sans-serif;
    white-space: normal;
    word-break: break-word;
  }

  & &__content {
    padding: 0;
    zoom: @blank-zoom;
  }

  &__head {
    margin-bottom: 15px;
    border-bottom: 1px dashed @c-grey-light;

    & td {
      padding-bottom: 15px;
      vertical-align: top;
    }
  }

  &__logo {
    display: block;
    width: 130px;
    height: 40px;
    //max-height: 40px;
    object-fit: contain;
  }

  &__name {
    margin: 0 0 5px;

    font-size: 22px;
    line-height: 1em;
    text-align: right;
    text-transform: uppercase;
  }

  &__number {
    margin: 0;

    text-align: right;
    font-size: 16px;
    line-height: @line-height;
    color: @c-grey;
  }

  &__company-name {
    margin: 0 0 10px;

    font-size: 14px;
    line-height: @line-height;
  }

  &__company-address {
    margin: 0;

    font-size: 12px;
    line-height: @line-height;
    color: @c-grey;
  }

  &__main {
  }

  &__info {
    padding: 20px 0 30px;
  }

  &__info-list-title {
    margin: 0 0 15px;

    font-size: 16px;
    color: @c-grey;
  }

  &__info-list {
    margin: 0;
    padding: 0;
    list-style: none;

    & li {
      margin: 0 0 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__info-table {
    margin: 0 0 0 auto;

    & td {
      padding: 5px 0;
      border: 0;
    }

    & td:first-child {
      padding-right: 10px;

      color: @c-grey;
    }

    & td:last-child {
      text-align: right;
    }
  }

  &__items-wrap {
  }

  &__items-title {
    margin: 0 0 15px;

    font-size: 20px;
  }

  &__items-table {
    width: 100%;
    margin: 0;

    & th,
    & td {
      padding: 12px 10px;
      border-bottom: 1px solid @c-grey-lighter;

      text-align: left;
      line-height: @f-lineheight;
      vertical-align: middle;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }

    & th:last-child,
    & td:last-child {
      text-align: right;
      width: 150px;
    }

    & th:first-child,
    & td:first-child {
      width: 250px;
    }

    th {
      background: @c-grey-lighter;

      line-height: 1.35em;
      font-weight: @w-semi-bold;
    }

    tr._has-contract {
      td {
        padding-bottom: 5px;
        border-bottom-style: dashed;
      }
    }

    tr.contract-item {
      td {
        padding-top: 5px;
        text-align: left;
      }
    }
  }
}
