.files-attach-document {
  &__select,
  &__file {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
