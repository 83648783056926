.g-c-white {
  color: white !important;
}

.g-c-primary {
  color: @c-primary-text !important;
}

.g-c-black {
  color: @c-grey-darkest !important;
}

.g-c-grey {
  color: @c-grey !important;
}

.g-c-grey-dark {
  color: @c-grey-dark !important;
}

.g-c-blue {
  color: @c-blue !important;
}

.g-c-yellow {
  color: @c-yellow !important;
}

.g-c-orange {
  color: @c-orange !important;
}

.g-c-green {
  color: @c-green !important;
}
.g-c-green-light {
  color: @c-green-light !important;
}

.g-c-red {
  color: @c-red !important;
}
