* {
  box-sizing: border-box;
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 1.5em 0;
  line-height: 1.2em;
  font-weight: 600;
  letter-spacing: 0;
}
h1,
.h1 {
  margin: 0.6em 0;
  font-size: 55px;
}
h2,
.h2 {
  margin: 0.8em 0;
  font-size: 48px;
}
h3,
.h3 {
  margin: 1.2em 0;
  text-transform: none;
  font-size: 26px;
}
h4,
.h4 {
  margin: 1.33333333em 0;
  font-size: 20px;
}
h5,
.h5 {
  font-size: 18px;
  text-transform: uppercase;
}
h6,
.h6 {
  font-size: 14px;
}
a {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
  transition: color 0.15s;
}
@media (hover: hover) and (pointer: fine) {
  a:hover {
    text-decoration: none;
    color: #8f1b2c;
  }
}
address {
  font: inherit;
  color: #616161;
  line-height: 1.71428571em;
}
ul,
ol,
p,
table {
  margin: 1.25em 0;
  color: inherit;
  font-size: inherit;
}
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
.page-break-inside-avoid {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
}
b {
  font-weight: 600;
}
table {
  border-collapse: separate;
  border-spacing: 10px 2px;
  max-width: 100%;
  font-size: 12px;
  line-height: 1.33333333em;
  color: #1f1f1f;
  text-align: left;
}
th,
td {
  padding: 0;
  border: 0;
  text-align: inherit;
  line-height: 1.33333333em;
  vertical-align: top;
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}
th {
  background: transparent;
  font-weight: 600;
}
tfoot td {
  font-weight: 600;
}
table {
  page-break-before: auto;
  page-break-inside: auto;
  page-break-after: auto;
}
tr {
  page-break-before: auto;
  page-break-inside: auto;
  page-break-after: auto;
}
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}
.text {
  margin: 0;
  font: inherit;
  font-size: 12px;
  line-height: 1.33333333em;
  color: inherit;
}
.text::after {
  content: '';
  display: table;
  width: 100%;
}
.text p,
.text ul,
.text ol,
.text table,
.text img {
  max-width: 100%;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.text object,
.text video,
.text iframe {
  margin: 1.5em 0;
  max-width: 100%;
}
.text ul,
.text ol,
.text table {
  margin-top: 0.8333em;
  margin-bottom: 0.8333em;
}
.text b,
.text strong {
  font-weight: bold;
}
.text i,
.text em {
  font-style: italic;
}
.text a {
  color: #8f1b2c;
  text-decoration: underline;
}
.text a:hover {
  text-decoration: none;
}
.text img {
  display: block;
  margin: 2em 0;
  max-width: 100%;
}
.text > *:first-child,
.text > *:first-child > *:first-child {
  margin-top: 0;
}
.text > *:last-child,
.text > *:last-child > *:last-child {
  margin-bottom: 0;
}
.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  font-weight: 700;
  letter-spacing: 0.02em;
}
.text h1 {
  margin: 0.39130435em 0;
  font-size: 3.5em;
  line-height: 1.2em;
}
.text h2 {
  margin: 0.6em 0;
  font-size: 2.66666em;
  line-height: 1.2em;
}
.text h3 {
  margin: 0.9em 0;
  font-size: 2em;
  line-height: 1.2em;
}
.text h4 {
  margin: 1.125em 0;
  font-size: 1.5em;
  line-height: 1.2em;
}
.text h5 {
  margin: 1.28571429em 0;
  font-size: 1.33333em;
  line-height: 1.2em;
}
.text h6 {
  margin: 1.5em 0;
  font-size: 1em;
  line-height: 1.2em;
  text-transform: uppercase;
}
.text ul,
.text ol {
  list-style: none;
  padding: 0 0 0 35px;
}
.text li {
  position: relative;
  margin: 0.66em 0;
}
.text li::before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
}
.text li:first-child {
  margin-top: 0;
}
.text li:last-child {
  margin-bottom: 0;
}
.text li p {
  margin: 0;
}
.text ul li:before {
  display: inline-block;
  top: 7px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #8f1b2c;
  margin-right: 8px;
}
.text ol {
  counter-reset: counter;
}
.text ol li {
  counter-increment: counter;
}
.text ol li:before {
  content: counter(counter) '.';
  margin-right: 3px;
  color: #8f1b2c;
  font-weight: 700;
  white-space: nowrap;
}
.text table {
  display: block;
  overflow-x: auto;
}
.text pre {
  font: inherit;
}
.g-bg-red {
  background-color: #8f1b2c !important;
}
.g-bg-green {
  background-color: #14a75d !important;
}
.g-bg-blue {
  background-color: #4778ff !important;
}
.g-bg-yellow {
  background-color: #e7a811 !important;
}
.g-bg-black {
  background-color: #1f1f1f !important;
}
.g-bg-grey-lightest {
  background-color: #f2f2f2 !important;
}
.g-bg-white {
  background-color: white !important;
}
.g-c-white {
  color: white !important;
}
.g-c-primary {
  color: #333333 !important;
}
.g-c-black {
  color: #1f1f1f !important;
}
.g-c-grey {
  color: #808080 !important;
}
.g-c-grey-dark {
  color: #616161 !important;
}
.g-c-blue {
  color: #4778ff !important;
}
.g-c-yellow {
  color: #e7a811 !important;
}
.g-c-orange {
  color: #e17d10 !important;
}
.g-c-green {
  color: #14a75d !important;
}
.g-c-green-light {
  color: #00bb5d !important;
}
.g-c-red {
  color: #8f1b2c !important;
}
.g-w-regular {
  font-weight: 400 !important;
}
.g-w-semi-bold {
  font-weight: 600 !important;
}
.g-w-medium {
  font-weight: 500 !important;
}
.g-w-bold {
  font-weight: 700 !important;
}
.nobr {
  white-space: nowrap;
  word-wrap: break-word;
  overflow-wrap: normal;
}
.g-text-uppercase {
  text-transform: uppercase !important;
}
.g-text-transform-none {
  text-transform: none !important;
}
.g-text-underline {
  text-decoration: underline !important;
}
.g-text-left {
  text-align: left !important;
}
.g-text-right {
  text-align: right !important;
}
.g-text-center {
  text-align: center !important;
}
.g-wrap {
  white-space: normal;
  word-break: break-word;
}
.g-no-wrap {
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}
.reset-button {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;
}
.reset-button:not(:disabled):hover {
  text-decoration: unset;
}
* {
  box-sizing: border-box;
  outline: none;
}
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
.page-break-inside-avoid {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
}
table {
  width: 100%;
  margin: 0;
  table-layout: fixed;
  border-collapse: collapse;
  font: inherit;
}
table th,
table td {
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit;
  text-align: left;
  vertical-align: top;
}
.blank-booking {
  zoom: var(--blank-preview-zoom, 1);
  width: 8.5in;
  min-height: 0;
  margin: 0 auto;
  background: white;
  padding: 0.4in 0.4in 0.4in 0.4in;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.25);
}
.blank-booking__content {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
  color: #1f1f1f;
  font-size: 12px;
  line-height: 1.33333333em;
  font-family: 'Open Sans', system-ui, sans-serif;
  white-space: normal;
  word-break: break-word;
}
.blank-booking .blank-booking__content {
  padding: 0;
  zoom: 0.8;
}
.blank-booking__head {
  margin-bottom: 15px;
  border-bottom: 1px dashed #b8b8b8;
}
.blank-booking__head td {
  padding-bottom: 15px;
  vertical-align: top;
}
.blank-booking__logo {
  display: block;
  width: 130px;
  height: 40px;
  object-fit: contain;
}
.blank-booking__name,
.blank-booking__sub-name {
  margin: 0 0 5px;
  font-size: 22px;
  line-height: 1em;
  text-align: right;
  text-transform: uppercase;
}
.blank-booking__sub-name {
  margin-bottom: 10px;
  font-size: 14px;
}
.blank-booking__number {
  margin: 0;
  text-align: right;
  font-size: 16px;
  line-height: 1.33333333em;
  color: #808080;
}
.blank-booking__company-name {
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.33333333em;
}
.blank-booking__company-address {
  margin: 0;
  font-size: 12px;
  line-height: 1.33333333em;
  color: #808080;
}
.blank-booking__info {
  margin-bottom: 45px;
}
.blank-booking__table {
  width: 100%;
  margin-bottom: 20px;
}
.blank-booking__table col {
  width: 25%;
}
.blank-booking__table td {
  padding: 10px;
  border: 1px solid #dedede;
  vertical-align: top;
  height: 60px;
  font-size: 12px;
}
.blank-booking__cell-title {
  margin: 15px 0 7px;
  font-size: 12px;
  line-height: 1.33333333em;
  color: #8a8a8a;
  font-weight: 400;
}
.blank-booking__cell-title:first-child {
  margin-top: 0;
}
.blank-booking__section-title {
  margin: 0 0 25px;
  font-size: 20px;
  line-height: 1.33333333em;
}
.blank-booking__section-sub-title {
  position: relative;
  margin: 30px 0 25px;
  font-size: 14px;
  line-height: 1.33333333em;
  font-weight: 600;
  text-transform: uppercase;
}
.blank-booking__section-sub-title::before {
  content: '';
  position: absolute;
  top: 9px;
  left: 0;
  width: 100%;
  border-bottom: 1px dashed #b8b8b8;
}
.blank-booking__section-sub-title > span {
  position: relative;
  z-index: 2;
  background: white;
  padding-right: 10px;
}
.blank-booking__trucking-table-wrap {
  width: auto;
  margin: 0 -10px 25px;
}
.blank-booking__trucking-table {
  border-collapse: separate;
  border-spacing: 10px 0;
}
.blank-booking__trucking-info-row {
  margin-bottom: 25px;
}
.blank-booking__containers-table-wrap {
  margin-bottom: 4rem;
}
.blank-booking__containers-table {
  width: 100%;
}
.blank-booking__remark {
  margin: 0;
  position: relative;
  padding-left: 10px;
  color: #e7a811;
  font-size: 12px;
  line-height: 1.33333333em;
  white-space: pre;
}
.blank-booking__remark::before {
  content: '*';
  position: absolute;
  left: 0;
}
