.inner-nav {
  &__list,
  &__sub-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list {
  }

  a&__link,
  a&__sub-link,
  button&__link,
  button&__sub-link {
    transition: @time;
    cursor: pointer;

    &:hover {
      background-color: @c-grey-lightest;

      text-decoration: none;
      color: inherit;
    }
  }

  &__link,
  &__sub-link {
    .reset-button();
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 2rem 0.9rem 2rem;
    border-top: 1px solid @c-grey-lighter;

    text-decoration: none;

    a&._active,
    button&._active {
      background: @c-grey-lightest;
      color: @c-red;
    }
  }

  &__link {
    font-weight: @w-semi-bold;
  }

  &__item:first-child &__link {
    border-top: 0;
  }

  &__sub-link {
    padding-left: 4rem;

    font-size: 1.2rem;

    &:not(a):not(button) {
      font-weight: @w-semi-bold;
    }
  }

  &__arrow {
    width: 0.8rem;
    height: 1rem;
    opacity: 0.3;
    flex-shrink: 0;

    transition: @time;
    margin-left: 0.5rem;
  }

  &__link._active &__arrow,
  &__sub-link._active &__arrow {
    fill: @c-red;
    opacity: 1;
  }

  &__sub-list &__sub-list &__sub-link {
    padding-left: 6rem;
  }

  &__search-wrap {
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__search {
    width: 100%;
  }

  @media (max-width: @breakpoints[xl]) {
    &__sub-link {
      padding-left: 3rem;
    }

    &__sub-list &__sub-list &__sub-link {
      padding-left: 4rem;
    }
  }
}
