.commodity-edit {
  position: relative;

  &.info-block {
    margin: -2rem;
  }

  &__sub-section {
    margin-top: 3rem;
    padding-top: 3rem;
    border-top: 1px dashed @c-grey-lighter;
  }

  &__customer {
    padding: @input-padding;
    border: 1px solid @c-grey-lightest;
    background: @c-grey-lightest;
    border-radius: @radius;

    line-height: @f-lineheight;
  }

  &__canceled {
    margin: 0;
    width: auto;
    align-self: flex-start;
    padding: 0.5rem 2rem 0.5rem 1.5rem;
    border-radius: @radius;
    background: @c-orange;
    display: flex;
    align-items: center;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: white;
    font-weight: @w-semi-bold;

    & b {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background: white;
      margin-right: 0.8rem;

      color: @c-orange;
    }
  }

  &__input-preloader-wrap {
    position: relative;
  }

  &__input-preloader {
    width: auto;
    right: 1.8rem;
    left: auto;
  }
}
