.dropdown {
  position: relative;
  display: inline-flex;

  font-size: @f-size-small;
  line-height: @f-lineheight-small;

  &__btn {
    width: 100%;
    border-color: @c-grey-light;
    background: transparent;
    padding-left: @input-padding-side;
    padding-right: 0;

    font: inherit;
    text-align: left;
    color: @c-grey-light;
    text-decoration: none;
    text-transform: none;

    &:hover {
      background-color: @c-grey;
      border-color: @c-grey;

      color: white;
    }
  }

  &._dashed &__btn {
    border-style: dashed;
  }

  &__btn-text {
    width: 50%;
    flex: 1 1 auto;
    .no-wrap();
  }

  &__arrow {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    padding-right: 0.5rem;
    margin-left: 0.2rem;

    & svg {
      width: 0.6rem;
      height: 1rem;
      transform: rotate(90deg);

      fill: @c-grey-light;

      transition: @time;
    }
  }

  &__btn:hover &__arrow svg {
    fill: white;
  }

  &._open &__arrow svg {
    transform: rotate(270deg);
  }

  &__dropdown {
    position: absolute;
    z-index: 41;
    top: calc(100% + 0.5rem);
    left: -2rem;
    width: calc(100% + 4rem);
    height: 1rem;
    //background: red;

    transition: @time-modal;
  }

  &__btn ~ &__dropdown {
    top: 100%;
  }

  &._on-hover &__dropdown {
    visibility: hidden;
    opacity: 0;
  }

  &._on-hover:hover &__dropdown {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

  &__drop-arrow {
    pointer-events: none;
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.6rem;
      left: 0.5rem;
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg);
      transform-origin: center;
      background: white;
      box-shadow: 0 0 0.6rem fade(black, 15);
    }
  }

  &__dropdown-inner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 0.6rem 0;
    box-shadow: 0 0.4rem 0.6rem fade(black, 15);
    border: 1px solid @c-grey-lighter;
    border-top: 0;
    border-radius: @radius;
    min-width: 100%;
  }

  &__drop-arrow ~ &__dropdown-inner {
    top: 100%;
  }

  &._right &__dropdown {
    left: auto;
    right: 0;
  }

  &._right &__dropdown-inner {
    left: auto;
    right: 0;
    transform: none;
  }

  &._left &__dropdown {
    left: 0;
  }

  &._left &__dropdown-inner {
    left: 0;
    transform: none;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    background: transparent;
    border: 0;
    padding: 0.8rem @input-padding-side;
    cursor: pointer;

    text-decoration: none;
    text-align: left;

    transition: @time;

    &:disabled {
      cursor: not-allowed;
    }

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1rem;
      flex-shrink: 0;
      fill: @c-grey-dark;

      transition: @time;
    }

    &:not(:disabled):hover {
      background-color: @c-blue;
      color: white;
      text-decoration: none;
    }

    &:not(:disabled):hover svg {
      fill: white;
    }

    &._red:not(:disabled) {
      color: @c-red;

      & svg {
        fill: @c-red;
      }
    }

    &._green:not(:disabled) {
      color: @c-green;

      & svg {
        fill: @c-green;
      }
    }

    &._red:not(:disabled):hover,
    &._green:not(:disabled):hover {
      background-color: @c-red;
      color: white;

      & svg {
        fill: white;
      }
    }

    &._green:not(:disabled):hover {
      background-color: @c-green;
    }
  }

  &._right &__item {
    text-align: right;
  }
}
