.message-modal-content {
  position: relative;

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    &:first-child {
      margin-top: 0;
    }
  }

  &__btn {
    min-width: 12rem;
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .text {
    text-align: center;
    white-space: pre-line;
  }
}
