.persons-list {
  @media (max-width: @breakpoints[sm]) {
    &__header {
      margin-bottom: 1.6rem;
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
      position: static;
      background-color: transparent;
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    &__mob-list {
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
    }
    &__photo-wrap {
      margin-top: 0;
      margin-bottom: 0;
      width: 4rem;
      height: 4rem;
    }

    &__info-name {
      font-size: 1.2rem;
      font-weight: @w-semi-bold;
      line-height: 1.4;
    }

    &__info {
      padding-top: 0;
      & > *:last-child {
        margin-bottom: 0;
      }
    }

    &__info-company {
      color: #888888;
      font-size: 1.2rem;
      text-transform: uppercase;
      line-height: 1.4;
    }

    &__mob-person {
      padding: 1.2rem;
      padding-right: 4rem;
      background-color: white;
      border: 1px solid #e0e0e0;
      border-radius: 0.4rem;
      margin-bottom: 0.8rem;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__mob-person-contacts {
      display: flex;
      margin-top: 0.8rem;
      svg {
        width: 1.3rem;
        height: 1.3rem;
        flex-shrink: 0;
        margin-right: 1rem;
        margin-top: 0.2rem;
        transition: fill @time;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        line-height: 1.4;

        li {
          margin-bottom: 0.3rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__mob-person-checkbox {
      padding: 1.2rem;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
    }

    &__mob-person-checkbox._checked ~ &__mob-person-contacts {
      svg {
        fill: #14a75d;
      }
    }

    &__selection-toolbar {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 50;
      background-color: white;
      padding: 0.9rem @page-sides-padding-sm;
      width: 100%;
    }

    &__selected {
      order: -1;
      margin-right: auto;

      .checkbox {
        vertical-align: middle;
        padding: 0.8rem;
        margin: -0.8rem;
        margin-right: 0;
        margin-top: -1rem;
      }
    }

    &__head-btn {
      background-color: #808080 !important;
      border-color: #808080 !important;

      svg {
        fill: white !important;
      }
    }

    &__drop-menu {
      margin-right: 0;

      .dropdown__dropdown {
        top: auto;
        bottom: 100%;
        left: auto;
        right: 1.5rem;
        margin-bottom: 0.5rem;
      }

      .dropdown__dropdown-inner {
        top: auto;
        bottom: 0;
      }
    }

    &__search {
      margin-right: 0;
      width: 100%;
    }
  }
}
