.commodity {
  @media (max-width: @breakpoints[ml]) {
    &__head {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    &__gallery {
      margin-bottom: 3rem;
      width: 100%;
    }

    &__main-wrap {
      flex-wrap: wrap;
    }

    &__main {
      width: unset;
    }
  }

  @media (max-width: @breakpoints[sm]) {
    &__row {
      flex-wrap: wrap;
    }

    &__row-item {
      margin-right: 0;
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}
