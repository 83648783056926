.customer-tabs {
  @media (max-width: @breakpoints[md]) {
    &__head {
      mask-image: linear-gradient(
        to right,
        fade(black, 0),
        black @page-sides-padding-sm,
        black @page-sides-padding-sm,
        black calc(100% - @page-sides-padding-sm),
        fade(black, 0) 100%
      );
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
    }

    &__tab-item {
      margin-right: 1.6rem;
    }

    &__tab {
      padding-left: 0;
      padding-right: 0;
    }

    &__plus.btn._small {
      margin-left: 0.5rem;
    }
  }
  @media (max-width: @breakpoints[sm]) {
    &__head-wrap {
      position: sticky;
      top: @header-height;
      background-color: #f9f9fb;
      z-index: 10;
    }
  }
}
