.shipping-file-files {
  position: relative;
  padding: 2rem;

  &__manipulation {
    margin-bottom: 2rem;
  }

  &__files {
  }
}
