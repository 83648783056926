.reports-page {
  &__content-head {
  }

  &__content {
    display: flex;
    flex: 1 1 0;
    overflow: hidden;
    //min-height: 40rem;
  }

  &__nav {
    width: 40rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #dedede;
  }

  &__main {
    flex: 1;
    width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__main-content {
    padding: 2rem 2rem 4rem;
    overflow-x: auto;
    overflow-y: hidden;
    flex-shrink: 0;
  }

  &__table {
    margin-left: auto;
    margin-right: auto;
  }

  &__default {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    &-title {
      color: @c-grey;
    }
  }

  &__content-header {
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    display: flex;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    gap: 1.5rem 2rem;
  }
  &__category-select {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  &__content-header-wrap {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  &__dates-select {
    width: 20rem;

    .select__option._disabled {
      display: none;
    }
  }

  &__dates-range {
    width: 25rem;
  }

  &__actions {
    margin-left: auto;
  }

  @media (max-width: @breakpoints[xl]) {
    &__nav {
      width: 30rem;
    }
  }

  @media (max-width: @breakpoints[ml]) {
    &__content {
      flex-basis: auto;
    }
    &__main {
    }
    &__main-content {
    }

    &__content-header {
    }
  }

  @media (max-width: @breakpoints[sm]) {
    &__content {
    }
    &__main {
    }
    &__main-content {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    &__category-select {
      margin-left: 1.6rem;
      margin-right: 1.6rem;
    }

    &__content-header {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      background: transparent;
    }
  }
  @media (max-width: @breakpoints[sm]) {
    &__content {
    }
    &__main {
    }
    &__main-content {
    }

    &__content-header {
      flex-wrap: wrap;
    }

    &__dates-select {
      flex: 1 1 100%;
    }

    &__dates-range {
      flex: 1;
      //order: 1;
      //flex: 1 1 100%;
    }
  }
}
