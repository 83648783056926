@media screen and (max-width: @breakpoints[md]) {
  .text {
    & table {
      display: block;
      overflow-x: auto;
    }

    & img {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}
