.customer-notes {
  position: relative;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__scroll-block {
    height: 20rem;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__alert {
    flex-shrink: 0;
    margin: 1rem 2rem;
  }

  &__table {
    width: 100%;
    margin: 0;

    & th:nth-child(1),
    & td:nth-child(1) {
      width: 15.5rem;
    }

    & th:nth-child(2),
    & td:nth-child(2) {
      //width: 42.6rem;
    }

    & th:nth-child(3),
    & td:nth-child(3) {
      width: 20%;
      //width: 16rem;
    }

    & th:last-child,
    & td:last-child {
      //width: 9rem + @scroll-width;
      width: 10.1rem;
      text-align: right;
    }
  }

  &__scroll-block {
    & th:last-child,
    & td:last-child {
    }
  }

  &__scroll-block tr:last-child td {
    border-bottom: 0;
  }

  &__scroll-pusher {
    padding: 0;
    width: @scroll-width !important;
  }

  & a {
    text-decoration: none;
  }

  &__input {
    width: 100%;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    border-radius: @radius;

    & .editr--toolbar {
      display: none;
    }

    & .editr--content {
      min-height: 3.2rem;
    }

    .editor__content {
      padding: 0.7rem 1rem 0.8rem;
    }
    .ProseMirror {
      min-height: auto;
    }
  }

  &__note {
    > div {
      margin: 0;
    }
  }

  &__btn {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__btn-save {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
}
