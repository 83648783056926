.storage-rates-toolbar {
  display: flex;
  align-items: center;
  transition: @time;
  &__drop-menu {
    margin-right: 1rem;
  }

  &__drop-menu .dropdown__dropdown-inner {
    width: 15rem;
  }
  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__head-btn {
    margin-right: 1rem;
  }
}
