.dashboard-page {
  &__menu-btn {
    padding: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0;
    background: transparent;
    margin-right: 1rem;

    &:hover {
      background-color: @c-grey-lighter;
    }

    & svg {
      fill: @c-grey-darker;
      width: 2rem;
    }

    &:hover svg {
      fill: @c-blue-light;
    }
  }

  &__title {
    margin-right: auto;
  }

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    //padding-right: @page-sides-padding;
  }

  &__main-content {
    background: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
