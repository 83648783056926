.modal {
  position: fixed;
  z-index: 91;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
  display: flex;
  //align-items: center;
  //justify-content: center;
  background-color: rgba(64, 64, 69, 0.58);
  padding: 1rem;

  transition-duration: @time-modal;
  transition-property: opacity;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: contain;

  &__content {
    max-width: 100%;
    //max-height: 100%;
    width: 62.8rem;
    background: white;
    display: flex;
    flex-direction: column;

    border-radius: @radius;

    transition-duration: @time-modal;
    transition-property: transform;
    transform: translate3d(0, 0, 0);
    margin: auto;

    @media (max-width: @breakpoints[sm]) {
      width: 100%;
    }
  }

  &._not-current {
  }

  &._current {
  }

  &._for-doc &__content {
    //width: 85.3rem;
    width: 96rem;
  }

  // - - - -

  &._slim &__content {
    width: 36rem;
  }

  &._medium &__content {
    width: 40rem;
  }

  &._medium-lg &__content {
    width: 50rem;
  }

  &._wide &__content {
    width: 96rem;
  }

  &._x-wide &__content {
    width: 140rem;
  }

  &._full-wide &__content {
    width: 100%;
  }

  &._full-height &__content {
    align-self: stretch;
  }

  &._small-title &__title {
    font-size: 1.8rem;
  }

  &__head {
    flex-shrink: 0;
    background: @c-grey-lightest;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2.4rem 1.2rem;
    min-height: 6rem;
    border-radius: @radius @radius 0 0;
    position: sticky;
    top: -1rem;
    z-index: 100;
  }

  &._warning &__head {
    background-color: @c-yellow;

    color: white;
  }

  &__title-ico {
    flex-shrink: 0;
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1rem;
    margin-left: -0.2rem;
  }

  &__title {
    width: 50%;
    margin: 0;
    flex-grow: 1;
    //padding-top: 0.2rem;

    font-size: 2rem;
    line-height: 1.2em;
    font-weight: @w-semi-bold;
    white-space: pre-line;
  }

  &._warning &__title-ico,
  &._error &__title-ico {
    fill: white;
  }

  &._success &__title {
    color: @c-green;
  }

  &._success &__title-ico {
    fill: @c-green;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border: 1px solid #979797;
    margin-left: 3rem;
    border-radius: 50%;

    transition: @time;

    &:hover {
      border-color: @c-red;
    }

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: #979797;

      transition: @time;
    }

    &:hover svg {
      fill: @c-red;
      opacity: 1;
    }
  }

  &._warning &__close,
  &._error &__close {
    border-color: white;

    & svg {
      fill: white;
    }
  }

  &__main {
    padding: 2.5rem 2.4rem;
    //height: 50%;
    //flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    //overflow-x: hidden;
    //overflow-y: auto;
    //-webkit-overflow-scrolling: touch;
  }

  &._full-content {
    .modal {
      &__main {
        padding: 0;
      }
    }
  }

  @media (max-width: @breakpoints[sm]) {
    padding: 0;

    &__head {
      top: 0;
    }

    &__head,
    &__main {
      padding-left: @page-sides-padding-xs;
      padding-right: @page-sides-padding-xs;
    }
  }
}
