
          @WINDOW_SIZE_XS: 450px;
          @WINDOW_SIZE_XM: 575px;
          @WINDOW_SIZE_SM: 749px;
          @WINDOW_SIZE_MD: 980px;
          @WINDOW_SIZE_ML: 1100px;
          @WINDOW_SIZE_LG: 1340px;
          @WINDOW_SIZE_XL: 1600px;
          @WINDOW_SIZE_XXL: 1920px;
          @IS_CONSTRUCTION: true;
          @import "@/assets/less/variables/variables.less";
          @import "@/assets/less/mixins/mixins.less";
        

.c-v-accept {
  z-index: 9999;

  & .modal__head {
    background-color: @c-yellow;

    color: white;
  }

  & .modal__close {
    border-color: white;

    & svg {
      fill: white;
    }
  }

  & .modal__content {
    width: 40rem;
  }

  &__main {
    position: relative;
  }

  &__text {
    margin-top: -0.4rem;
    margin-bottom: 2rem;

    text-align: center;

    white-space: pre-line;
    font-size: 1.4rem;
  }

  &__password {
    margin-bottom: 2rem;
  }

  &__alert {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-right: -1rem;
  }

  &__btn {
    margin-right: 1rem;
  }
}
