.customer-emails {
  @media (max-width: @breakpoints[md]) {
    &__table {
      & th:first-child,
      & td:first-child {
        width: 10rem;
      }
    }
  }
  @media (max-width: @breakpoints[sm]) {
    height: auto;
    background-color: transparent;

    &__scroll-block {
      height: auto;
      overflow: visible;
    }
    &__table {
      & th:last-child,
      & td:last-child {
        width: 10rem;
      }
    }
  }
}
