.new-file-page {
  position: relative;
  display: flex;
  flex-direction: column;

  &__main {
    flex-grow: 1;
    position: relative;
    //padding-right: @page-sides-padding;
    display: flex;
    flex-direction: column;

    &._new-file {
      padding-right: 0;
    }
  }

  &__booking-section {
    margin: 0;
    width: 100%;
    border: none;
    margin-bottom: 3rem;

    & .info-block__main {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    & .info-block__row {
      margin-top: 2rem;
    }

    & .info-block__row:first-child {
      margin-top: 0;
    }

    @media (max-width: @breakpoints[ml]) {
      margin-top: 2rem;
    }
  }

  &__main-content {
    flex-grow: 1;
    background: white;
    padding-bottom: 3rem;
  }

  &__booking-table {
    width: 100%;
    margin: 0 0 4rem;
  }

  &__booking {
    width: 100%;
  }

  &__trucking-section {
    margin-bottom: 3rem;
    position: relative;
  }

  &__trucking {
    margin-top: 2rem;
  }

  &__tab-block {
    padding: 2rem;
  }

  &__tabs .tabs__tab {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  &__trucking-info-title {
    margin: 0 0 0.6rem;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
    font-weight: @w-regular;
  }

  &__trucking-info {
    margin: 0;
  }

  &._short .expenses-table col:first-child,
  &._short .expenses-table th:first-child,
  &._short .expenses-table td:first-child,
  &._short .expenses-table col:nth-child(2),
  &._short .expenses-table th:nth-child(2),
  &._short .expenses-table td:nth-child(2),

    //&._short .expenses-table col:last-child,
    //&._short .expenses-table th:last-child,
    //&._short .expenses-table td:last-child ,
  &._short .expenses-table col:nth-last-child(2),
  &._short .expenses-table th:nth-last-child(2),
  &._short .expenses-table td:nth-last-child(2) {
    display: none;
  }

  &._short .expenses-table th:nth-last-child(3),
  &._short .expenses-table td:nth-last-child(3) {
    padding-right: 2rem;
  }

  &._short .expenses-table th:nth-child(3),
  &._short .expenses-table td:nth-child(3) {
    padding-left: 2rem;
  }

  &._short .expenses-table .expenses-table__cell-content {
    max-width: 10rem;
  }

  &__add-booking {
    padding: 2rem;
  }

  &__add-icon {
    margin-right: 1rem !important;
  }

  &__number-edit {
    position: relative;
    display: flex;
    align-items: center;
    margin: -0.5rem 0;
  }

  &__button-edit {
    width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem !important;
    min-width: 2.5rem !important;
    margin-left: 2rem;
  }

  &__header-new {
    justify-content: initial;
  }

  &__save-file {
    padding: 0 2rem 0 2rem;
    display: flex;
    justify-content: flex-end;
  }

  &__error {
    margin: 0 2rem 2rem;
  }
}
