.expenses-table {
  width: 100%;
  margin: 0;

  & thead {
    position: sticky;
    top: @header-height + 5.5rem;
    z-index: 2;
  }

  &._static-head {
    thead {
      position: static;
    }
  }

  & td {
    vertical-align: top;

    &._no-link {
      cursor: auto;
    }
  }

  & col._checkbox {
    width: 5rem;
  }

  & col._toggle {
    width: 5.3rem;
  }

  & col._photo {
    width: 8.5rem;
  }

  & col._date {
    width: 12rem;
  }

  & col._agent {
    width: 20rem;
  }
  & col._status {
    width: 15rem;
  }

  & col._category {
    width: 18rem;
  }
  & col._actions {
    width: 4rem;
  }

  & col._memo {
    width: 20rem;
  }

  & col._payments {
    width: 20rem;
  }

  & col._customer {
    width: 25rem;
  }

  & col._vendor {
    width: 20rem;
  }

  & tr:nth-child(even) td {
    background-color: @c-grey-lightest;
  }

  // - - - -

  &__toggle {
    padding: 0;
    min-width: 2.4rem;
    min-height: 2.4rem;
    background: white;
    //box-shadow: 0 0 0.6rem fade(#bbbbbb, 50);
    border-color: @c-grey-lighter;

    & svg {
      width: 0.8rem;
      height: 1rem;
      transform: rotate(90deg);
      fill: @c-grey-darker;
    }

    &:hover {
      background-color: @c-blue;
    }

    &:hover svg {
      fill: white;
    }
  }

  &__table-row {
    cursor: pointer;
  }

  &__table-row._open &__toggle,
  &__card._open &__toggle {
    background: @c-blue;

    & svg {
      transform: rotate(270deg);
      fill: white;
    }
  }

  &__cell._first {
    transition: border-color @time;
    border-left: 1px dashed transparent;
  }
  &__cell._last {
    transition: border-color @time;
    border-right: 1px dashed transparent;
  }

  &__table-row._open &__cell._first {
    border-color: #dbdbdb;
  }
  &__table-row._open &__cell._last {
    border-color: #dbdbdb;
  }

  &__cell-content {
    position: relative;
    height: @filling-list-content-height;
    //max-width: 22rem;

    &._not-current > * {
      opacity: 0.35;
    }

    .d-flex > .g-no-wrap {
      flex: 1;
      width: 0;
    }
  }

  &__cell-list &__cell-content {
    height: @filling-list-content-height + 1.3rem;
    padding-top: 1.3rem;
  }

  &__cell-content::after {
    content: '';
    position: absolute;
    left: -1rem;
    top: calc(100% + 0.6rem);
    height: 0;
    width: calc(100% + 2rem);
    border-bottom: 1px dashed #dbdbdb;
    opacity: 0;

    transition: opacity @time;
  }

  &__table-row._open &__cell-content::after {
    opacity: 1;
  }

  &__cell-content:last-child::after {
    display: none;
  }

  &__amount {
    display: inline-block;
    min-width: 1.7rem;
    height: 1.7rem;
    background: white;
    box-shadow: 0 0 0.7rem fade(#a2a2a2, 50);
    border-radius: 0.3rem;
    opacity: 1;
    margin-right: 0.6rem;

    text-align: center;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-semi-bold;

    transition: @time;
    flex-shrink: 0;

    &._absolute {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      margin-right: 0;
    }
  }

  &__table-row._open &__amount {
    opacity: 0;
    display: none;
  }

  // - - - -

  &__row-separator {
    & td {
      background: white !important;
      padding: 0.5rem 0;
    }
  }

  &__dropdown {
    margin-left: 0.4rem;
  }

  &__dropdown .dropdown__dropdown-inner {
    width: 30rem;
  }

  &__drop-statuses-list {
    list-style: none;
    margin: -@expenses-list-drop-statuses-marg 0 0;
    padding: 1rem 2rem;
    display: flex;
    flex-wrap: wrap;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-regular;
  }

  &__drop-status {
    width: 48%;
    margin-top: @expenses-list-drop-statuses-marg;
  }

  &__photo-wrap {
    position: relative;
    background: @c-grey-light-2;
    overflow: hidden;
    width: 6.5rem;
    height: 4.6rem;
    //margin-top: -0.5rem;
    //margin-bottom: -0.5rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // - - - -

  &__short-item {
    display: flex;
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    //background: @c-grey-lightest;

    cursor: pointer;

    &._selected {
      background-color: @c-blue-2;
    }
  }

  &__short-checkbox {
    flex-shrink: 0;
    margin-right: 2rem;
  }

  &__short-item-content {
    flex-grow: 1;
    width: 50%;
    margin-top: -0.5rem;
    margin-right: -1rem;

    & > div {
      display: flex;
      justify-content: space-between;
    }
  }

  &__short-el {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    //flex-grow: 1;
    flex: 1;
    //width: 0;
    display: flex;
    margin-right: 1rem;

    .g-no-wrap {
      flex: 1;
      width: 0;
    }

    &._right {
      //flex-grow: 0;
      //flex-shrink: 0;
      text-align: right;
      justify-content: flex-end;
    }

    &._no-shrink {
      flex: 0 0 auto;
    }
  }

  &:not(table):not(._short) {
    padding: 1.6rem;
  }

  &__card {
    min-height: 9rem;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 1.1rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    background-color: white;
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    &:last-child {
      margin-bottom: 0;
    }

    &-info {
      padding-right: 3rem;

      &-item {
        margin-bottom: 0.3rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-actions {
      position: absolute;
      padding: 1.1rem;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > * {
        margin-bottom: 1.2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-item {
      padding: 1rem 3rem 1rem 0;
      position: relative;
      &-actions {
        position: absolute;
        right: 0;
        top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > * {
          margin-bottom: 1rem;
        }
      }
      &._first {
        margin-top: 1rem;
        border-top-style: solid;
      }
      &:not(._first) {
      }
      border-top: 1px dashed #e0e0e0;

      &:last-child {
        padding-bottom: 0;
      }

      &-row {
        //margin-bottom: .3rem;
        position: relative;
        //&:last-child {
        //  margin-bottom: 0;
        //}
        &._not-current > * {
          opacity: 0.35;
        }
      }

      .expenses-table__amount {
        right: auto;
        left: 0.2rem;
        top: 0.2rem;
      }

      .expenses-table__photo-wrap {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
