.notes {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__alert {
    flex-shrink: 0;
    margin: 1rem 2rem;
  }

  &__table {
    width: 100%;
    margin: 0;

    & th:nth-child(1),
    & td:nth-child(1) {
      width: 15.5rem;
    }
    & th:nth-child(3),
    & td:nth-child(3) {
      width: 20%;
    }

    & th:last-child,
    & td:last-child {
      width: 10.2rem;
      &:not(:first-child) {
        text-align: right;
      }
    }
  }

  & a {
    text-decoration: none;
  }

  &__input {
    width: 100%;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    border-radius: @radius;

    & .editr--toolbar {
      display: none;
    }

    & .editr--content {
      min-height: 3.2rem;
    }

    .editor__content {
      padding: 0.7rem 1rem 0.8rem;
    }
    .ProseMirror {
      min-height: auto;
    }
  }

  &__note {
    margin: 0;
  }

  &__btn {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__btn-save {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
}
