.payment-received-edit {
  display: flex;
  flex-direction: column;
  min-height: 30rem;

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    //padding-right: @page-sides-padding;
  }

  &__main-content {
    background: white;
    flex-grow: 1;
  }

  &__pay-full-amount {
    margin-top: 0.6rem;
  }

  &__invoices-head {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    margin: 2rem 0 2rem;
  }

  &__invoices-title {
    margin: 0 2rem 0 0;

    font-size: 2rem;
  }

  &__clear-link {
    border: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    cursor: pointer;

    font-size: 1.2rem;
    color: @c-red;

    &:hover {
      color: @c-red-light;
    }
  }

  &__invoices-table {
    width: 100%;
    margin: 0 0 2rem;

    & th:last-child {
      width: 13rem;
    }

    & td._payment {
      width: 12rem;
    }

    & td:not(._no-link) {
    }

    & tbody tr:nth-child(even) td {
      background-color: @c-grey-lightest;
    }
  }

  &__invoice-pay {
    width: 10rem;
    margin-top: -0.5rem;
  }

  &__pay-in-full,
  &__apply-full-amount {
    .reset-button();
    display: block;
    margin-bottom: -0.6rem;
    cursor: pointer;
    text-decoration: underline;

    font-size: 1rem;
    color: @c-blue;
  }

  &__apply-full-amount._disabled {
    color: @c-grey-light;
    cursor: not-allowed;
  }

  &__amount-received-btns {
    display: flex;
    justify-content: space-between;
  }
}

@supports (text-decoration-style: dotted) {
  .payment-received-edit {
    &__pay-in-full,
    &__apply-full-amount {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }
}
