.tabs {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  background: @c-grey-lightest;
  border-bottom: 0.2rem solid #e7e7e7;

  @media (max-width: @breakpoints[ml]) {
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }

  font-size: @f-size;

  &._white {
    background: white;
  }

  &__toggle-btn.btn {
    align-self: flex-end;
    margin-right: 1rem;
    border-radius: @radius @radius 0 0;
    border-bottom: 0;
    min-height: 4rem;
    //background-color: @c-grey-lighter;

    &:hover {
      border-color: @c-grey-lighter;
    }

    & svg {
      transform: rotate(90deg);
      fill: @c-blue-light;
    }

    &:hover svg {
      fill: @c-blue-light;
    }
  }

  &._open &__toggle-btn.btn svg {
    transform: rotate(270deg);
  }

  &__tab {
    position: relative;
    padding: 0.9rem 1rem;
    margin-right: 1rem;
    border: 0;
    box-shadow: none;
    background: transparent;
    cursor: pointer;

    text-decoration: none !important;
    font-weight: @w-semi-bold;

    &:last-child {
      margin-right: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 100%;
      height: 0.2rem;
      background: @c-green;
      transform: translateX(-50%) scaleX(0);

      transition: @time;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:not(:disabled):hover::after,
    &:not(:disabled)._active::after {
      transform: translateX(-50%) scaleX(1);
    }
  }

  &._hide &__tab::after {
    opacity: 0.5;
  }

  &__plus.btn._small {
    align-self: center;
    margin-left: -1rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: @c-green-light;
      border-color: @c-green-light;
    }

    & svg {
      width: 1rem;
      height: 1rem;
    }

    &:hover svg {
      fill: white;
    }
  }

  &__btn-wrap {
    align-self: center;
    padding: 0 1rem;

    .btn {
      text-transform: none;
    }
  }

  &__carousel {
    display: flex;
    flex: 1;
    width: 0;
    padding: 0 1rem;
    margin: 0 -1rem -0.2rem;
    mask-image: linear-gradient(
      to right,
      fade(black, 0),
      black 1rem,
      black 1rem,
      black calc(100% - 1rem),
      fade(black, 0) 100%
    );
    overflow-y: hidden;
    overflow-x: scroll;
    overscroll-behavior-x: contain;
    .hide-scrollbar();

    &-container {
      display: flex;
      width: 100%;
    }

    &-list {
      display: flex;
      width: 100%;
    }

    &-item {
      padding-bottom: 0.2rem;
      display: flex;
      margin-right: 1rem;
      flex-shrink: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
