.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.3rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.3rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.3rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.3rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.3rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.3rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.3rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.3rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.3rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.3rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.3rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.3rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.3rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.3rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.3rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

each(@breakpoints, {
  @media (max-width: @value) {
    .m-@{key}-0 {
      margin: 0 !important;
    }

    .mt-@{key}-0,
    .my-@{key}-0 {
      margin-top: 0 !important;
    }

    .mr-@{key}-0,
    .mx-@{key}-0 {
      margin-right: 0 !important;
    }

    .mb-@{key}-0,
    .my-@{key}-0 {
      margin-bottom: 0 !important;
    }

    .ml-@{key}-0,
    .mx-@{key}-0 {
      margin-left: 0 !important;
    }

    .m-@{key}-1 {
      margin: 0.3rem !important;
    }

    .mt-@{key}-1,
    .my-@{key}-1 {
      margin-top: 0.3rem !important;
    }

    .mr-@{key}-1,
    .mx-@{key}-1 {
      margin-right: 0.3rem !important;
    }

    .mb-@{key}-1,
    .my-@{key}-1 {
      margin-bottom: 0.3rem !important;
    }

    .ml-@{key}-1,
    .mx-@{key}-1 {
      margin-left: 0.3rem !important;
    }

    .m-@{key}-2 {
      margin: 0.5rem !important;
    }

    .mt-@{key}-2,
    .my-@{key}-2 {
      margin-top: 0.5rem !important;
    }

    .mr-@{key}-2,
    .mx-@{key}-2 {
      margin-right: 0.5rem !important;
    }

    .mb-@{key}-2,
    .my-@{key}-2 {
      margin-bottom: 0.5rem !important;
    }

    .ml-@{key}-2,
    .mx-@{key}-2 {
      margin-left: 0.5rem !important;
    }

    .m-@{key}-3 {
      margin: 1rem !important;
    }

    .mt-@{key}-3,
    .my-@{key}-3 {
      margin-top: 1rem !important;
    }

    .mr-@{key}-3,
    .mx-@{key}-3 {
      margin-right: 1rem !important;
    }

    .mb-@{key}-3,
    .my-@{key}-3 {
      margin-bottom: 1rem !important;
    }

    .ml-@{key}-3,
    .mx-@{key}-3 {
      margin-left: 1rem !important;
    }

    .m-@{key}-4 {
      margin: 1.5rem !important;
    }

    .mt-@{key}-4,
    .my-@{key}-4 {
      margin-top: 1.5rem !important;
    }

    .mr-@{key}-4,
    .mx-@{key}-4 {
      margin-right: 1.5rem !important;
    }

    .mb-@{key}-4,
    .my-@{key}-4 {
      margin-bottom: 1.5rem !important;
    }

    .ml-@{key}-4,
    .mx-@{key}-4 {
      margin-left: 1.5rem !important;
    }

    .m-@{key}-5 {
      margin: 3rem !important;
    }

    .mt-@{key}-5,
    .my-@{key}-5 {
      margin-top: 3rem !important;
    }

    .mr-@{key}-5,
    .mx-@{key}-5 {
      margin-right: 3rem !important;
    }

    .mb-@{key}-5,
    .my-@{key}-5 {
      margin-bottom: 3rem !important;
    }

    .ml-@{key}-5,
    .mx-@{key}-5 {
      margin-left: 3rem !important;
    }

    .p-@{key}-0 {
      padding: 0 !important;
    }

    .pt-@{key}-0,
    .py-@{key}-0 {
      padding-top: 0 !important;
    }

    .pr-@{key}-0,
    .px-@{key}-0 {
      padding-right: 0 !important;
    }

    .pb-@{key}-0,
    .py-@{key}-0 {
      padding-bottom: 0 !important;
    }

    .pl-@{key}-0,
    .px-@{key}-0 {
      padding-left: 0 !important;
    }

    .p-@{key}-1 {
      padding: 0.3rem !important;
    }

    .pt-@{key}-1,
    .py-@{key}-1 {
      padding-top: 0.3rem !important;
    }

    .pr-@{key}-1,
    .px-@{key}-1 {
      padding-right: 0.3rem !important;
    }

    .pb-@{key}-1,
    .py-@{key}-1 {
      padding-bottom: 0.3rem !important;
    }

    .pl-@{key}-1,
    .px-@{key}-1 {
      padding-left: 0.3rem !important;
    }

    .p-@{key}-2 {
      padding: 0.5rem !important;
    }

    .pt-@{key}-2,
    .py-@{key}-2 {
      padding-top: 0.5rem !important;
    }

    .pr-@{key}-2,
    .px-@{key}-2 {
      padding-right: 0.5rem !important;
    }

    .pb-@{key}-2,
    .py-@{key}-2 {
      padding-bottom: 0.5rem !important;
    }

    .pl-@{key}-2,
    .px-@{key}-2 {
      padding-left: 0.5rem !important;
    }

    .p-@{key}-3 {
      padding: 1rem !important;
    }

    .pt-@{key}-3,
    .py-@{key}-3 {
      padding-top: 1rem !important;
    }

    .pr-@{key}-3,
    .px-@{key}-3 {
      padding-right: 1rem !important;
    }

    .pb-@{key}-3,
    .py-@{key}-3 {
      padding-bottom: 1rem !important;
    }

    .pl-@{key}-3,
    .px-@{key}-3 {
      padding-left: 1rem !important;
    }

    .p-@{key}-4 {
      padding: 1.5rem !important;
    }

    .pt-@{key}-4,
    .py-@{key}-4 {
      padding-top: 1.5rem !important;
    }

    .pr-@{key}-4,
    .px-@{key}-4 {
      padding-right: 1.5rem !important;
    }

    .pb-@{key}-4,
    .py-@{key}-4 {
      padding-bottom: 1.5rem !important;
    }

    .pl-@{key}-4,
    .px-@{key}-4 {
      padding-left: 1.5rem !important;
    }

    .p-@{key}-5 {
      padding: 3rem !important;
    }

    .pt-@{key}-5,
    .py-@{key}-5 {
      padding-top: 3rem !important;
    }

    .pr-@{key}-5,
    .px-@{key}-5 {
      padding-right: 3rem !important;
    }

    .pb-@{key}-5,
    .py-@{key}-5 {
      padding-bottom: 3rem !important;
    }

    .pl-@{key}-5,
    .px-@{key}-5 {
      padding-left: 3rem !important;
    }

    .m-@{key}-n1 {
      margin: -0.3rem !important;
    }

    .mt-@{key}-n1,
    .my-@{key}-n1 {
      margin-top: -0.3rem !important;
    }

    .mr-@{key}-n1,
    .mx-@{key}-n1 {
      margin-right: -0.3rem !important;
    }

    .mb-@{key}-n1,
    .my-@{key}-n1 {
      margin-bottom: -0.3rem !important;
    }

    .ml-@{key}-n1,
    .mx-@{key}-n1 {
      margin-left: -0.3rem !important;
    }

    .m-@{key}-n2 {
      margin: -0.5rem !important;
    }

    .mt-@{key}-n2,
    .my-@{key}-n2 {
      margin-top: -0.5rem !important;
    }

    .mr-@{key}-n2,
    .mx-@{key}-n2 {
      margin-right: -0.5rem !important;
    }

    .mb-@{key}-n2,
    .my-@{key}-n2 {
      margin-bottom: -0.5rem !important;
    }

    .ml-@{key}-n2,
    .mx-@{key}-n2 {
      margin-left: -0.5rem !important;
    }

    .m-@{key}-n3 {
      margin: -1rem !important;
    }

    .mt-@{key}-n3,
    .my-@{key}-n3 {
      margin-top: -1rem !important;
    }

    .mr-@{key}-n3,
    .mx-@{key}-n3 {
      margin-right: -1rem !important;
    }

    .mb-@{key}-n3,
    .my-@{key}-n3 {
      margin-bottom: -1rem !important;
    }

    .ml-@{key}-n3,
    .mx-@{key}-n3 {
      margin-left: -1rem !important;
    }

    .m-@{key}-n4 {
      margin: -1.5rem !important;
    }

    .mt-@{key}-n4,
    .my-@{key}-n4 {
      margin-top: -1.5rem !important;
    }

    .mr-@{key}-n4,
    .mx-@{key}-n4 {
      margin-right: -1.5rem !important;
    }

    .mb-@{key}-n4,
    .my-@{key}-n4 {
      margin-bottom: -1.5rem !important;
    }

    .ml-@{key}-n4,
    .mx-@{key}-n4 {
      margin-left: -1.5rem !important;
    }

    .m-@{key}-n5 {
      margin: -3rem !important;
    }

    .mt-@{key}-n5,
    .my-@{key}-n5 {
      margin-top: -3rem !important;
    }

    .mr-@{key}-n5,
    .mx-@{key}-n5 {
      margin-right: -3rem !important;
    }

    .mb-@{key}-n5,
    .my-@{key}-n5 {
      margin-bottom: -3rem !important;
    }

    .ml-@{key}-n5,
    .mx-@{key}-n5 {
      margin-left: -3rem !important;
    }

    .m-@{key}-auto {
      margin: auto !important;
    }

    .mt-@{key}-auto,
    .my-@{key}-auto {
      margin-top: auto !important;
    }

    .mr-@{key}-auto,
    .mx-@{key}-auto {
      margin-right: auto !important;
    }

    .mb-@{key}-auto,
    .my-@{key}-auto {
      margin-bottom: auto !important;
    }

    .ml-@{key}-auto,
    .mx-@{key}-auto {
      margin-left: auto !important;
    }
  }
});
