@media screen and (max-width: @breakpoints[xl]) {
  .invoices-page {
    &__invoices-list {
      min-width: 32rem;
      flex-basis: 32rem;
    }

    &__one-invoice {
      flex: 1 0 calc(100% - 32rem);
    }
  }
}

@media screen and (max-width: @breakpoints[lg]) {
  .invoices-page {
    &__invoices-list {
      min-width: 29rem;
      flex-basis: 29rem;
    }

    &__one-invoice {
      flex: 1 0 calc(100% - 29rem);
    }
  }
}
