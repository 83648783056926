.c-settings-persons {
  position: relative;

  &__table-wrap {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__scroll-block {
    //height: 20rem;
    flex-grow: 1;
    //overflow-y: scroll;
    //overflow-x: hidden;
  }

  &__table {
    margin: 0;

    display: block;

    thead,
    tbody {
      display: block;
    }

    & td._checkbox {
      width: 4.8rem;
    }

    & td._actions {
      width: 5.4rem;
    }

    & tr {
      display: flex;
    }

    & th,
    & td {
      flex-shrink: 0;
      flex-grow: 1;
    }

    & tbody tr {
      transition: background-color @time;
    }

    & th:nth-child(1),
    & td:nth-child(1) {
      width: 18rem;
    }

    & th:nth-child(2),
    & td:nth-child(2) {
      width: 14rem;
    }

    & th:nth-child(3),
    & td:nth-child(3) {
      width: 16rem;
    }

    & th:nth-child(4),
    & td:nth-child(4) {
      width: 16rem;
    }

    & th:nth-child(5),
    & td:nth-child(5) {
      width: 14rem;
    }

    & th:last-child,
    & td:last-child {
      width: 8.3rem;
      flex-grow: 0;
    }
  }

  &__scroll-block {
    & th:last-child,
    & td:last-child {
      width: 8.3rem;
    }
  }
}
