.commodity-delivery-rates-edit {
  @media (max-width: @breakpoints[md]) {
    .info-block__row {
      &.first-row {
        margin-bottom: -3rem;
        .col-4 {
          padding: 0 1rem 3rem 1rem;
        }
      }
      &.second-row,
      &.third-row,
      &.fourth-row {
        margin-bottom: -3rem;
        .col-3,
        .col-4,
        .col-6 {
          padding: 0 1rem 3rem 1rem;
        }
      }
    }
  }
}
