.select-customer {
  display: flex;

  &__select {
    width: 50%;
    flex: 1 1 auto;

    &:not(:last-child) .select__container {
      border-right-width: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:after {
        content: '';
        position: absolute;
        top: 3.3rem;
        bottom: 0;
        right: 0;
        border-right: 1px solid;
        border-color: inherit;
      }
    }
  }

  &__select._open ~ &__btn,
  &__input:hover ~ &__btn,
  &__select:not(._disabled):not(._error):hover ~ &__btn,
  &._add:hover &__input .input__input {
    border-color: #cdcccc;
    cursor: pointer;
  }

  &__select .select__option._disabled {
  }

  //
  //&__select .select__option._disabled,
  //&__select .select__option._disabled:hover,
  //&__select .select__option._disabled:focus,
  //&__select .select__option._disabled:hover *,
  //&__select .select__option._disabled:focus * {
  //  background: @c-grey-lightest !important;
  //  cursor: default;
  //
  //  color: inherit !important;
  //}
  //
  //.select__option:focus,
  //.select__option:focus *,
  //.select__option:hover,
  //.select__option:hover * {
  //
  //}

  &__input,
  &__input .input__input {
    width: 50%;
    flex: 1 1 auto;

    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__input .input__input {
    width: 100%;
  }

  &__btn {
    flex-shrink: 0;
    padding: 0.2rem;
    width: 2.9rem;
    height: 3.4rem;
    //min-height: 3.4rem;
    border-radius: 0 @radius @radius 0;
    background: white !important;
    border: 1px solid @c-grey-lighter;
    border-left-width: 0;
    overflow: visible;

    & svg {
      fill: @c-blue;
      width: 1.8rem;
      height: 1.8rem;

      transition: transform @time;
    }

    &._rotate svg {
      transition: transform @time-modal * 1.5;
    }

    &:hover svg {
      transform: scale(1.1);
    }

    &._rotate:hover svg {
      transform: rotate(180deg);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -1px;
      width: 1px;
      min-width: 1px;
      height: 1.8rem;
      transform: translateY(-50%);
      background: linear-gradient(
        to bottom,
        fade(@c-grey-darkest, 0),
        fade(@c-grey-darkest, 35%),
        fade(@c-grey-darkest, 0)
      );
    }
  }

  &__select._error ~ &__btn {
    border-color: @c-red-light;
  }

  &__select._disabled ~ &__btn {
    opacity: 0.6;
    cursor: not-allowed;
    //pointer-events: none;
  }

  &._add &__btn:before {
    display: none;
  }

  &__option {
    display: flex;
    align-items: center;
  }

  &__option-img-wrap {
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: @c-grey-lighter;
    margin-top: -0.5rem;
    margin-bottom: -0.5em;
    margin-right: 1rem;
    flex-shrink: 0;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__option-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  &__photo-placeholder {
    opacity: 0.5;
    font-size: 1.4rem;
    font-weight: @w-bold;
    text-transform: uppercase;
    color: @c-grey-darker;
  }

  &__option &__option-img-wrap &__photo-placeholder {
    color: @c-grey-darker!important;
  }

  &__option-main {
    width: 50%;
    flex-grow: 1;
    min-width: 0;
  }

  &__option-name {
    display: flex;
    align-items: center;
    &:last-child {
      margin-bottom: 0 !important;
    }

    div {
      min-width: 0;
    }
  }

  &__status {
    flex-shrink: 0;
    padding-left: 1rem;
    margin-left: auto;
    display: flex;
    align-items: center;

    font-size: 1.2rem;
    color: @c-yellow;

    &::before {
      content: '';
      flex-shrink: 0;
      border-radius: 50%;
      margin-right: 0.3rem;
      width: 0.6rem;
      height: 0.6rem;
      background-color: @c-grey-lightest;
      border: 1px solid @c-grey-lighter;
    }

    &._inactive::before {
      background-color: @c-yellow;
      border-color: @c-yellow;
    }
  }
}
