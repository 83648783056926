.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .no-selection();

  font-size: @f-size;

  & input {
    display: none;
  }

  &__mark {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.8rem;
    height: 1.8rem;
    border: 1px solid @c-grey-light;
    border-radius: 50%;
    flex-shrink: 0;
    color: @c-green;

    transition: @time;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: white;
      opacity: 0;

      transition: opacity @time;
    }

    &:hover {
      border-color: currentColor;
    }
  }

  & input:checked ~ &__mark {
    background: currentColor;
    border-color: currentColor;
  }

  & input:checked ~ &__mark::before {
    opacity: 1;
  }

  &__text {
    margin-left: 0.8rem;

    font-size: inherit;
  }

  &._disabled {
    cursor: not-allowed;
  }

  &._disabled &__text {
    color: @c-grey;
  }
}
