.upload-files-light {
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -1rem 0 0 -1rem;
  }

  &__item,
  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: @radius;
    border: 1px solid @c-grey-lighter;
    margin: 1rem 0 0 1rem;
    padding: 0.4rem 1.4rem;
    min-height: 3rem;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__item {
    padding-right: 1.1rem;
  }

  & input {
    display: none;
  }

  &__btn {
    cursor: pointer;

    text-align: center;
    line-height: @f-lineheight-small;
    color: @c-grey;

    transition: @time;

    &:hover {
      border-color: @c-blue;

      color: @c-blue;
    }

    & svg {
      position: relative;
      width: 1rem;
      height: 1.6rem;
      margin-right: 0.8rem;
      fill: @c-grey;
    }

    &:hover svg {
      fill: @c-blue;
    }
  }

  &__delete {
    width: 2rem;
    height: 2rem;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    padding: 0;
    background: @c-grey-light;
    cursor: pointer;
    margin-left: 0.8rem;

    transition: @time;

    & svg {
      fill: white;
    }

    &:hover {
      opacity: 1;
      border-color: @c-red-light;
      background-color: @c-red-light;
    }
  }
}
