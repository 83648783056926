.page-header {
  @media (max-width: @breakpoints[xl]) {
    // padding-right: @page-sides-padding-lg;
    padding-right: 3rem;
  }

  @media (max-width: @breakpoints[sm]) {
    padding-left: @page-sides-padding-sm;
    padding-right: @page-sides-padding-sm;
    flex-wrap: wrap;
    box-shadow: none;

    &__title:not(:nth-last-child(2)):not(:last-child) {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }

    .dropdown {
      max-width: 80%;
    }

    &__title {
      font-size: 2.4rem;
    }

    &__title-col {
      display: block;
      width: 100%;

      .page-header__title {
        margin-right: 0;
      }
    }

    &__search {
      margin-top: 1rem;
      width: 100%;
      background-color: white;
    }
  }
}
