.customer-settings {
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__page-btns {
    display: flex;
    flex-wrap: wrap;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    margin-right: 2rem;
  }

  &__page-btn-group {
    margin: 1.5rem 0 0 1.5rem;
    display: flex;
  }

  &__page-btn {
    background: transparent;
    border: 1px solid #e0e0e0;
    min-height: 3rem;
    color: #333333;
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: none;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    &:hover {
      background: transparent;
      border-color: @c-green;

      color: @c-green;
    }

    &._active {
      background-color: @c-green;
      border-color: @c-green;
      color: white;
    }

    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0;
    }

    &._add {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      padding: 0;
      width: 3rem;
    }

    &._add:hover {
      background-color: @c-green-light;
      border-color: @c-green-light;

      color: white;
    }

    &:not(._active):hover ~ & {
      border-left-color: @c-green-light;
    }
  }

  &__search {
    margin-left: auto;
  }

  &__table {
    width: 100%;
    margin: 0;
  }

  &__content {
    height: 20rem;
    flex-grow: 1;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
  }
}
