.sending-logs {
  &__toggle-btn {
    display: inline-block;
    cursor: pointer;
    padding-bottom: 0.2rem;
    margin: 0;

    font-size: 1.4rem;
    color: @c-grey-dark;

    border-bottom: 1px dashed @c-grey;

    .no-selection();

    transition: margin-bottom @time-modal;

    & svg {
      width: 0.8rem;
      height: 1rem;
      fill: @c-grey-darker;
      margin-left: 0.4rem;
      transform: rotate(90deg);

      transition: @time;
    }
  }

  &._open &__toggle-btn {
    margin-bottom: 2rem;

    & svg {
      transform: rotate(270deg);
    }
  }

  &__table {
    width: 100%;
    margin: 0;
  }
}
