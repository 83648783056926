.commodity-select {
  &__search {
    width: 100%;
    display: flex;
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
  }

  &__search-input-wrap {
    display: flex;
    flex-grow: 1;
  }

  &__search-ico {
    width: 1.8rem;
    height: 1.8rem;
    align-self: center;
    flex-shrink: 0;
    margin-left: 1rem;
    fill: @c-grey-lighter;
  }

  &__search-input {
    border: 0;
    border-radius: 0;
    flex-grow: 1;
  }

  &__search-clear {
    position: relative;
    background: transparent !important;
    border-radius: 0;
    flex-shrink: 0;
    padding: 0;
    border: 0 !important;
    width: 2.9rem;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: @c-grey-light;
    }

    &:hover svg {
      fill: @c-red;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -1px;
      width: 1px;
      height: 1.8rem;
      transform: translateY(-50%);
      background: linear-gradient(
        to bottom,
        fade(#ffffff, 0),
        fade(#9d9d9d, 80%),
        fade(#ffffff, 0)
      );
    }
  }

  // - - - -

  &__head {
    display: flex;
    padding: 0 0 2rem;
  }

  &__head-separator {
    margin: 0 auto;
  }

  &__head-btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    &._disabled {
      opacity: 0.4;
      cursor: not-allowed;
      //pointer-events: none;
    }
  }

  &__table-wrap {
    position: relative;
  }

  &__table {
    width: 100%;
    margin: 0;

    & tbody tr {
      cursor: pointer;
    }
  }

  // - - - -

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }
}
