.access-config {
  padding: 2rem 0;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__disable-primary {
    color: @c-grey;

    & svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.6rem;
      fill: @c-grey;

      transition: @time;
    }

    &:hover {
      color: @c-red-light;
    }

    &:hover svg {
      fill: @c-red-light;
    }
  }

  &__primary-admin-title {
    margin: 0;
    display: flex;
    align-items: center;

    color: @c-green;

    & svg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.8rem;
      fill: @c-green;
    }
  }

  &__table {
    width: 100%;
    margin: 0;

    td:first-child {
      padding-left: 2.4rem;
      //width: 50%;
      //text-align: right;
    }

    td:last-child {
      padding-right: 2.4rem;
    }

    &._sticky {
      position: sticky;
      top: 5rem;
      left: 0;
      z-index: 2;
      background: white;
      box-shadow: 0 1px 0 0 @c-grey-lighter;
    }

    &:not(._list) td {
      border-bottom: 0;
    }
  }

  .radio {
    font: inherit;
  }

  &__item {
    color: @c-primary-text;

    &._no-access {
      color: fade(@c-primary-text, 60);
    }
  }

  &__name {
    word-break: break-all;
  }

  &__toggle {
    width: 100%;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      transform: rotate(90deg);
      transition: 0.15s;
    }

    &._active {
      svg {
        transform: rotate(270deg);
      }
    }
  }
}
