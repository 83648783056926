.employees-list {
  &__header {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 5;
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__add-btn {
    margin-left: auto;

    font-size: 2rem;
    line-height: 1em;
  }

  &__table-wrap {
  }

  &__table {
    width: 100%;
    margin: 0;

    thead {
      position: sticky;
      top: 5.4rem;
      z-index: 4;
    }

    & th:last-child {
      width: 4.4rem;
    }

    & tbody tr {
      cursor: pointer;
    }

    & tbody tr:hover {
      background-color: @c-grey-lightest;
    }

    & td._checkbox {
      width: 4.8rem;
    }

    & td._profile {
      width: 23%;
    }

    & td._actions {
      width: 5.4rem;
    }

    td:first-child {
      width: 4.5rem;
    }

    td:last-child {
      width: 5.5rem;
    }
  }

  &__profile {
    display: flex;
    align-items: center;

    font-size: 1.2rem;
  }

  &__photo-wrap {
    background: @c-grey-lighter;
    border-radius: 50%;
    overflow: hidden;
    width: 4.5rem;
    min-width: 4.5rem;
    height: 4.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 1.5rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__photo-placeholder {
    opacity: 0.5;

    font-size: 1.8rem;
    font-weight: @w-bold;
    text-transform: uppercase;
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }

  // - - - -

  &._short &__header {
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__short-item {
    display: flex;
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    //background: @c-grey-lightest;

    cursor: pointer;

    &._selected {
      background-color: @c-blue-2;
    }
  }

  &__short-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 25rem;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid #e0e0e0;
    overflow-y: auto;
  }

  &-mob {
    padding: 0 18px;
    &__item {
      position: relative;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 12px 20px 12px 8px;
      margin-top: 10px;
      &-row {
        font-size: 1.2rem;
        margin-bottom: 0.3rem;
      }
    }
  }
}
