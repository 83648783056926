.commodity {
  position: relative;
  //min-height: 40rem;

  &__head {
    padding: 3rem 2rem 0;
  }

  &__title {
    //margin: 0 2rem 0 0;
    margin: 0;
    font-size: 2.4rem;
  }

  &__main-wrap {
    display: flex;
    padding: 0 2rem 3rem;
  }

  &__gallery {
    width: 40rem;
    margin-right: 2rem;
    flex-shrink: 0;
  }

  &__main {
    width: 40%;
    flex-grow: 1;
  }

  &__rows-group {
    border: 1px solid @c-grey-lighter;
    padding: 0.5rem;
    margin-bottom: 2rem;
    background: @c-grey-lightest;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__rows-group-title {
    display: block;
    margin: -0.5rem -0.5rem 1rem;
    padding: 1.3rem 2.5rem;
    background: @c-grey-lighter;

    font-size: 1.2rem;
    text-transform: uppercase;
  }

  &__row {
    display: flex;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__row-item {
    width: 20%;
    flex-grow: 1;
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
