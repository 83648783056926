.help-page {
  &__content {
    padding: 2rem;
  }

  &__search {
    width: 50rem;
    max-width: 100%;
    margin-bottom: 3rem;
  }

  &__list {
  }

  &__item {
    margin-bottom: 3rem;
  }

  &__title {
    margin-bottom: 1rem;

    font-size: 1.4rem;
    color: @c-blue;

    & a {
      color: inherit;
    }
  }

  &__text {
  }
}
