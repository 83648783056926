.additional-name {
  &__label {
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    color: #808080;
    text-transform: uppercase;
    margin-bottom: 1rem;
    display: inline-block;
    transition: 0.15s;
  }

  &__icon {
    background-color: #f2f2f5;
    min-width: 2.4rem;
    margin-right: 1rem;

    &:hover {
      background-color: #aa0300 !important;
      border-color: #aa0300 !important;
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__input {
    width: 100%;
  }
}
