.settings-page {
  &__content-head {
    padding: 2rem;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__main-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;

    flex-grow: 1;
  }

  &__nav,
  &__main {
    //min-height: 40rem;
  }

  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 40rem;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid @c-grey-lighter;
    display: flex;
    flex-direction: column;
  }

  & .inner-nav__list {
    height: 10rem;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__main {
    width: calc(100% - 40rem);
    margin-left: auto;
    height: 10rem;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &__mob-header {
    padding: 1rem @page-sides-padding-lg 1rem;
    background-color: white;
    z-index: 10;
    position: sticky;
    top: 5rem;

    @media (max-width: @breakpoints[sm]) {
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
    }
  }

  &__nav-select {
  }

  @media (max-width: @breakpoints[xl]) {
    &__nav {
      width: 30rem;
    }

    &__main {
      width: calc(100% - 30rem);
    }
  }

  @media (max-width: @breakpoints[ml]) {
    &__main {
      width: 100%;
      height: auto;
    }
  }
}
