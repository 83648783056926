.payment-received-page {
  &__additional-information {
    margin: 5rem auto 0;
    zoom: var(--blank-preview-zoom, 1);
  width: @blank-width;
  }

  &__additional-information-title {
    margin: 0 0 1.5rem;
  }

  &__additional-information-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;

    & dt,
    & dd {
      margin: 0 0 0.8rem;

      &:last-child,
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    & dt {
      width: 15rem;
      color: @c-grey;
    }

    & dd {
      width: calc(100% - 16rem);
    }
  }

  &__files-list {
    display: flex;
    flex-wrap: wrap;
    margin: -20px 0 0 -20px;
  }

  &__file-wrap {
    width: 120px;
    margin: 20px 0 0 20px;
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    cursor: zoom-in;
  }

  &__file {
    width: 100%;
  }

  &__files-empty {
    padding: 1.1rem 2rem;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }
}
