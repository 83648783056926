.customer-emails {
  position: relative;
  background: white;
  width: 100%;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem 2rem 2rem;
  }

  &__filter-mail-type {
    width: 16rem;
  }

  &__scroll-block {
    height: 20rem;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  }

  &__table {
    width: 100%;
    margin: 0;
    display: flex;

    & thead,
    & tbody {
      width: 100%;
      display: block;
    }

    & tr {
      width: 100%;
      display: flex;
      cursor: pointer;

      transition: @time;

      &:hover {
        background-color: @c-grey-lightest;
      }

      &:hover .hide-particle__placeholder::before {
        opacity: 0;
      }

      &:hover .hide-particle__placeholder::after {
        opacity: 1;
      }
    }

    & th,
    & td {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
    }

    & th:first-child,
    & td:first-child {
      width: 18rem;
    }

    & th:nth-child(2),
    & td:nth-child(2) {
      width: 20%;
      flex-grow: 1;
    }

    //& th:nth-child(3),
    //& td:nth-child(3) {
    //  width: 14rem;
    //}
    //
    //& th:nth-child(4),
    //& td:nth-child(4) {
    //  width: 18rem;
    //}
    //
    & th:last-child,
    & td:last-child {
      width: 13rem;
      text-align: right;
    }

    & tr:last-child td {
      border-bottom: 0;
    }
  }

  & a {
    text-decoration: none;
  }

  &__hide-particle-wrap {
    display: flex;
  }

  &__hide-particle {
    width: 5rem;
    flex-grow: 1;
  }

  &__email-list {
    max-width: 100%;
    .no-wrap();
  }

  &__files {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
  }

  &__file {
    display: inline-flex;
    align-items: center;
    padding: 0.4rem 1rem;
    border-radius: 1.5rem;
    border: 1px solid @c-grey-lighter;
    background: @c-grey-lightest;
    max-width: 12rem;
    margin: 0.5rem 0 0 0.5rem;
    .no-wrap();

    & svg {
      width: 1.2rem;
      height: 1.4rem;
      margin-right: 0.6rem;
      fill: @c-grey-light;
    }
  }
}
