.g-page-wrap {
  padding: 0 @page-sides-padding;
  width: 100%;
}

.g-content-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: @max-width;
}
