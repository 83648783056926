.booking-list {
  &__header {
    position: sticky;
    z-index: 32;
    top: @header-height;
    background: white;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &._short &__header {
    top: 0;
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__only-booking {
    margin-left: auto;
  }

  &__table-wrap {
    //height: 30rem;
    //flex-grow: 1;
    //display: flex;
    //flex-direction: column;
  }

  &__table {
    width: 100%;
    margin: 0;

    & th:last-child {
      width: 4.4rem;
    }

    & th:nth-child(6),
    & td:nth-child(6),
    & th:nth-child(7),
    & td:nth-child(7) {
      width: 8.5rem;
    }

    & tbody tr {
      cursor: pointer;
    }

    & tbody tr:hover {
      background-color: @c-grey-lightest;
    }
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }

  // - - - -

  &._short &__header {
    justify-content: flex-start;
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__short-item {
    display: flex;
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    //background: @c-grey-lightest;

    cursor: pointer;

    &._selected {
      background-color: @c-blue-2;
    }
  }

  &__short-checkbox {
    flex-shrink: 0;
    margin-right: 2rem;
  }

  &__short-item-content {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 50%;
  }

  &__short-el {
    font-size: 1.2rem;

    &::after {
      content: '|';
      margin: 0 0.4rem;

      color: @c-grey-light;
    }

    &._path {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0.6rem 0;
    }

    &._path > div {
      display: block;
      width: 100%;
      color: black;
      //font-weight: @w-semi-bold;

      strong {
        font-weight: @w-regular;
        color: @c-grey;
      }
    }

    &._path::after,
    &._last::after,
    &:last-child::after,
    &._path > div:last-child::after {
      display: none;
    }

    abbr {
      color: @c-grey;
      font-weight: @w-semi-bold;
    }

    &._full {
      width: 100%;
      padding: 0.6rem 0 0;
    }

    &._last {
      strong {
        font-weight: @w-regular;
        color: @c-grey;
      }
    }
  }
}
