.tasks-list {
  position: relative;

  &__table {
    margin: 0;
    width: 100%;

    col {
      &._date {
      }

      &._vendor {
      }

      &._amount {
      }

      &._balance {
      }

      &._description {
      }

      &._notes {
      }

      &._actions {
        width: 1px;
      }
    }

    td,
    th {
      &:last-child {
        text-align: right;
      }
    }
  }

  &__item {
    cursor: pointer;
    transition: background-color @time;

    &:hover {
      background-color: @c-grey-lightest;
    }
  }
}
