.commodity-delivery-info {
  &__spec-row {
    display: flex;
    justify-content: space-between;
  }

  &__spec-col {
    flex-basis: 47%;
    max-width: none;
  }
}
