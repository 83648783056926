.search-table-col {
  display: flex;
  align-items: center;
  max-width: 100%;

  &__btn {
    background: transparent;
    border-radius: 0;
    padding: 0 0.2rem 0 0;
    min-height: 0;
    border: 0;
    width: 2.4rem;
    height: 2.8rem;
    overflow: visible;
    flex-shrink: 0;
    justify-content: flex-start;

    transition: @time;

    &:hover {
      background-color: transparent;
    }

    & svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: @c-grey;
    }

    &:hover svg {
      fill: @c-green;
    }
  }

  &__search {
    background-color: white;
    border-color: white;
    max-width: calc(100% + 1.8rem);
    margin: auto -0.9rem;

    & .search__ico {
      display: none;
    }
  }

  &__content {
    max-width: 100%;
  }

  & .search__input {
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
    padding-right: 0;
    padding-left: 0.5rem;
  }

  & .search__ico {
    margin-left: 0.7rem;
    width: 1.6rem;
    height: 1.6rem;
  }

  & .search__btn {
    width: 1.8rem;

    &::before {
      display: none;
    }

    & svg {
      width: 0.8rem !important;
      height: 0.8rem !important;
    }
  }
}
