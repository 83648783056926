.date-show {
  display: inline-flex;
  width: auto;

  & .mx-input-wrapper {
    display: inline;
  }

  & .mx-icon-calendar {
    display: none;
  }
}
