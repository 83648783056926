.section {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: @c-blue;
    min-height: 4rem;
    padding: 0.6rem 2rem;

    color: white;
  }

  &._wrap > &__header {
    background: @c-grey-lighter;
    padding-left: 2.6rem;
    padding-right: 2.6rem;

    color: inherit;
  }

  &__header-title {
    padding-top: 0.2rem;
    margin: 0 2rem 0 0;
    flex-grow: 1;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: uppercase;
  }

  &__content {
    border: 1px solid @c-grey-lighter;
    border-top: 0;
    padding: 1.5rem;
  }

  &._wrap > &__content {
    padding: 1rem 0.5rem 0.5rem;
  }
}
