.commodity-confirm-delete {
  &__select {
    margin-bottom: 2rem;
  }

  &__input {
    margin-bottom: 2rem;
  }

  &__cancel-btn {
    margin-right: 1.5rem;
  }

  &__error {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
