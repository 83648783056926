.chat {
  height: 60rem;
  max-height: calc(90vh - 6rem);
  display: flex;
  flex-direction: column;

  &__list {
    overflow-y: auto;
    height: 10rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
  }

  &__no-messages {
    margin: auto 0;

    text-align: center;
    color: @c-grey;
  }

  &__message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    max-width: calc(100% - 4rem);

    &:first-child {
      margin-top: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &._my {
      align-self: flex-end;
      max-width: calc(100% - 8rem);
    }
  }

  &__message-person-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background: @c-grey-lightest no-repeat center;
    background-size: cover;
    margin-right: 0.8rem;
    flex-shrink: 0;

    font-size: 1.4rem;
    font-weight: @w-bold;
    color: @c-grey;
  }

  &__message-main {
  }

  &__message-head {
    margin-bottom: 0.2rem;

    font-size: 1.2rem;
    color: @c-grey;

    & b {
      font-weight: @w-semi-bold;
    }
  }

  &__message._my &__message-head {
    text-align: right;
  }

  &__message-text {
    background-color: @c-grey-lightest;
    padding: 0.4rem 1rem;
    border-radius: @radius;
  }

  &__message._my &__message-text {
    background-color: @c-blue-lightest;
  }

  // - - - -

  &__input-wrap {
    position: relative;
    border-top: 1px solid @c-grey-lighter;
    padding: 0 1.5rem;
  }

  &__input {
    & p {
      margin: 0;
    }

    & .editor-for-chat__content > .ProseMirror {
      padding: 1.3rem 0;
    }
  }

  &__btn {
    .reset-button();
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    bottom: 0;
    transform: translateY(-50%);
    width: 2.9rem;
    height: 3.2rem;
    border-radius: @radius;
    background: @c-blue;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: @time;

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: white;
    }

    &:hover {
      background-color: @c-blue-light;
    }
  }
}
