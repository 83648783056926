.commodity-customer {
  &__content {
    position: relative;
    //background: @c-grey-lightest;
    border: 1px dashed @c-grey-light;
    padding: 2rem 2rem 3rem;
    border-radius: @radius;
  }

  &._inactive &__content {
    border-color: @c-yellow;
  }

  &._deleted &__content {
    border-color: @c-red;
  }

  &__main-info-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;

    &:first-child {
      padding-top: 1rem;
    }
  }

  &__main-info-row {
    display: flex;

    &._wrap {
      flex-wrap: wrap;
    }
  }

  &__main-info-row {
    display: flex;
  }

  &__customer-photo-outer {
    position: relative;
    margin-right: 2rem;
  }

  &__customer-photo-wrap {
    background: @c-grey-light-2;
    border-radius: 50%;
    overflow: hidden;
    width: 8.5rem;
    height: 8.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__customer-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: zoom-in;

    &-placeholder {
      opacity: 0.5;

      font-size: 3rem;
      font-weight: @w-bold;
      text-transform: uppercase;
    }
  }

  &__customer-edit-btn.btn {
    //border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__main-info {
    width: 18rem;
    margin-right: 2rem;
  }

  &__customer-photo-outer,
  &__main-info {
    margin-bottom: 3rem;
  }

  &__status {
    margin-bottom: 1rem;

    font-size: 1.2rem;
    color: @c-green;

    &._inactive {
      color: @c-yellow;
    }

    &._deleted {
      color: @c-red;
    }

    &::before {
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      display: inline-block;
      border-radius: 50%;
      background: #14a75d;
      vertical-align: 1px;
      margin-right: 0.6rem;
    }

    &._inactive::before {
      background-color: @c-yellow;
    }

    &._deleted::before {
      background-color: @c-red;
    }
  }

  &__contacts-wrap-row {
    display: flex;
    flex-wrap: wrap;
    width: 15%;
    flex: 1 1 auto;
  }

  &__contacts-group {
    width: max-content;
    min-width: max-content;
    margin-right: 2rem;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__options-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
  }

  &__toggle.btn {
    position: absolute;
    left: 0;
    bottom: -1.1rem;
    background: white;
    border: 1px dashed @c-grey-light;

    color: @c-blue;

    & svg {
      transform: rotate(90deg);
      fill: @c-blue;
    }

    &._open svg {
      transform: rotate(270deg);
    }
  }

  &__full-info-wrap {
  }

  &__full-info {
    height: auto;
    overflow: hidden;
    padding: 3rem 0 0;

    & .person-info__section:first-child {
      border-top: 1px solid #e0e0e0;
    }

    & .person-info__section:last-child .person-info__contact-wrap {
      margin-bottom: 0;
    }
  }
}
