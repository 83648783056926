.shipping-file-reports {
  padding: 2rem;

  &__btn {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
