.document-creations-edit {
  position: relative;

  &__name {
    margin-bottom: 2rem;
  }

  &__error {
    margin-top: 3rem;
  }

  &__footer {
    display: flex;
    margin-top: 3rem;
  }

  &__separator {
    margin: 0 auto;
    //padding: 1rem;
  }

  &__btn {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__document-wrap {
    min-width: 0;
    overflow: auto;
    padding: 2rem 2.4rem;

    margin: -2rem -2.4rem;

    @media (max-width: @breakpoints[sm]) {
      padding: 2rem @page-sides-padding-xs;

      margin: -2rem -@page-sides-padding-xs;
    }
  }
}
