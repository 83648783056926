.commodity-sort-files {
  position: relative;

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem;
  }

  &__item {
    width: (100% / 5);
    padding: 0.5rem;
  }

  &__item-inner {
    border: 1px solid @c-grey-lighter;
    background-color: @c-grey-lightest;
    border-radius: @radius;
  }

  &__error {
    margin-top: 3rem;
  }

  &__footer {
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
