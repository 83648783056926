.root-page {
  flex-grow: 1;
  //padding-top: @header-height;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: padding-left @time-modal;

  &__main {
    flex-grow: 1;
    width: calc(100% - @side-nav-width);
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  &._less &__main {
    width: calc(100% - 5rem);
  }

  &._full-width &__main {
    width: 100%;
  }

  &__main-header {
    position: sticky;
    z-index: 83;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.15);
  }

  &__side-nav {
  }

  &__content-wrap {
    flex-grow: 1;
    // padding-right: @page-sides-padding;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__content {
    background: white;
    flex-grow: 1;
  }
}
