.commodity-list-table-pending-delivery {
  & th:nth-child(4),
  & td:nth-child(4) {
    width: 14rem;
  }

  & th:nth-child(5),
  & td:nth-child(5) {
    width: 10rem;
  }

  & th:nth-child(6),
  & td:nth-child(6) {
    width: 10rem;
  }
}
