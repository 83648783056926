.contacts-creator {
  background: white;

  &__item {
    position: relative;
    padding: 0 2.4rem;
    margin: 0 0 2.4rem;

    &:not(:first-child) {
      margin-top: 3rem;
    }

    &._contains {
      border: 1px dashed @c-grey-lighter;
      border-radius: @radius;
      margin: 3.5rem 1rem 3rem;
      padding: 0 1.2rem;
    }

    &._contains:first-child {
      margin-top: 0;
    }
  }

  &__item-add-wrap {
    position: relative;
    margin-bottom: -1rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -2.4rem;
      width: calc(100% + 4.8rem);
      border-bottom: 1px dashed @c-grey-light;
    }
  }

  &__item:first-child &__item-main:first-child {
    margin-top: 1.4rem;
  }

  &__item._contains &__item-add-wrap::before {
    display: none;
  }

  &__item-add-btn {
    position: relative;
    z-index: 2;
    background: white;
    padding: 0 0.6rem;
    margin-left: -0.6rem;
    border: 0;
    box-shadow: none;
    cursor: pointer;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-semi-bold;
    color: @c-grey;
    text-transform: uppercase;

    transition: @time;

    &:hover {
      color: @c-blue;
    }
  }

  &__item-main {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 1.4rem 0;

    &:not(:first-child) {
      margin-top: 2.4rem;
    }
    &:first-child {
      margin-top: 4rem;
    }

    &._error {
      margin-top: -1rem;
    }

    &:not(:first-child):not(&._error) {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        border-top: 1px dotted @c-grey-light-2;
        top: -1.2rem;
      }
    }
  }

  &__item-main ~ &__item-add-wrap {
    //margin-top: 2rem;
  }

  &__item-select {
    width: 11.2rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  &__item-input-wrap {
    position: relative;
    flex-grow: 1;
  }

  &__item-input-title {
    margin: 0.5rem 0 1.8rem;
    font-size: 1.2rem;
  }

  &__item._address &__item-input-wrap {
    padding-left: 1rem;
    border-left: 1px solid @c-grey-lighter;
  }

  &__item-input {
    width: 100%;

    & .input__input {
      padding-right: 3.2rem;
    }
  }

  &__ex-date {
    width: 15.6rem;
    margin-left: 1rem;

    & .date-time-picker .flex {
      justify-content: flex-end;
    }
  }

  &__item-additional {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    flex-shrink: 0;
    width: 15rem;
    min-height: 3.4rem;
  }

  &__item-delete-btn.btn {
    //position: absolute;
    //top: 0.5rem;
    //right: 0.5rem;
    //padding: 0;
    //min-width: 2rem;
    //min-height: 2rem;
    //background: white;
    //border-color: @c-grey;
    border: 0 !important;
    background: @c-grey-lightest;
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: 0.5rem;
    margin-right: 1rem;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      //fill: @c-grey
    }

    &:hover {
      background-color: @c-red-light !important;
    }

    //&._middle-pos {
    //  top: 3.2rem;
    //  //right: -1.1rem;
    //}
  }

  &__birthday {
    font-size: 1.2rem;
    line-height: 1.2em;
  }

  &__item-check-props {
    display: flex;
    align-items: center;
    margin: 1px 1rem 0 -0.5rem;
  }

  &__item-check-prop {
    position: relative;
    margin: 0.5rem 0 0 0.5rem;
    cursor: pointer;
    .no-selection();

    & input {
      display: none;
    }
  }

  &__item-check-prop-ico {
    display: flex;

    & svg {
      width: 2.4rem;
      height: 2.4rem;
      fill: @c-grey-light;

      transition: fill @time;
    }
  }

  &__item-check-prop-check {
    position: absolute;
    z-index: 5;
    top: -0.4rem;
    right: -0.4rem;
    background: @c-green;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    opacity: 0;

    transition: opacity @time;

    & svg {
      width: 0.7rem;
      height: 0.7rem;
      fill: white;
    }
  }

  &__item-check-prop._checked &__item-check-prop-check {
    opacity: 1;
  }

  &__sub-accounts {
    margin-top: 0;
    width: 100%;

    td,
    th {
      &:first-child {
        width: 2.4rem;
        padding-right: 0;
      }
    }
  }
}
