@import '~@/assets/css-blanks/blank-base.less';
@size: 12px;
@line-height: @f-lineheight-small;

//html {
//  font-size: 12px;
//}
//
//body {
//  background: white;
//
//  font-size: 12px;
//}
//

* {
  box-sizing: border-box;
  outline: none;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.page-break-inside-avoid {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
}

table {
  width: 100%;
  margin: 0;
  table-layout: fixed;
  border-collapse: collapse;

  font: inherit;
}

th,
td {
  padding: 0;
  border: 0;
  background: transparent;

  font: inherit;
  text-align: left;
  vertical-align: top;
}

.blank-shipping-file {
  zoom: var(--blank-preview-zoom, 1);
  width: @blank-width;
  min-height: @blank-height;
  margin: 0 auto;
  background: white;
  padding: @blank-side-top @blank-side-right @blank-side-bottom @blank-side-left;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.25);

  &__content {
    //padding: 0 @blank-side-right 0 @blank-side-left;
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-before: auto;

    color: @c-grey-darkest;
    font-size: @size;
    line-height: @line-height;
    font-family: 'Open Sans', system-ui, sans-serif;
    white-space: normal;
    word-break: break-word;
  }

  & &__content {
    padding: 0;
    zoom: @blank-zoom;
  }

  &__head {
    margin-bottom: 15px;
    border-bottom: 1px dashed @c-grey-light;

    & td {
      padding-bottom: 15px;
      vertical-align: top;
    }
  }

  &__logo {
    display: block;
    width: 130px;
    height: 40px;
    //max-height: 40px;
    object-fit: contain;
  }

  &__name,
  &__sub-name {
    margin: 0 0 5px;

    font-size: 22px;
    line-height: 1em;
    text-align: right;
    text-transform: uppercase;
  }

  &__sub-name {
    margin-bottom: 10px;

    font-size: 14px;
  }

  &__number {
    margin: 0;

    text-align: right;
    font-size: 16px;
    line-height: @line-height;
    color: @c-grey;
  }

  &__company-name {
    margin: 0 0 10px;

    font-size: 14px;
    line-height: @line-height;
  }

  &__company-address {
    margin: 0;

    font-size: 12px;
    line-height: @line-height;
    color: @c-grey;
  }

  &__info {
    margin-bottom: 45px;
  }

  &__table {
    width: 100%;
    margin-bottom: 20px;

    & col {
      width: 25%;
    }

    & td {
      padding: 10px;
      border: 1px solid @c-grey-lighter;
      vertical-align: top;
      height: 60px;

      font-size: 12px;
    }
  }

  &__cell-title {
    margin: 15px 0 7px;

    font-size: @size;
    line-height: @line-height;
    color: #8a8a8a;
    font-weight: @w-regular;

    &:first-child {
      margin-top: 0;
    }
  }

  //// - - - -
}
