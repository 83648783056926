.file-page {
  @media (max-width: @breakpoints[ml]) {
    &__head {
      padding: 0 1.6rem 1.6rem;

      &:first-child {
        padding-top: 1.6rem;
      }
    }

    &__title {
      font-size: 2rem;
    }
  }
}
