@nav-width: 25rem;

.profile-page {
  &__content {
    position: relative;
    flex-grow: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    min-height: 38rem;
    background: white;
  }

  &__mob-header {
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    display: flex;
    grid-gap: 2rem;
    align-items: center;
    @media (max-width: @breakpoints[sm]) {
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
    }
  }

  &__nav-select {
    flex: 1;
  }

  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    width: @nav-width;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid @c-grey-lighter;
    overflow-y: auto;
  }

  &__main {
    width: calc(100% - @nav-width);
    margin-left: auto;
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: @breakpoints[ml]) {
      width: 100%;
      margin-left: 0;

      .person-info__header {
        //padding-top: 0;
        border-bottom: 0;
      }
    }

    &._resize {
      width: 100%;
    }
  }

  .persons-page__status {

  }

  .persons-page__back-btn {
    //font-size: 1.4rem;
  }

  //.persons-page__back-btn ~ .persons-page__status {
  //  margin-left: auto;
  //  margin-right: 0;
  //}

  @media (max-width: @breakpoints[ml]) {
    .page-header {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid @c-grey-lighter;
      min-height: 4.9rem;
    }

    .persons-page {
      //padding-top: 1rem;
    }

  }

  //.page-header{
  //  padding: 1.5rem 2rem 1.5rem 2rem;
  //  @media screen and (max-width: @breakpoints[sm]) {
  //    padding: 1.5rem 3rem 1.5rem 2rem;
  //  }
  //  @media screen and (max-width: 848px) {
  //    padding: 1.5rem 3rem 1.5rem 2rem;
  //  }
  //
  //}
}
