.over-btn {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: fade(@c-blue, 80);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: white;

  transition: opacity @time;

  & svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    fill: white;
  }
}
