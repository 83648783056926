.photo {
  &__btns-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &__camera-wrap {
    margin-bottom: 2rem;
    position: relative;
    width: 100%;
    overflow: hidden;
    outline: 0.2rem solid transparent;
    border-radius: 0.5rem;
    background-color: @c-grey-light-2;
    transition: outline-color @time-modal;

    &._active {
      outline-color: @c-green;

      video {
        opacity: 0;
      }
    }

    &:before {
      content: '';
      display: block;
      padding-top: 75%;
      width: 100%;
    }

    img,
    video {
      object-fit: contain;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    video {
      transition: opacity @time;
    }

    img {
      z-index: 2;
    }
  }

  &__photo-wrap {
    position: absolute;
  }

  &__clear {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 3;
  }

  &__done {
    display: flex;
    justify-content: flex-end;
  }
}
