.light-table {
  background: transparent;

  & th,
  & td {
    padding: 1rem 1rem;
    background: transparent;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  & th {
    border-bottom-width: 0.2rem;
  }
}
