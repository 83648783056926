.invoice-edit {
  min-height: 3rem;

  &__items-forms-wrap {
    border-top: 1px dashed @c-grey-lighter;
    padding: 3rem 2rem 2rem;
  }

  &__items-form-variant-select {
    width: 30rem;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  &__items-form {
    width: 100%;
    margin: 0;
    border: 1px solid @c-grey-lighter;

    & > table {
      width: 100%;
      margin: 0;
    }

    & td {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 0;
      vertical-align: top;
    }

    & tbody tr:first-child td {
      padding-top: 2rem;
      border-top: 1px dashed @c-grey-lighter;
    }

    & thead + tbody tr:first-child td {
      padding-top: 2.5rem;
      border-top: 0;

      &:last-child {
        padding-top: 2.9rem;
      }
    }

    & td:first-child,
    & th:first-child {
      width: 28%;
    }

    & td:last-child,
    & th:last-child {
      width: 5.4rem;
    }

    & td:last-child {
      padding-top: 2rem;
    }

    & td:nth-last-child(2),
    & th:nth-last-child(2) {
      width: 16rem;
    }

    & tfoot td {
      padding-bottom: 2.4rem;
      padding-top: 1rem;
    }

    tr._has-contract td {
      padding-bottom: 1rem;
    }
  }
  &__items-editor {
    & .ProseMirror {
      min-height: 1rem;
    }
  }
  &__result {
    margin-top: 1.2rem;
    padding: 2.4rem 2rem;
    border-top: 1px solid @c-grey-lighter;
  }

  &__result-inner {
    display: flex;
    align-items: flex-start;
    grid-gap: 1.6rem;
  }

  &__attach {
    display: flex;
    flex-direction: column;
    grid-gap: 1.6rem;
  }

  &__attach-group {
    position: relative;
    //background-color: @c-yellow;

    &-label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    &-item {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__result-table {
    margin: 0;
    width: 60rem;
    max-width: 100%;
    margin-left: auto;

    & td:first-child {
      width: 45%;
    }

    & tfoot td {
      background-color: @c-grey-lightest;
    }

    & td:nth-last-child(2) {
      width: 10rem;
    }

    & td:last-child {
      width: 5.4rem;
    }
  }

  &__main-section-footer {
    margin-top: 1.5rem;
  }

  &__taxes {
    width: 20rem;
  }

  &__alert {
    margin-top: 2rem;
  }

  .contract-item {
    td {
      padding-top: 0;
      text-align: right;
    }
  }
}
