.preview-person-with-photo {
  display: flex;
  align-items: center;

  font-size: @f-size-small;
  line-height: @f-lineheight-small;

  &__photo-wrap {
    position: relative;
    background: @c-grey-lighter;
    border-radius: 50%;
    width: 5.5rem;
    height: 5.5rem;
    margin-top: -1rem;
    margin-bottom: -1rem;
    margin-right: 1rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &._without-photo-flattening &__photo-wrap {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__photo-placeholder {
    opacity: 0.5;

    font-size: 2rem;
    font-weight: @w-bold;
    text-transform: uppercase;
  }

  &__info {
    width: 50%;
    flex-grow: 1;
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  &__status {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    background-color: @c-grey-light;
    border: 1px solid @c-grey-light;

    &._active {
      background-color: @c-green;
      border-color: @c-green;
    }

    &._inactive {
      background-color: @c-yellow;
      border-color: @c-yellow;
    }

    &._deleted {
      background-color: @c-red;
      border-color: @c-red;
    }

    &-reg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      font-size: 1rem;
      line-height: 1rem;
      font-weight: bold;
      color: @c-grey-lightest;
    }
  }
}
