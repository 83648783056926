.vendor-logs {
  @media (max-width: @breakpoints[md]) {
    &__table {
      & th:nth-child(1),
      & td:nth-child(1) {
        width: 13rem;
      }
    }
  }
  @media (max-width: @breakpoints[sm]) {
    &__scroll-block {
      height: auto;
      overflow: auto;
    }
  }
  @media (max-width: @breakpoints[xs]) {
    &__table {
      & th:nth-child(1),
      & td:nth-child(1) {
        width: 9rem;
      }
    }
  }
}
