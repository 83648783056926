.commodity-list-table-need-to-be-dispatch {
  @media screen and (max-width: @breakpoints[xxl]) {
    & th._origin-destination,
    & td._origin-destination {
      width: 18rem;
    }

    & th._trailer-type,
    & td._trailer-type {
      width: 10rem;
    }
  }

  @media screen and (max-width: @breakpoints[xl]) {
    & th._origin-destination,
    & td._origin-destination {
      width: 14rem;
    }

    & th._trailer-type,
    & td._trailer-type {
      width: 9rem;
    }
  }
}
