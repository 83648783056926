.booking-edit {
  position: relative;
  min-height: 50rem;
  padding-top: 20px;

  &__head-btn {
    border-color: @c-grey-light;
  }

  &__copy-from-prev-wrap {
    display: flex;
    align-items: center;
  }

  &__copy-from-prev-title {
    margin: 0 1rem 0 0;

    font-size: 1.2rem;
    color: @c-grey-darker;
    text-transform: none;
  }

  &__copy-from-prev {
    width: 40rem;
  }

  &__footer {
    padding: 4.5rem 2rem 3rem;
    border-top: 1px solid @c-grey-lighter;
  }

  &__logs {
    margin-bottom: 2.4rem;
  }

  &__error {
    margin-bottom: 2.5rem;
  }

  &__footer-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer-separator {
    margin: 0 auto;
    width: 1rem;
  }

  &__block {
    margin-bottom: 1px;
  }

  &__btn {
    margin-left: 1rem;
  }

  &__btn-left {
    margin-right: 1rem;
  }
}
