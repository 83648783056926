
          @WINDOW_SIZE_XS: 450px;
          @WINDOW_SIZE_XM: 575px;
          @WINDOW_SIZE_SM: 749px;
          @WINDOW_SIZE_MD: 980px;
          @WINDOW_SIZE_ML: 1100px;
          @WINDOW_SIZE_LG: 1340px;
          @WINDOW_SIZE_XL: 1600px;
          @WINDOW_SIZE_XXL: 1920px;
          @IS_CONSTRUCTION: true;
          @import "@/assets/less/variables/variables.less";
          @import "@/assets/less/mixins/mixins.less";
        

.tracker-panel {
  position: fixed;
  bottom: 5rem;
  right: 0;
  z-index: 1000;
  width: 15rem;
  font-variant-numeric: tabular-nums;
  transition: transform @time;
  transform: translateX(100%);

  &__inner {
    width: 100%;
    border-radius: 1rem 0 0 1rem;
    background: @c-grey-lightest;
    box-shadow: 0 0.1rem 0.6rem fade(#000000, 15);
    padding: 1.4rem;
    position: relative;
    z-index: 2;
  }

  &._open {
    transform: translateY(0);
  }

  &._open &__toggle {
    opacity: 1;
  }

  &._open &__toggle-arrow {
    transform: rotate(0);
  }

  &__toggle {
    position: absolute;
    right: 100%;
    top: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    opacity: 0.6;
    box-shadow: 0 0.1rem 0.6rem fade(#000000, 15);
    z-index: 1;

    .hoverOnly({
      &:hover {
        opacity: 1;
      }
    });

    &-arrow {
      width: 1rem;
      height: 1rem;
      margin-right: -0.5rem !important;
      margin-left: -0.3rem !important;
      transform: rotate(180deg);
    }
  }

  &__from-start {
    font-size: 1.2rem;
    color: @c-grey;
    display: flex;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    grid-gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
  }

  &__timers {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    text-decoration: none;
    position: relative;

    svg {
      position: absolute;
      top: -0.7rem;
      right: -0.2rem;
      width: 1.6rem;
      height: 1.6rem;
      opacity: 0.6;
      transition: opacity @time;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  &__timer {
    font-size: 2.4rem;
    font-weight: 500;
    transition-property: transform;
    transition-duration: @time;

    &._total {
      color: @c-green;
    }

    &._deduction {
      color: @c-yellow;
    }

    &._active {
      &._deduction {
        transform: translateY(-2rem);
      }
    }

    &:not(._active) {
      transform: scale(0.62);

      &._total {
        transform: scale(0.62) translateY(3.3rem);
      }
    }
  }
}
