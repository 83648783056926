.actions-buttons {
  display: flex;
  align-items: center;

  transition: @time;

  &__btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;

    color: @c-grey;
  }

  &__unselected-all {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: @c-grey;

      transition: @time;
    }

    &:hover svg {
      fill: @c-red;
    }
  }
}
