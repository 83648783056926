.show-gallery {
  &__previews {
    margin-top: 1rem;
  }

  &__footer {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
