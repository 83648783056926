.commodity-delivery-details {
  &__scroll-to-uploading {
    margin-right: 1rem;
  }

  &__checkbox-cell {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__checkbox {
    margin-right: 3rem;
  }

  &__footer {
    display: flex;
    margin-top: 2rem;
  }

  &__selected-wrap {
    display: flex;
    align-items: center;

    transition: @time;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__logs {
    padding-top: 1.6rem;
    margin-top: 2rem;
    border-top: 1px dashed @c-grey-lighter;
  }

  &__customer {
    padding: @input-padding;
    border: 1px solid @c-grey-lightest;
    background: @c-grey-lightest;
    border-radius: @radius;

    line-height: @f-lineheight;
  }
}
