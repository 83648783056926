.input {
  position: relative;
  font-size: @f-size-small;
  line-height: @f-lineheight-small;
  display: flex;
  flex-direction: column;

  &__label {
    display: block;
    margin-bottom: 0.6rem;
    font-size: inherit;
    line-height: inherit;
  }

  &__pre {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    height: @input-height;
    align-items: center;
    color: @c-grey;
    padding-left: @input-padding-side / 2;
    pointer-events: none;
  }

  &__pre ~ &__input {
    padding-left: @input-padding-side + @input-padding-side / 2;
  }

  &__input {
    width: 100%;
    border-radius: @radius;
    background: white;

    font-size: inherit;
    line-height: inherit;

    &:autofill,
    &:-webkit-autofill {
      background: white !important;
    }

    transition-property: color, border, outline, box-shadow, background;
    transition-duration: @time;

    min-height: @input-height;
  }

  textarea&__input {
    //min-height: @input-height;
  }

  &._no-resize &__input {
    resize: none;
  }

  &._large {
  }

  &._over &__label {
    position: absolute;
    bottom: 100%;
    left: (@input-padding-side / 2);
    z-index: 2;
    transform: translateY(((@input-height + 1.6rem) / 2));
    transform-origin: left bottom;
    display: block;
    margin: 0;
    width: calc(~'100% -' (@input-padding-side / 2));
    pointer-events: none;

    color: @c-grey-light;

    transition: @time;

    & > span {
      display: inline-block;
      padding: 0 (@input-padding-side / 2) + 0.1;
      background: white;
      border-radius: 0.3rem;
    }
  }

  &__input:autofill ~ &__label,
  &__input:-webkit-autofill ~ &__label,
  &._over._focus &__label,
  &._over._filled &__label {
    transform: translateY(0.5rem) scale(0.9);
  }

  &._disabled &__label {
    color: fade(@c-grey-light, 60);
    cursor: not-allowed;
  }

  &._disabled &__required,
  &._disabled &__input {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &._error &__input {
    border-color: @c-red-light;
  }

  &__required {
    color: @c-yellow;
  }

  // - - - -

  &__hide-btn,
  &__show-btn {
    .reset-button();
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    //background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    cursor: pointer;

    & svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: @c-blue;

      transition: transform @time;
    }

    &:hover svg {
      transform: scale(1.1);
    }
  }

  &._password &__input {
    padding-right: 3rem;
  }

  &._loading {
    pointer-events: none;
  }

  .preloader {
    width: auto;
    //right: 1.8rem;
    //left: auto;
  }
}
