.vendor-notes {
  @media (max-width: @breakpoints[md]) {
    &__btn {
      margin-right: 0;
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__table {
      display: block;

      tbody,
      thead {
        display: block;
      }

      thead {
        background-color: #dedede;
      }

      tr {
        display: flex;
      }

      th:last-child {
        font-size: 0;
      }

      td,
      th {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-child {
          width: 24%;
          flex-shrink: 0;
          max-width: 13rem;
        }

        &:nth-child(2) {
          width: 40%;
          flex-grow: 1;
        }

        &:nth-child(3) {
          width: 18%;
          //flex-basis: 6.4rem;
        }

        &:last-child {
          width: auto;
          flex-basis: 5rem;
        }

        &:first-child:last-child {
          flex-basis: 100%;
          max-width: none;
          width: 100%;
        }
      }
    }
    & > &__table {
      tbody tr {
        td {
          width: auto;

          &:first-child,
          &:nth-child(3) {
            display: none;
          }

          &:nth-child(2) {
            padding-left: @page-sides-padding-sm;
          }

          &:last-child {
            width: 8rem;
          }
        }
      }
    }
  }

  @media (max-width: @breakpoints[sm]) {
    height: auto;
    &__scroll-block {
      height: auto;
      overflow: visible;
    }
  }
}
