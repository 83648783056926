h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 1.5em 0;
  line-height: 1.2em;
  font-weight: @w-semi-bold;
  letter-spacing: @f-letter-spacing;
}

h1,
.h1 {
  margin: 0.6em 0;

  font-size: 5.5rem;
}

h2,
.h2 {
  margin: 0.8em 0;

  font-size: 4.8rem;
}

h3,
.h3 {
  margin: 1.2em 0;
  text-transform: none;

  font-size: 2.6rem;
}

h4,
.h4 {
  margin: @f-lineheight-small 0;
  font-size: 2rem;
  //font-weight: @w-medium;
}

h5,
.h5 {
  font-size: 1.8rem;
  text-transform: uppercase;
}

h6,
.h6 {
  font-size: 1.4rem;
}

a {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
  transition: color @time;

  .hoverOnly({
    &:hover {
      text-decoration: none;
      color: if((@IS_CONSTRUCTION = true), @primary-main-construction, @c-red);
    }
  });
}

address {
  font: inherit;
  color: @c-grey-dark;
  line-height: (24/14em);
}

///------

ul,
ol,
p,
table {
  margin: 1.25em 0;

  color: inherit;
  font-size: inherit;
}
