.add-commodity-to-loading {
  position: relative;
  min-height: 8rem;
  padding: 3rem;

  &__container-title {
    margin: 2rem 0 0;
    padding: 1rem 2rem;
    background: @c-grey-lightest;
    border: 1px solid @c-grey-lighter;
    border-bottom: 0;

    font-size: 1.4rem;
  }

  &__commodity {
    display: flex;
    align-items: center;
    border: 1px solid @c-grey-lighter;
    border-top: 0;
    padding: 1rem 2rem;
  }

  &__commodity-main {
    margin-right: auto;
    font-size: 1.2rem;
  }

  &__commodity-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__loading-right-note {
    font-size: 1.2rem;
    color: @c-orange;
  }

  &__alert {
    margin-top: 1rem;
  }
}
