@expenses-list-drop-statuses-marg: 1.2rem;

.expenses-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  &__header {
    position: sticky;
    z-index: 32;
    top: @header-height;
    background: white;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__actions {
    //margin-right: 1rem;
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;
    margin-left: 1rem;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__table {
    width: 100%;
    margin: 0;
  }

  &__table-wrap {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__filters {
    background-color: white;
    position: sticky;
    bottom: 0;
    z-index: 2;
    border: 1px solid @c-grey-lighter;
    border-left: 0;
    border-right: 0;
    padding: 1.2rem 2rem;
    display: flex;
    gap: 1.5rem 2rem;
    margin-top: auto;
  }

  &__filter-select {
    width: 20rem;

    .select__option._disabled {
      display: none;
    }
  }

  &__dates-range {
    width: 25rem;
  }

  // - - - -

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }

  // - - - -

  &._short &__header {
    border-bottom: 1px solid @c-grey-lighter;
    top: 0;
  }

  @media (max-width: @breakpoints[sm]) {
    &__selection-toolbar {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 50;
      background-color: white;
      padding: 0.9rem @page-sides-padding-sm;
      width: 100%;
    }

    &__selected {
      order: -1;
      margin-right: auto;
      margin-left: 0;

      .checkbox {
        vertical-align: middle;
        padding: 0.8rem;
        margin: -0.8rem;
        margin-right: 0;
        margin-top: -1rem;
      }
    }
  }
}
