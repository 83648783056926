.vendor-expenses {
  flex: 1;
  height: 20rem;
  display: flex;
  flex-direction: column;
  position: relative;

  &__content-wrap {
    flex: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: auto;
    flex-direction: column;
  }

  &__table {
    thead {
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }
}
