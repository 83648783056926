.light-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
  background: transparent;
  border: 0;
  border-radius: 0;

  font-family: @f-font;
  font-size: @f-size-small;
  line-height: @f-lineheight-small;
  font-weight: @w-semi-bold;
  color: @c-grey-darkest;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: @f-letter-spacing;
  vertical-align: middle;

  transition: color @time;

  &:hover {
    color: @c-blue-light;
  }
}
