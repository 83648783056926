.user-panel {
  @media (max-width: @breakpoints[sm]) {
    &__name-arrow {
      margin-left: 0;
    }

    &__dropdown {
      min-width: 12rem;
    }
  }
}
