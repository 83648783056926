.toaster {
  position: fixed;
  top: 0;
  right: 0;
  max-height: 100%;
  //width: 100%;
  z-index: 1002;
  padding: 1rem 0 1rem 1rem;

  //pointer-events: none;
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-start;
  //align-items: flex-end;
  overflow-x: hidden;
  overflow-y: auto;

  &__item {
    padding: 1.4rem 5rem 1.4rem 2.3rem;
    transition: transform @time-modal, opacity @time-modal;
    border-radius: 1rem 0 0 1rem;
    width: 37.5rem;
    position: relative;
    opacity: 0;
    margin: 1rem 0;
    color: white;
    background-color: @c-blue;
    word-break: break-word;
    max-width: calc(100vw - 2rem);
    cursor: pointer;
    box-shadow: 0 0.1rem 0.6rem fade(@c-blue, 90);
    transform: translateX(100%);

    &-text {
      font-weight: 600;
    }

    &._active {
      opacity: 0.9;
      transform: none;

      &:hover {
        opacity: 1;
      }
    }

    &-ico {
      width: 2rem;
      height: 2rem;
      position: absolute;
      right: 2rem;
      top: 1.3rem;
      svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
      }
    }

    &._success {
      background-color: @c-green;
      box-shadow: 0 0.1rem 0.6rem fade(@c-green, 90);
    }
    &._error {
      background-color: @c-red;
      box-shadow: 0 0.1rem 0.6rem fade(@c-red, 90);
    }

    ul {
      margin-bottom: 0;
      margin-top: 0.5rem;
      padding-left: 2.5rem;
    }
  }
}
