.light-table {
  background: transparent;

  & th,
  & td {
    padding: 10px;
    background: transparent;
    border-bottom: 1px solid @c-grey-lighter;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  & th {
    border-bottom-width: 2px;
  }
}
