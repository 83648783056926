.information-for-documents {
  &__toggle {
    margin-bottom: 2rem;
  }

  &__editor {
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__editor-result {
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    padding: 0.8rem;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }
}
