.commodity-page {
  &__main {
    //padding-right: @page-sides-padding;
  }

  &__main-content {
    background: white;
  }

  &__photos-wrap {
    padding: 2.4rem 2rem;
  }
}
