.auth-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
  }

  &__logo {
    height: 4rem;
    margin-bottom: 4rem;
  }

  &__auth-form {
    margin-bottom: 4rem;
  }

  &__footer {
    align-self: stretch;
  }
}
