.shipping-rates-select {
  width: 100%;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;
    min-height: 2.5rem;
    background: #f2f2f2;
    border-radius: 50%;
    padding: 0.4rem 0.3rem 0;
    font-weight: 600;
    color: #616161;
  }
}
