.files-all-documents {
  &__table {
    width: 100%;
    margin: 0;

    & col._edit {
      text-align: right;
    }
  }
}
