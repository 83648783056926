.aes-itn-list {
  &__header {
    position: sticky;
    z-index: 32;
    top: @header-height;
    background: white;
    padding: 2rem;
    display: flex;
    align-items: center;
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__only-booking {
    margin-left: auto;
  }

  &__table-wrap {
  }

  &__table {
    width: 100%;
    margin: 0;

    & th:last-child {
      width: 4.4rem;
    }
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }
}
