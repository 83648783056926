.manipulation {
  display: flex;
  align-items: center;

  transition: @time-modal;

  &__btn {
    margin-right: 1rem;

    &.btn {
      border-color: @c-grey-light;
    }

    &._pdf {
      & svg {
        fill: @c-pdf;
      }

      &:hover {
        border-color: @c-pdf;
        background-color: @c-pdf;
      }
    }

    &._print {
      & svg {
        fill: @c-print;
      }

      &:hover {
        border-color: @c-print;
        background-color: @c-print;
      }
    }

    &._email {
      & svg {
        fill: @c-email;
      }

      &:hover {
        border-color: @c-email;
        background-color: @c-email;
      }
    }

    &._viber {
      & svg {
        fill: @c-viber;
      }

      &:hover {
        border-color: @c-viber;
        background-color: @c-viber;
      }
    }

    &._whatsapp {
      & svg {
        fill: @c-whatsapp;
      }

      &:hover {
        border-color: @c-whatsapp;
        background-color: @c-whatsapp;
      }
    }

    &._trash {
      & svg {
        fill: @c-red;
      }

      &:hover {
        border-color: @c-red-light;
        background-color: @c-red-light;
      }
    }
  }

  &__selected {
    color: @c-grey;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__unselected-all {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: @c-grey;

      transition: @time;
    }

    &:hover svg {
      fill: @c-red;
    }
  }
}
