.commodity-show-section {
  background: white;

  &__content {
    border: 1px solid @c-grey-lighter;
    border-top: 0;
    padding: 1.5rem;
  }

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: @c-blue;
    min-height: 4rem;
    padding: 0.6rem 2rem;

    color: white;
  }

  &__header-title {
    padding-top: 0.2rem;
    margin: 0 2rem 0 0;
    flex-grow: 1;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: uppercase;
  }

  &__header-toggle {
    & svg {
      transition: transform @time !important;
    }

    & svg {
      transform: rotate(90deg);
    }

    &._open svg {
      transform: rotate(270deg);
    }
  }

  &__sub-title {
    margin: 0 0 1.5rem;

    font-size: 1.6rem;
  }

  &__main-info-table {
    width: 100%;
    margin: -0.5rem 0 0;

    & td:first-child {
      width: 50%;
    }
  }

  &._canceled &__main-info-table {
    color: @c-grey;
  }

  &__notes {
    list-style: none;
    margin: 0;
    padding: 0;

    font-size: 1.2rem;

    & li {
      margin: 0.6rem 0;
      position: relative;
      padding-left: 1rem;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        position: absolute;
        content: '•';
        left: 0;
      }
    }
  }

  &__actions {
    background: transparent;

    .actions__btn {
      background: transparent;

      &:hover {
        background: transparent;

        svg {
          fill: white;
        }
      }

      svg {
        fill: white;
      }
    }
  }

  &__canceled {
    margin: 0;
    width: auto;
    align-self: flex-start;
    padding: 0.5rem 2rem 0.5rem 1.5rem;
    border-radius: @radius;
    background: @c-orange;
    display: inline-flex;
    align-items: center;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: white;
    font-weight: @w-semi-bold;

    & b {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background: white;
      margin-right: 0.8rem;

      color: @c-orange;
    }
  }
}
