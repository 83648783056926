.status {
  .reset-button;
  margin: 0;
  padding: 0.4rem 0.8rem 0.4rem;
  display: inline-flex;
  align-items: center;
  background-color: @c-grey;
  border-radius: @radius;
  transition: background-color @time, color @time;

  color: white;
  font-size: 1rem;
  line-height: @f-lineheight-small;
  font-weight: @w-semi-bold;
  text-transform: uppercase;
  position: relative;

  svg {
    fill: currentColor;
    width: 1.3em;
    height: 1.3em;
    margin-right: 0.5em;
    margin-top: -0.3rem;
    margin-bottom: -0.3rem;
    flex-shrink: 0;
  }

  &._editable {
    cursor: pointer;
    &:hover {
      background-color: @c-grey-light;
    }
  }

  &._blue {
    background-color: @c-blue;
    &._editable:hover {
      background-color: @c-blue-light;
    }
  }

  &._green {
    background-color: @c-green;
    &._editable:hover {
      background-color: @c-green-light;
    }
  }

  &._yellow {
    background-color: @c-yellow;
    &._editable:hover {
      background-color: @c-yellow-light;
    }
  }
  &._orange {
    background-color: @c-orange;
    &._editable:hover {
      background-color: @c-orange-light;
    }
  }
  &._red {
    background-color: @c-red;
    &._editable:hover {
      background-color: @c-red-light;
    }
  }
}
