.commodity-show-documents {
  position: relative;

  font-size: 1.2rem;

  &__manipulation {
    margin-bottom: 2rem;
  }

  &__content {
    margin: -1rem;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  &__item {
    padding: 1rem;
  }

  &__add,
  &__file {
    width: 9rem;
    cursor: pointer;
  }

  &__add-main,
  &__file-main {
    width: 9rem;
    height: 9rem;
    border-radius: @radius;
    border: 1px dashed @c-grey-lighter;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    background: white;

    transition: @time;
  }

  &__add:hover &__add-main,
  &__file:hover &__file-main {
    border-color: @c-grey-light;
  }

  &__add-btn {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: @radius;
    background: @c-blue;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: white;
    }
  }

  &__add-name,
  &__file-name {
    text-align: center;
    font-weight: @w-semi-bold;
    line-height: 1.2em;
  }

  &__file-main {
    position: relative;
    border-style: solid;
    padding: 2.2rem 1rem 0.5rem;
    justify-content: flex-start;

    text-align: center;
    font-size: 1rem;
    line-height: 1.2em;
    color: @c-grey;
  }

  &__file-main-ico {
    width: 2.8rem;
    height: 2.8rem;
    fill: @c-blue;
    margin-bottom: 0.5rem;
  }

  &__file-check-wrap {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
  }

  &__error {
    margin-top: 1rem;
  }
}
