.commodity-list-table-deleted {
  & th:nth-child(4),
  & td:nth-child(4) {
    width: 16rem;
  }

  & th:nth-child(5),
  & td:nth-child(5) {
    width: 16rem;
  }

  & th:nth-last-child(6),
  & td:nth-last-child(6) {
    //width: 12rem;
    //text-align: left;
    //align-items: flex-start;
    //flex-grow: 1;
  }

  & th:nth-last-child(2),
  & td:nth-last-child(2) {
    width: 10rem;
    //align-items: flex-start;
  }

  //& th:last-child,
  //& td:last-child {
  //  width: 8.8rem + @scroll-width;
  //}
  //
  //& .commodities-list__scroll-block {
  //  & th:last-child,
  //  & td:last-child {
  //    width: 8.8rem;
  //    flex-direction: row;
  //    align-items: center;
  //  }
  //}
}
