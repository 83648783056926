.g-fs-11 {
  font-size: 1.1rem !important;
}

.g-fs-12 {
  font-size: 1.2rem !important;
}

.g-fs-13 {
  font-size: 1.3rem !important;
}

.g-fs-14 {
  font-size: 1.4rem !important;
}

.g-fs-15 {
  font-size: 1.5rem !important;
}

.g-fs-16 {
  font-size: 1.6rem !important;
}

.g-fs-17 {
  font-size: 1.7rem !important;
}

.g-fs-18 {
  font-size: 1.8rem !important;
}

.g-fs-19 {
  font-size: 1.9rem !important;
}

.g-fs-20 {
  font-size: 2rem !important;
}

.g-fs-24 {
  font-size: 2.4rem !important;
}

.g-fs-25 {
  font-size: 2.5rem !important;
}

.g-fs-32 {
  font-size: 3.2rem !important;
}
