.document-creations-options {
  &__main {
    position: relative;
    min-height: 9rem;
  }

  &__type-select {
    width: 56rem;
    max-width: 100%;

    & .select__dropdown {
      max-height: 20.5rem;
    }
  }

  &__commodities {
    width: 100%;
    margin: 2rem 0 0;

    & th:first-child {
      width: 5.4rem;
    }
  }

  &__error {
    margin-top: 3rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  }

  &__btn {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
