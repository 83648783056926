.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: auto;
  font-size: @f-size;
  word-break: break-word;

  &:not(._disabled):not(._readonly) {
    cursor: pointer;
    .no-selection();
  }

  &._small {
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &._disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  & input {
    display: none;
  }

  &__mark {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: @radius-small;
    border: 1px solid @c-grey-light;
    flex-shrink: 0;
    color: white;
    transition: @time;

    & svg {
      width: 1rem;
      height: 1rem;
      opacity: 0;
    }

    &::before {
      content: '';
      position: absolute;
      inset: -.6rem;
    }
  }

  &:not(._disabled):not(._readonly):hover &__mark {
    border-color: @c-green;
  }

  &._small &__mark {
    width: 1.6rem;
    height: 1.6rem;
  }

  &._checked &__mark {
    border-color: @c-green;
    background: @c-green;
    //box-shadow: 0 0 0.7rem 0 @c-green;

    & svg {
      opacity: 1;
    }
  }

  &._small &__mark svg {
    width: 0.8rem;
    height: 0.8rem;
  }

  &__text {
    margin-left: 0.8rem;
    font-size: inherit;

    .g-icon {
      font-size: 1.2em;
    }
  }

  &._disabled &__text {
    opacity: 0.8;
  }

  // _radio

  &._radio &__mark {
    border-radius: 50%;
  }
}
