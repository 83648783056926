.main-footer {
  padding-left: @page-sides-padding;
  background: white;
  border-top: 1px solid @c-grey-lightest;

  &__content {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 4rem;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__development {
    margin: 0;

    font-weight: @w-semi-bold;
    text-align: center;

    & a {
      font: inherit;
      color: inherit;
      text-decoration: none;
    }
  }
}
