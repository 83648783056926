.commodity-list-table-waiting-list {
  //& th:nth-child(3),
  //& td:nth-child(3) {
  //  width: 19rem;
  //}
  //
  //& th:nth-child(4),
  //& td:nth-child(4) {
  //  width: 19rem;
  //}

  & th._in-file,
  & td._in-file {
    width: 10rem;
  }
}
