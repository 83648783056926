.files-page {
  @media (max-width: @breakpoints[ml]) {
    &__files-list._short {
      display: none;
    }
    &__one-file {
      width: 100%;
      overflow: visible;
      height: auto;
      min-height: auto;
      background-color: white;
    }
  }
}
