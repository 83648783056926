.vendors-page {
  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    //padding-right: @page-sides-padding;
    min-height: @subpage-min-height;
    flex-grow: 1;
    background: white;
  }

  &__persons-list {
    width: 100%;

    &._short {
      width: 30rem;
      position: absolute;
      left: 0;
      top: 0;
      overflow-y: auto;
      height: 100%;
    }
  }

  &__one-person {
    margin-left: auto;
    width: calc(100% - 30rem);
    //height: 45rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &._full {
      width: 100%;
    }
  }

  &__status {
    margin-right: auto;
    //margin-left: 2rem;
    flex-shrink: 1;
    max-width: calc(100% - 5rem);

    & .dropdown__dropdown {
      width: 14rem;
    }

    & .dropdown__btn {
      border: 0;
      border-radius: 0;
      padding: 0;
      background: none;
      min-height: 2.8rem;

      font-size: @f-size;
      line-height: @f-lineheight;
      font-weight: @w-semi-bold;
      color: @c-grey-darkest;
    }

    & .dropdown__arrow svg {
      fill: @c-grey-darker !important;
    }
  }
}
