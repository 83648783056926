.payment-received-edit-result-info {
  border: 1px dashed @c-grey-lighter;
  border-radius: @radius;
  padding: 1.5rem 2rem;

  &__item {
    display: flex;
    margin-bottom: 1rem;

    text-align: right;

    &:last-child {
      margin-bottom: 0;
    }

    &._warning {
      color: @c-orange;
    }

    &._error {
      color: @c-red;
    }
  }

  &__item-title {
    width: 20%;
    flex-grow: 1;
    margin-right: 2rem;
  }

  &__item-value {
    width: 10rem;
    flex-shrink: 0;
  }
}
