.file-page {
  position: relative;
  min-height: 30rem;
  padding-bottom: 3rem;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 2rem;
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    margin: 0 2rem 0 0;
  }

  &__title-wrap &__title {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }

  &__title {
    margin: 0 2rem 0 0;
    font-size: 2.4rem;
  }

  &__booking-section {
    width: 100%;
    margin: 0 0 4rem;
  }

  &__booking {
    width: 100%;
    margin: 0 0 3rem;

    & th {
      background-color: @c-grey-lightest;
      border-top: 1px solid @c-grey-lighter;
    }
  }

  &__trucking-section {
    margin-bottom: 1rem;
  }

  &__container {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  &__back-wrap {
    padding: @page-sides-padding-sm;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__add-btn {
    padding: 0 0.4rem;
    width: 3rem;
    min-height: 3rem;

    font-size: 2.4rem;
    line-height: 1em;
  }

  &__back-btn {
    .reset-button;
    font-size: 1.2rem;
    align-items: center;
    font-weight: @w-semi-bold;
    color: @c-grey-darkest;

    svg {
      width: 0.6rem;
      height: 1rem;
      transform: scaleX(-1);
      fill: currentColor;
      margin-right: 0.9rem;
      flex-shrink: 0;
    }
  }
}
