.files-list {
  @media (max-width: @breakpoints[ml]) {
    &__header-row {
      border-bottom: 0;
      padding: 1rem 1.6rem;

      &._sticky {
        //background-color: #F9F9FB;
      }
    }

    &__header-content {
      display: block;
    }

    &__selection-toolbar {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 50;
      background-color: white;
      padding: 0.9rem @page-sides-padding-sm;
      width: 100%;
      margin: 0;
    }

    &__selected {
      order: -1;
      margin-right: auto;

      .checkbox {
        vertical-align: middle;
        padding: 0.8rem;
        margin: -0.8rem;
        margin-right: 0;
        margin-top: -1rem;
      }
    }

    &__drop-menu {
      margin-right: 0;

      .dropdown__dropdown {
        top: auto;
        bottom: 100%;
        left: auto;
        right: 1.5rem;
        margin-bottom: 0.5rem;
      }

      .dropdown__dropdown-inner {
        top: auto;
        bottom: 0;
      }
    }

    &__cards {
    }
  }
}
