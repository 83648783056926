.inventory-edit {
  &__input {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__alert {
    margin-bottom: 3rem;
  }

  &__img-wrap {
    display: block;
    width: 8.5rem;
    background: @c-grey-lightest;
    position: relative;
    margin-right: 1rem;
    flex-shrink: 0;
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 100%;
    }

    a&:hover {
      opacity: 0.8;
      cursor: zoom-in;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__main {
    display: flex;
    align-items: flex-start;

    &-inputs {
      flex: 1;
    }
  }
}
