.help-tooltip {
  position: relative;
  display: inline-flex;
  margin: 0 0.5rem;

  &__btn {
    min-height: 0;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    padding: 0;

    font-size: 1rem;
    font-weight: @w-semi-bold;

    .no-selection();
  }

  &__dropdown {
    position: absolute;
    z-index: 41;
    top: calc(100% + 1rem);
    left: 0;
    width: 100%;

    transition: @time-modal;
  }

  &__arrow {
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    pointer-events: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.6rem;
      left: 0.5rem;
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg);
      transform-origin: center;
      background: white;
      box-shadow: 0 0 0.6rem fade(black, 15);
    }
  }

  &__dropdown-inner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 1.6rem 2rem;
    box-shadow: 0 0.4rem 0.6rem fade(black, 15);
    border: 1px solid @c-grey-lighter;
    border-top: 0;
    border-radius: @radius;
    min-width: 18rem;
  }
}
