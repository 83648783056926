.gallery-main {
  position: relative;
  width: 100%;
  background: @c-grey-lightest;
  border: 1px solid @c-grey-lighter;
  border-radius: @radius;

  &__carousel {
    position: relative;
    width: 100%;
    height: 29.7rem;
    border-radius: @radius;
    overflow: hidden;
  }

  &__list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__item {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAYCAYAAAACqyaBAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAgFJREFUSIm1ls1x2zAQhT9qVABP5pWpwEoFoTqQKwhUQaSjebFzwZV2BZYqEFOBmAqiDqIrTmEHzmEXQ4jmmOSIejOcBYgdvF3sDxDdPb4TYAGsmBals5y6FuYqY+AAZBMTA9TQTR6p50clPumXAelE5FUwPgN7Z+VfdPf4vgD+KOkeKCYi/Qw7Z1nPaWL8S4lrYAvshuzi7HDGJGcFvAEmydnPgrV7la9DicfCWUrEMYAsJI9VVrcgDnD2g9knSh4ZUglH4HlKK+Y96waJUWjIPfAwZPMkxwDfgK2z1O31Ps+fVG6BL0hFrJBm1Ee8QBLYBPuMIk9VvqA1qvO4S7mFt0Bvk+QfG1gfue9MBeLxd52fvUKSc0hy/qmn/l+BnM6JJkRh+AaR+7LYIEm3oDmF0KgYOCY5sdbyBukXay2vEkiT/DJh+xKuQmJtdH6m6QEmIKnUiCNNqH4GF8oaSdYnglLuI/eEz61/Ppn8xpWS+qMvneXFKztLneQ80Lq4htR5G/4GjBHvSi2jJRLjWg26gLNUzo479i4cEC9LghNRA76O2WiGJNAS+D1Av6C5ej94NxYzmoTpgyHIYpVXkw9BO8E6XybXkHtPsg69Ak0wJNbXIPWDKHhApsBfHW+59O5HQH4tsXdkGbVer4aONngDrJ1l1y61HZJ85obElX9A/gf/YI66TWjQqQAAAABJRU5ErkJggg=='),
      zoom-out;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &._open-in-lightbox &__item {
    cursor: zoom-in;
  }

  &._disabled &__item {
    cursor: not-allowed;
  }

  &__no-items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-weight: @w-semi-bold;
    color: @c-grey;
  }

  &__item-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__item-group-name {
    position: absolute;
    left: 0;
    bottom: 3rem;
    background: fade(white, 90%);
    padding: 0.4rem 1rem;
  }

  &__nav.btn {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    min-width: 4rem;
    min-height: 4rem;
    background-color: fade(white, 80%);

    &._disabled {
      opacity: 0.5;
      pointer-events: auto;
      cursor: not-allowed;
    }

    &:not(._disabled):hover {
      background-color: fade(white, 100%);
    }

    &._left {
      left: 0;
      border-radius: 0 @radius @radius 0;
    }

    &._right {
      right: 0;
      border-radius: @radius 0 0 @radius;
    }

    & svg {
      fill: @c-grey-dark;
      width: 1rem;
      height: 1.4rem;
    }

    &._left svg {
      transform: rotate(180deg);
    }

    &.swiper-button-lock {
      display: none;
    }
  }
}
