.side-nav {
  @media screen and (max-width: @breakpoints[xl]) {
    &__content {
      padding-left: @page-sides-padding-lg;
    }
  }

  @media screen and (max-width: @breakpoints[lg]) {
    &__content {
      padding-left: 0;
    }

    &__link {
      &::before,
      &::after {
        border-radius: 0;
      }
    }
  }
}

@media screen and (max-width: @breakpoints[lg]) {
  .sublist,
  .sublist::after {
    border-radius: 0;
  }
}

//@media screen and (max-width: @breakpoints[sm]) {
//  .side-nav {
//
//    &__content {
//      padding-left: @page-sides-padding-lg;
//    }
//  }
//}
