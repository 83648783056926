.customer-files {
  @media (max-width: @breakpoints[md]) {
    &__head {
      align-items: center;
      padding: 1.6rem @page-sides-padding-sm 2rem;
      flex-wrap: wrap;
    }

    &__select-wrap {
      width: 100%;
      display: flex;
      align-items: center;

      .select {
        flex-grow: 1;
      }
    }

    &__search {
      width: 100%;
      margin-top: 1rem;
      background-color: white;
    }
  }

  @media (max-width: @breakpoints[sm]) {
    height: auto;
    background-color: transparent;

    &__content-wrap {
      display: block;
      overflow: visible;
      height: auto;
    }
  }
}
