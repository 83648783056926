.hide-particle {
  position: relative;
  //width: 100%;
  min-width: 0;
  pointer-events: none;

  &__placeholder {
    //max-width: 100%;
    //overflow: hidden;
    position: relative;
    //display: inline-block;
    .no-wrap();
    transition: opacity @time-modal;
    pointer-events: auto;

    & * {
      min-width: 0;
    }

    //&::before,
    //&::after {
    //  content: '';
    //  position: absolute;
    //  top: 0;
    //  right: 0;
    //  width: 4rem;
    //  max-width: 100%;
    //  height: 100%;
    //  background: linear-gradient(to left, white, fade(white, 0));
    //  transition: opacity @time;
    //}
    //
    //&::after {
    //  display: none;
    //  background: linear-gradient(to left, @c-grey-lightest, fade(@c-grey-lightest, 0));
    //}
  }

  //&._grey &__placeholder::before {
  //  background: linear-gradient(to left, @c-grey-lightest, fade(@c-grey-lightest, 0));
  //}

  &__overlay {
    position: absolute;
    z-index: 21;
    top: -0.2rem;
    left: -0.5rem;
    background: white;
    padding: 0.2rem 0.5rem;
    box-shadow: 0 0 1rem fade(@c-grey-darkest, 20);
    border-radius: @radius;
    opacity: 0;
    //visibility: hidden;
    //.no-wrap();
    //pointer-events: auto;
    transition: opacity @time-modal;
    //white-space: normal;
    //word-wrap: break-word;
    .wrap();
    pointer-events: none;
  }

  //&:hover &__placeholder {
  //  opacity: 0;
  //}

  &._wrap-overlay &__overlay {
    .wrap();
  }

  &:hover &__overlay {
    opacity: 1;
    pointer-events: auto;
  }
}
