.invoices-generators-page {
  &__main-content {
    position: relative;
    display: flex;
  }

  &__invoices-list {
    min-width: 40rem;
    flex: 1 1 40rem;
    border-right: 1px solid @c-grey-lighter;
    @media screen and (max-width: @breakpoints[md]) {
      display: none;
    }
  }

  &__one-invoice {
    flex: 1 0 calc(100% - 40rem);
  }

  .single-page__back-btn {
    margin-bottom: 2rem;
  }
}
