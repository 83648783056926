.commodity-list-table-in-yard {
  & th:nth-child(4),
  & td:nth-child(4) {
    width: 16rem;
  }

  & th:nth-child(5),
  & td:nth-child(5) {
    width: 16rem;
    flex-grow: 1;
  }

  & th:nth-child(6),
  & td:nth-child(6) {
    width: 12.5rem;
  }
}
