.commodity-delivery-rates-toolbar {
  @media (max-width: @breakpoints[ml]) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: white;
    padding: 0.9rem @page-sides-padding-sm;
    width: 100%;

    &__selected {
      order: -1;
      margin-right: auto;

      .checkbox {
        vertical-align: middle;
        padding: 0.8rem;
        margin: -0.8rem;
        margin-right: 0;
        margin-top: -1rem;
      }
    }

    &__head-btn {
      background-color: #808080 !important;
      border-color: #808080 !important;

      svg {
        fill: white !important;
      }
    }

    &__drop-menu {
      margin-right: 0;

      .dropdown__dropdown {
        top: auto;
        bottom: 100%;
        left: auto;
        right: 1.5rem;
        margin-bottom: 0.5rem;
      }

      .dropdown__dropdown-inner {
        top: auto;
        bottom: 0;
      }
    }
  }
}
