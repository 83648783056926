.preview-commodity {
  font-size: @f-size-small;
  line-height: @f-lineheight-small;

  &:hover {
    color: inherit;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__photo-wrap {
    margin: -0.5rem 1rem -0.5rem 0;
  }

  &__descr-main {
    width: 50%;
    flex-grow: 1;

    .status:not(:first-child) {
      margin-top: 0.2rem;
    }
  }

  &__vin {
    //.no-wrap();

    &:first-child {
      margin-top: 0;
    }
  }

  &__name + &__vin {
    margin-top: 0.2rem;
  }

  &__name,
  &__vin {
  }
}
