.photo-edit {
  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__photo-wrap {
    width: 8rem;
    height: 8rem;
    border: 1px solid @c-grey-lighter;
    flex-shrink: 0;
    margin-right: 2rem;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__current-name {
    font-size: 1.4rem;
  }

  &__input {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
