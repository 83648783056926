.time-log-edit {
  background: white;

  &__employee-remark {
    margin: -1rem 0 2.5rem;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__input {
    margin-bottom: 2rem;
  }

  &__time-range {
    display: flex;
    margin-bottom: 2rem;
  }

  &__time-range-input {
    width: 10%;
    flex-grow: 1;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    margin: 1rem 0 2.2rem;

    font-size: 1.2rem;
    color: @c-grey;
    font-weight: @w-semi-bold;
    text-transform: uppercase;

    & > span {
      position: relative;
      padding-right: 0.5rem;
      background: white;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0.7rem;
      left: 0;
      width: 100%;
      border-bottom: 1px dashed @c-grey-lighter;
    }
  }

  &__sub-title &__sub-title-value {
    //padding: 0.4rem 0.8rem;
    padding: 0;
    //margin-left: 0.6rem;
    border-radius: @radius;
    display: inline-block;
    //background: @c-grey-lightest;
    //border: 1px dashed @c-grey-lighter;

    font-weight: @w-semi-bold;
    text-transform: none;
    color: @c-grey-darkest;

    &._less {
      color: @c-orange;
    }

    &._same {
      color: @c-green;
    }

    &._more {
      color: @c-red-light;
    }
  }

  &__separator {
    margin: 2rem 0;
    border-bottom: 1px dashed @c-grey-lighter;
  }

  &__total {
    margin: 0 auto 0 0;
    padding: 0 2rem 0 0;
    //text-align: right;
    font-size: 1.8rem;

    &-main {
      color: @c-green;
    }

    &-left {
      font-size: 1.4rem;

      &._less {
        color: @c-orange;
      }

      &._same {
        color: @c-green;
      }

      &._more {
        color: @c-red-light;
      }
    }
  }

  &__pin-wrap {
    margin-bottom: 4rem;
  }

  &__radio-list {
    margin-bottom: 2rem;
  }

  &__pin {
    border-bottom: 1px solid @c-grey-lighter;
    padding: 2rem 0;
    display: flex;
    align-items: center;

    &._add {
      padding: 1rem 0;
    }
  }

  &__pin-content {
    width: 50%;
    flex-grow: 1;
  }

  &__pin-duration {
    //font-size: @f-size-small;
    //line-height: @f-lineheight-small;
    //padding-left: 1.1rem;
    line-height: 1.1;
    flex: 0 0 8rem;
    text-align: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 3.4rem;
    font-weight: 500;
  }

  &__sub-title + &__pin {
    padding-top: 0;
  }

  &__pin-row {
    display: flex;
    margin-bottom: 1rem;
  }

  &__pin-type {
    width: 16rem;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  &__pin-input {
    width: 50%;
    flex-grow: 1;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    &._time {
      flex-shrink: 0;
      width: 7rem;
    }
  }

  &__delete-pin-wap {
    align-self: stretch;
    flex-shrink: 0;
    margin-left: 1rem;
    padding-left: 1rem;
    //display: flex;

    border-left: 1px solid @c-grey-lighter;
  }

  &__delete-pin {
    position: sticky;
    top: 6rem;
  }
  &__delete-pin svg {
    width: 1.2rem;
    height: 1.2rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0 2.5rem;
    margin-bottom: -2.5rem;
    margin-top: 1rem;
    //background-image: linear-gradient(to bottom, fade(white, 0), white 1rem);
    background: white;
    position: sticky;
    bottom: -1rem;
    z-index: 2;
    box-shadow: 0 -1rem 1rem -1.2rem rgba(140, 140, 140, 0.5);
  }

  &__btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__deduction-select .select__option {
  }
}
