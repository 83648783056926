.loading-tabs {
  &__head {
    padding: 2rem;
  }

  &__container-wrap {
    margin-top: 0;

    & .info-block__table-wrap {
      padding: 1rem 0 0;
    }
  }

  &__table {
    width: 100%;
    margin: 0;

    //border: 1px solid @c-grey-lighter;

    td {
      vertical-align: middle;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    & td._count {
      width: 3.5rem;
      padding-right: 0;
    }

    & td._descr {
      //width: 35%;
      width: auto;
    }

    & td._comments {
      width: auto;
    }

    & td:nth-last-child(3) {
      width: 4.5rem;
      .notes-btn {
        margin: 0;
      }
    }

    & td:nth-last-child(2) {
      width: 5.4rem;
    }

    & td:last-child {
      width: 5.4rem;
    }

    & tr:last-child td {
      border-bottom: 0;
    }
  }
  //
  //&__add-btn {
  //  margin: 0;
  //}

  &__comments-button {
    position: relative;
    z-index: 2;
    background: white;
    padding: 0 0.6rem;
    margin-left: -0.6rem;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    color: #808080;
    text-transform: uppercase;
    transition: 0.15s;
  }

  &__add-comments {
    margin-top: 1.5rem;
  }

  &__button-delete {
    background: #f2f2f2 !important;
    height: 2.4rem;
    min-width: 2.4rem !important;
    margin-right: 1.5rem;
    transition: all 0.3s;

    &:hover {
      border-color: #aa0300 !important;
      background-color: #aa0300 !important;
    }
  }

  &__comments-container {
    display: flex;
    align-items: center;
  }

  &__comments-field {
    min-width: 22rem;
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    .col-4 {
      &:last-child {
        .input {
          max-width: 15rem;
        }
      }
    }
  }
}
