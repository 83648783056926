.upload-photos {
  width: 100%;
  background: white;
  //padding: 2.4rem 1.8rem;

  &__main {
    width: 100%;
    display: flex;
  }

  &__list {
    //flex-basis: 30%;
    // display: grid;
    // grid-auto-rows: 9.6rem;
    // grid-gap: 2rem;
    // flex-basis: 10%;
    // grid-template-columns: repeat(auto-fill, auto);
    margin-right: 3rem;
  }

  //&__img-wrap {
  //  position: relative;
  //  width: 100%;
  //  height: 100%;
  //  border-radius: @radius;
  //  overflow: hidden;
  //
  //  &:first-child {
  //    grid-column: ~"1 / 3";
  //    grid-row: ~"1 / 3";
  //  }
  //
  //  &:nth-child(2) {
  //    grid-column: ~"3 / 4";
  //  }
  //
  //  &:nth-child(3) {
  //    grid-column: ~"3 / 4";
  //    grid-row: ~"2 / 3";
  //  }
  //
  //  &:nth-child(5) {
  //    grid-column: ~"4 / 5";
  //    grid-row: ~"2 / 3";
  //  }
  //
  //  &:nth-child(7) {
  //    grid-column: ~"5 / 6";
  //    grid-row: ~"2 / 3";
  //  }
  //}

  &__upload-outer {
    flex-grow: 1;
    min-width: 15%;
    display: flex;
  }

  &__img-wrap ~ &__upload-outer {
    padding-left: 4rem;
  }

  &__upload-wrap {
    display: flex;
    flex-grow: 1;
    position: relative;
  }

  &__upload {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed @c-grey-lighter;
    border-radius: @radius;
    padding: 3rem;
    overflow: hidden;
    cursor: pointer;

    text-align: center;
  }

  &__upload-ico {
    fill: @c-grey-lightest;
  }

  &__upload-title {
    margin: 2rem 0 2.4rem;

    color: @c-grey-lightest;
    font-weight: @w-bold;
    font-size: 1.8rem;
    text-transform: uppercase;
  }

  &__upload-note {
    margin: 0;
    color: @c-grey-dark;
  }

  &__upload-note-accent {
    text-decoration: underline;
    color: @c-blue;
  }

  &__input {
    display: none;
  }

  // - - - -

  &__loader-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border: 1px dashed @c-grey-light;
    border-radius: @radius;
    padding: 3rem;
  }

  &__loader {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.4rem solid @c-grey-lightest;
    border-left-color: @c-green;
    border-right-color: @c-green;
    animation: rotate 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &__loader-text {
    margin: 1rem 0 -1rem;

    color: @c-grey;
    font-size: 1.2rem;
  }

  &__footer {
    padding-top: 2rem;
    display: flex;
    align-items: center;

    transition: @time;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__logs {
    padding-top: 1.6rem;
    margin-top: 2rem;
    border-top: 1px dashed @c-grey-lighter;
  }
}
