.upload-file-small-prev {
  position: relative;
  width: 3.4rem;
  height: 3.4rem;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__btn {
    .reset-button();
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    cursor: pointer;
    overflow: hidden;

    & input {
      display: none;
    }

    & svg {
      width: 0.7rem;
      height: 1.4rem;
      fill: @c-blue;
    }

    &._img {
      cursor: zoom-in;
    }
  }

  &__delete {
    .reset-button();
    position: absolute;
    z-index: 5;
    right: -0.8rem;
    top: -0.8rem;
    padding: 0;
    background: @c-red;
    width: 2rem;
    height: 2rem;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    & svg {
      fill: white;
    }

    &:hover {
      background-color: @c-red-light;
    }
  }
}
