.main-header {
  @media (max-width: @breakpoints[xl]) {
    padding: 0 @page-sides-padding-lg;
  }

  @media (max-width: @breakpoints[sm]) {
    padding: 0 @page-sides-padding-sm;

    &__help,
    &__setting-lists {
      margin-right: 3rem;
    }

    &__menu-btn {
      margin-left: 1.5rem;
    }
  }

  @media (max-width: @breakpoints[xs]) {
    &__content {
      gap: 1.6rem;

      & > a:not(:first-child),
      button {
        margin: 0;
      }
    }
  }
}
