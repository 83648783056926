.select {
  position: relative;

  font-size: @f-size-small;
  line-height: @f-lineheight-small;

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  min-width: 0;

  &._full-width {
    width: 100%;
  }

  &__title {
    display: block;
    margin-bottom: 0.5rem;

    font: inherit;
  }

  &._disabled {
    //cursor: not-allowed;
  }

  &._disabled &__title {
    opacity: 0.8;
  }

  &._over &__title {
    position: absolute;
    bottom: 100%;
    left: (@input-padding-side / 2);
    z-index: 2;
    transform: translateY(((3.4rem + 1.6rem) / 2));
    transform-origin: left bottom;
    display: block;
    margin: 0;
    width: calc(~'100% -' (@input-padding-side / 2));
    pointer-events: none;

    color: @c-grey-light;

    transition: @time;

    & > span {
      display: inline-block;
      padding: 0 ((@input-padding-side / 2) + 0.1);
      background: white;
      border-radius: 0.3rem;
    }
  }

  &._over._open &__title,
  &._over._selected &__title {
    transform: translateY(0.5rem) scale(0.9);
  }

  &__wrap {
    position: relative;
  }

  &__container {
    width: 100%;
    min-height: 3.4rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    border-radius: @radius;
    border: 1px solid @c-grey-lighter;
    background: white;

    cursor: pointer;

    transition: @time;

    & > * {
      flex-shrink: 0;
    }
  }

  &._disabled &__container {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &._open &__container,
  &:not(._disabled):not(._error):hover &__container {
    border-color: #cdcccc;
  }

  &._error &__container {
    border-color: @c-red-light;
  }

  &__main {
    position: relative;
    width: 40%;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 0.6rem 0 0.6rem @input-padding-side;
  }

  &._multiple &__main {
    flex-wrap: wrap;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.4rem;
  }

  &__input {
    width: 0;
    flex: 0 1 auto;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;

    font: inherit;
    color: inherit;

    .no-wrap();

    transition: max-height @time;
  }

  &._open:not(._multiple) &__input {
    position: absolute;
    flex-grow: 1;
    top: 50%;
    width: calc(100% - @input-padding-side);
    transform: translateY(-50%);
  }

  &._multiple._selected &__input {
    max-height: 0;
  }

  &._multiple._open &__input {
    //max-height: 1.2em;
    max-height: none;
    width: 25%;
    flex-grow: 1;
    padding: 0.3rem 0.2rem 0.3rem 0.5rem;
    margin: 0.2rem 0.4rem 0.2rem 0;
  }

  &__current {
    display: inline-block;
    flex: 1 1 auto;
    line-height: 1.6rem;
    .no-wrap();
  }

  &._selected._open:not(._multiple) &__current {
    opacity: 0.5;
  }

  &._multiple &__current {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.2rem 0.4rem 0.2rem 0;
    padding: 0.3rem 0.2rem 0.3rem 0.5rem;
    background: @c-grey-lightest;
    border: 1px solid @c-grey-lighter;
    border-radius: 0.3rem;
    flex-grow: 0;
    transition: @time;

    .wrap();

    &:last-child {
      margin: 0;
    }
  }

  &__current &__cancel {
    height: auto;
  }

  & &__current._placeholder {
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 4rem);
    margin: 0;
    padding: 0;
    opacity: 0.5;
    border: 0;
    border-radius: 0;
    background: transparent;
  }

  &__cancel {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    margin-left: 0.2rem;
    position: relative;
    height: 3.2rem;

    & svg {
      width: 0.8rem;
      height: 0.8rem;
      fill: @c-grey-light;
      transition: @time;
    }
  }

  &:not(._disabled) &__cancel {
    &:hover svg {
      fill: @c-red-light;
    }
  }

  &._multiple:not(._disabled) &__current {
    &:hover {
      color: @c-red-light;
      svg {
        fill: @c-red-light;
      }
    }
  }

  &._multiple &__cancel {
    //padding-top: 0.2rem;
  }

  &._error &__input {
    border-color: @c-red-light;
  }

  &__dropdown {
    position: absolute;
    z-index: 41;
    transform-origin: center top;
    top: 100%;
    left: 0;
    width: 100%;
    padding-bottom: 4rem;
    pointer-events: none;
  }

  &._right &__dropdown {
    right: 0;
    left: auto;
  }

  &._to-top &__dropdown {
    top: auto;
    bottom: 100%;
    transform-origin: center bottom;
    padding-bottom: 0;
    padding-top: 4rem;
  }

  &._to-top &__dropdown-inner {
    //border-top-width: 1px;
    //border-bottom-width: 0;
  }

  &__dropdown-inner {
    background: white;
    padding: 0.5rem 0;
    box-shadow: 0 0.4rem 0.6rem .2rem fade(black, 10);
    border: 1px solid @c-grey-lighter;
    margin: 1px 0;
    //border-top-width: 0;
    border-radius: @radius;
    max-height: 34rem;
    overflow-y: auto;
    overflow-x: hidden;
    pointer-events: auto;
  }

  &__option {
    padding: 0.8rem @input-padding-side;
    cursor: pointer;

    &._selected {
      background-color: @c-grey-lightest;
      color: @c-blue !important;
      & * {
        color: @c-blue !important;
      }

      &:not(._disabled) {
        &:focus,
        &:hover {
          background-color: fade(@c-blue, 50) !important;
          //color: white !important;
          //& * {
          //  color: white !important;
          //}
        }
      }
    }

    &._disabled {
      cursor: not-allowed;
      //background: fade(@c-orange, 15);
      opacity: 0.6;
    }

    &:not(._disabled),
    &:not(._selected) {
      &:focus,
      &:hover {
        background-color: @c-blue !important;
        color: white !important;
        & * {
          color: white !important;
        }
      }
    }
  }

  &__option-row {
    margin-bottom: 0.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__dropdown-placeholder {
    padding: 1rem 2rem;
    opacity: 0.6;

    text-align: center;
  }

  &__arrow {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.7rem;
    height: 3.2rem;
    padding-right: 0.5rem;
    position: relative;

    & svg {
      width: 0.6rem;
      height: 1rem;
      opacity: 0.5;
      transform: rotate(90deg);

      transition: @time;
    }

    .preloader {
      right: 0.5rem;
      width: auto;
      //margin-left: -0.2rem;
    }
  }

  &._open &__arrow svg {
    transform: rotate(270deg);
  }

  &._open &__arrow svg,
  &:not(._disabled):hover &__arrow svg {
    opacity: 0.65;
  }

  //&__loader {
  //  position: absolute;
  //  pointer-events: none;
  //  flex-shrink: 0;
  //  display: block;
  //  width: 2rem;
  //  height: 2rem;
  //  border: 0.2rem solid fade(@c-green, 20);
  //  border-right-color: @c-green;
  //  border-left-color: @c-green;
  //  border-radius: 50%;
  //  animation: vueBaseSelectLoading 0.6s;
  //  animation-timing-function: linear;
  //  animation-iteration-count: infinite;
  //  font-size: 0;
  //}

  &__required {
    color: @c-yellow;
  }
}

//@keyframes vueBaseSelectLoading {
//  to {
//    transform: rotate(360deg);
//  }
//}
