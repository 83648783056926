.error-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  font-size: @f-size-small;
  line-height: @f-lineheight-small;
  color: @c-red-light;

  transition: @time-modal;

  &__item {
    margin: 0.5rem 0;
  }
}
