.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;

  &__nav {
    .reset-button;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 2.4rem;
    width: (24/14em);
    //height: 2.4rem;
    height: (24/14em);
    color: fade(@c-grey-darkest, 60%);
    transition: color @time, opacity @time;
    text-decoration: none;

    &._prev {
      transform: rotate(180deg);
    }

    &._next {
    }

    &._disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    & svg {
      //width: 0.5rem;
      width: (5/14em);
      //height: 0.9rem;
      height: (9/14em);
    }

    &:hover {
      color: @c-green;
    }
  }

  &__pages {
    //min-width: 4rem;
    min-width: (40/14em);

    cursor: pointer;

    .no-selection();
  }

  &__dropdown {
    position: absolute;
    //top: calc(100% + 0.6rem);
    @val: (6/14em);
    top: calc(100% + @val);
    //left: calc(50% - 3rem);
    @val2: (30/14em);
    left: calc(50% - @val2);
    //width: 6rem;
    width: (60/14em);
    z-index: 41;

    transition: @time-modal;
  }

  &__dropdown-content {
    display: flex;
    //border: 1px solid @c-green;
    //padding: 0.2rem;
    padding: (2/14em);
    width: 100%;
    overflow: hidden;
    border-radius: @radius;
    box-shadow: @dropdown-shadow;
    background: white;
  }

  &__dropdown-arrow {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2rem;
    height: 1rem;
    overflow: hidden;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 0.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg);
      transform-origin: center;
      background: white;
      box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.15);
    }
  }

  &__input-to {
    border: 0;
    background: transparent;
    width: 50%;
    //padding: 0.5rem;
    padding: (5/14em);

    text-align: center;
    font-size: (12/14em);
  }

  &__btn-to {
    min-height: 0;
    //padding: 0.2rem;
    padding: (2/14em);
    border-radius: 0 @radius - 0.1 @radius - 0.1 0;
    width: 50%;
    font-size: (12/14em);

    //font-size: @f-size-small;
    //line-height: @f-lineheight-small;
  }
}
