.contracts-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  &__header-row {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @c-grey-lighter;

    &._sticky {
      position: sticky;
      z-index: 32;
      top: @header-height;
      background-color: white;
    }
  }

  &._short &__header-row._sticky {
    top: 0;
  }

  &__table-wrap {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    table {
      thead {
        position: sticky;
        top: 10.5rem;
        z-index: 2;
      }
    }
  }

  &__table {
    margin: 0;
    width: 100%;

    col {
      &._checkbox {
        width: 4.8rem;
      }
      &._date {
      }

      &._vendor,
      &._customer {
      }

      &._amount {
        width: 15rem;
      }

      &._description {
      }

      &._notes {
      }

      &._actions {
        width: 4rem;
      }
    }

    td,
    th {
      &:last-child {
        text-align: right;
      }
    }
  }

  &__item {
    transition: background-color @time;

    @media (min-width: @breakpoints[md] + 1px) {
      &:hover {
        background-color: @c-grey-lightest;
      }
    }

    & td:not(._no-link) {
      cursor: pointer;
    }
  }

  &__item-pay {
    //width: 10rem;
    margin-top: -0.5rem;
  }

  &__item-pay-full {
    .reset-button();
    display: block;
    margin-bottom: -0.6rem;
    cursor: pointer;
    text-decoration: underline;

    font-size: 1rem;
    color: @c-blue;

    &:disabled {
      //cursor: not-allowed;
      cursor: auto;
      opacity: 0.6;
      color: @c-grey;
      text-decoration: none;
    }
  }

  @media (max-width: @breakpoints[md]) {
    &__table-wrap {
      padding: 0 1.8rem;
    }

    &__table,
    tbody {
      display: block;
    }

    colgroup,
    thead {
      display: none;
    }

    tr {
      display: flex;
      flex-direction: column;
      padding: 1.1rem;
      border: 1px solid #e0e0e0;
      border-radius: 0.4rem;
      background-color: white;
      margin-top: 1.4rem;
      font-size: 1.2rem;
      padding-bottom: 0.5rem;
      position: relative;
    }

    td {
      border: 0;
      padding: 0;
      text-align: left !important;
      margin-bottom: 0.6rem;
      vertical-align: baseline;

      &::before {
        content: attr(data-title);
        font-weight: 600;
      }

      &._actions {
        position: absolute;
        padding: 1.1rem;
        top: 0;
        right: 0;
      }
    }
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  .files-list__selected {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    @media (max-width: @breakpoints[md]) {
      flex-direction: row;
    }
  }

  @media (max-width: @breakpoints[md]) {
    .files-list__selected {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      background-color: white;
      width: 100%;
      padding: 0.9rem 1.6rem;
    }
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__short-item {
    display: flex;
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    //background: @c-grey-lightest;
    font-size: 1.2rem;
    cursor: pointer;

    &._selected {
      background-color: @c-blue-2;
    }
  }

  &__short-checkbox {
    flex-shrink: 0;
    margin-right: 2rem;
  }

  &__short-item-content {
    flex-grow: 1;
    width: 50%;
    margin-top: -0.5rem;
    margin-right: -1rem;

    & > div {
      display: flex;
      justify-content: space-between;
    }
  }

  &__short-el {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    //flex-grow: 1;
    flex: 1;
    //width: 0;
    display: flex;
    margin-right: 1rem;

    .g-no-wrap {
      flex: 1;
      width: 0;
    }

    &._right {
      //flex-grow: 0;
      //flex-shrink: 0;
      text-align: right;
      justify-content: flex-end;
    }

    &._no-shrink {
      flex: 0 0 auto;
    }
  }
}
