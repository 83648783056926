.list-footer {
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  //&__info {
  //  padding-top: 1px;
  //
  //  & > * {
  //    margin-bottom: 0.4rem;
  //  }
  //}

  &__pagination {
  }
}
