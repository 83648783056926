.statuses-list {
  display: flex;
  flex-wrap: wrap;

  &__list {
    margin: -0.5rem 0 0 -0.5rem;
  }

  &__status {
    margin: 0.5rem 0 0 0.5rem;
  }
}
