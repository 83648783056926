.radio-list {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -1rem 0 0 -2rem;
  }

  &__radio {
    margin: 1rem 0 0 2rem;
  }
}
