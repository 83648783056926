.g-text-left {
  text-align: left !important;
}

.g-text-right {
  text-align: right !important;
}

.g-text-center {
  text-align: center !important;
}
