@media screen and (max-width: @breakpoints[xl]) {
  .persons-page {
    &__persons-list {
      &._short {
        width: 26rem;
      }
    }

    &__one-person {
      width: calc(100% - 26rem);
    }
  }
}

@media screen and (max-width: @breakpoints[lg]) {
  .persons-page {
    &__main {
      padding: 0 !important;
    }
  }
}

@media screen and (max-width: @breakpoints[sm]) {
  .persons-page {
    &__main {
      background: transparent;
    }

    .person-info__header {
      padding-top: 0;
      border-bottom: 0;
    }

    &__persons-list {
      background: transparent;

      &._short {
        display: none;
      }
    }

    &__one-person {
      margin-left: 0;
      width: 100%;
      height: auto;
    }

    &._show-one-person {
      .persons-page__head {
        display: none;
      }
    }

  }
}
