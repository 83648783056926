.document-versions {
  &__list-wrap {
    overflow: hidden;
  }

  &__list {
    display: flex;
    width: 100%;
  }

  &__item {
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: zoom-in;
    border: 1px dashed @c-grey-lighter;
    margin-right: 1rem;
    border-radius: @radius;
    background: white;
    padding: 1rem 0.5rem;

    transition: @time;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      text-decoration: underline;
      background-color: transparent;
    }

    & svg {
      width: 2rem;
      height: 3rem;
      fill: @c-orange;
      margin-bottom: 0.2rem;

      transition: @time;
    }

    &:hover svg {
      fill: @c-orange-light;
    }
  }

  &__item-name {
    text-align: center;
    line-height: 1.2em;
  }
}
