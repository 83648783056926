.commodity-show-info {
  &__documents-table {
    max-width: none;
    //width: calc(100% + 4.1rem);
    margin: 0 -1.5rem;
    background: @c-grey-lightest;

    & td {
      padding: 1rem 1.5rem !important;
    }

    & tfoot td {
      padding: 1rem 1.5rem !important;
      background: @c-grey-lightest;
    }

    & tfoot td > * {
      margin-bottom: 0.4rem;
    }

    & tfoot td > *:last-child {
      margin-bottom: 0;
    }
  }

  &__additional-commodity {
    font-size: 1.2rem;
  }
}
