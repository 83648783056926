table {
  border-collapse: collapse;
  max-width: 100%;
  //background: white;

  font-size: @f-size-small;
  line-height: @f-lineheight-small;
  color: @c-grey-darkest;
  text-align: left;
}

th,
td {
  padding: 1.2rem 1rem;
  border-bottom: 1px solid @c-grey-lighter;

  text-align: inherit;
  vertical-align: middle;

  &:first-child {
    padding-left: 2rem;
  }

  &:last-child {
    padding-right: 2rem;
  }

  &._small-padding {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
}

th {
  background: @c-grey-lighter;
  font-weight: @w-semi-bold;
  cursor: auto;
}

tfoot {
  td {
    font-weight: @w-semi-bold;
  }
}
