.no-selection() {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.wrap() {
  white-space: normal;
  word-break: break-word;
  //hyphens: auto;
  //hyphenate-character: "";
  //hyphenate-limit-chars: 3;
  //hyphens: auto;
}

.no-wrap() {
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  word-break: normal;
  text-overflow: ellipsis;
  min-width: 0;
}

.clip-text-height(@lineHeight, @row) {
  line-height: @lineHeight;
  max-height: calc(@lineHeight * @row);
  overflow: hidden;
  text-overflow: ellipsis ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: @row;
  -webkit-box-orient: vertical;
}

.reset-list() {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reset-button() {
  border-radius: 0;
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  font: inherit;
  text-align: inherit;
  color: inherit;
  text-decoration: none;

  &:not(:disabled):hover {
    text-decoration: unset;
  }
}

.hide-scrollbar() {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.hoverOnly(@rules) {
  @media (hover: hover) and (pointer: fine) {
    @rules();
  }
}
