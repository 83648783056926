.add-estimates {
  &__main {
    padding: 2.4rem 2rem;
  }

  &__forms {
    border-top: 1px dashed @c-grey-lighter;
    padding: 3rem 2rem 2rem;
  }

  &__form {
    width: 100%;
    margin: 0;
    border: 1px solid @c-grey-lighter;

    & > table {
      width: 100%;
      margin: 0;
    }

    & td {
      border-bottom: 0;
    }

    & tbody tr:first-child td {
      padding-top: 2.4rem;
    }

    & td:first-child {
      width: 28%;
    }

    & td:last-child {
      width: 5.4rem;
    }

    & td:nth-last-child(2) {
      width: 16rem;
    }

    & tfoot td {
      padding-bottom: 2.4rem;
    }
  }

  &__form-variant-select {
    width: 30rem;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  &__delete.btn {
    &:hover {
      background-color: @c-red-light;

      & svg {
        fill: white;
      }
    }
  }

  &__note {
    width: 100%;
  }

  &__footer {
    padding: 1rem 2rem 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn-left {
    margin-right: 1rem;
  }

  &__result {
    margin-top: 1.2rem;
    padding: 2.4rem 2rem;
    border-top: 1px solid @c-grey-lighter;
  }

  &__result-table {
    margin: 0;
    width: 50rem;
    max-width: 100%;
    margin-left: auto;

    & td:first-child {
      width: 45%;
    }

    & tfoot td {
      background-color: @c-grey-lightest;
    }
  }

  &__logs {
    padding-top: 1.6rem;
    margin: 2rem 2rem 1.4rem;
    border-top: 1px dashed @c-grey-lighter;
  }
}
