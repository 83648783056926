.single-page {
  display: flex;
  flex-direction: column;
  //position: relative;

  overflow-x: hidden;
  overflow-y: auto;
  margin-left: auto;
  width: calc(100% - 30rem);
  padding: 0 0 1rem;
  flex: 1 1 0;
  min-height: 20rem;

  @media (max-width: @breakpoints[md]) {
    margin-left: 0;
    width: auto;
  }

  &__back-wrap {
    padding: @page-sides-padding-sm;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__add-btn {
    padding: 0 0.4rem;
    width: 3rem;
    min-height: 3rem;

    font-size: 2.4rem;
    line-height: 1em;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 2rem 0;

    @media (max-width: @breakpoints[ml]) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      padding-top: 0;
      &:first-child {
        padding-top: 1.6rem;
      }
    }
  }

  &__head-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__head-group &__back-btn {
    margin-top: -1.5rem;
    margin-bottom: 0.5rem;

    @media (max-width: @breakpoints[ml]) {
      margin-top: 0;
    }
  }

  @media (max-width: @breakpoints[md]) {
    margin-left: 0;
  }

  &__btns-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    position: sticky;
    left: 0;
    z-index: 2;
  }

  &__btns-separator {
    display: block;
    flex: 1;
    //margin: 0 auto;
    //width: 2rem;
    // width: 100%;
  }

  &__btn {
    min-width: 11rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__edit-btn {
    margin-left: auto;
  }

  &__info {
    margin-bottom: 2rem;
  }

  &__info-table {
    margin: 0;

    & td {
      padding: 0 0 0.8rem;
      border: 0;
    }

    & td:first-child {
      padding-right: 1rem;

      color: @c-grey;
    }
  }

  &__tab-block {
    padding: 2rem 2rem 2rem;
    //border-bottom: 1px solid #e0e0e0;

    .customer-reports {
      overflow: visible;
    }

    &._no-offset {
      padding: 0;
    }
  }

  &__back-btn {
    .reset-button;
    font-size: 1.2rem;
    align-items: center;
    font-weight: @w-semi-bold;
    color: @c-grey-darkest;

    svg {
      width: 0.6rem;
      height: 1rem;
      transform: scaleX(-1);
      fill: currentColor;
      margin-right: 0.9rem;
      flex-shrink: 0;
    }
  }

  &__table,
  &__logs-table,
  &__payments-table {
    width: 100%;
    margin: 0;
  }

  &__logs-table {
    th:first-child,
    td:first-child {
      width: 12rem;
    }

    .text {
      ul {
        padding: 0 1.8rem;
      }
    }
  }

  &__table {
    & td._photo {
      width: 6rem;
    }

    & td._photo:first-child {
      width: 7rem;
    }
  }

  &__comments {
    list-style: none;
    margin: 0;
    padding: 0;

    & li {
      margin: 0.6rem 0;

      font-size: 1.2rem;

      &::before {
        content: '•';
        margin-right: 0.8rem;
      }
    }
  }

  &__add-comment {
    margin-top: 1.4rem;
  }

  &__blank-wrap {
    padding: 2rem 2rem 4rem;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
    flex-shrink: 0;

    @media (max-width: @breakpoints[ml]) {
      &:not(:first-child) {
        padding-top: 0;
      }
    }
  }

  &__blank-container {
    position: relative;
    margin: 0 auto;
  }

  &__blank {
    position: relative;

    .ribbon {
      display: block !important;
    }
  }

  &__table-photo-wrap {
    display: block;
    width: 4rem;
    height: 4rem;
    background: @c-grey-lightest;

    line-height: 0;

    &:hover {
      opacity: 0.8;
      cursor: zoom-in;
    }
  }

  &__table-photo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__no-elements {
    text-align: center;
  }

  &__actions {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
  }

  &__drop-menu {
    margin-left: 1rem;

    .dropdown__item {
      white-space: nowrap;
    }
  }

  &__title {
    margin: 0 2rem 0 0;

    font-size: 2.4rem;
  }

  &__files-list {
    display: flex;
    flex-wrap: wrap;
    margin: -20px 0 0 -20px;
  }

  &__file-wrap {
    width: 120px;
    margin: 20px 0 0 20px;
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    cursor: zoom-in;
  }

  &__file {
    width: 100%;
  }

  &__files-empty {
    padding: 1.1rem 2rem;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__not-found {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-content {
      flex: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
    }
  }
}

.modal .single-page {
  overflow: visible;
  width: auto;
  padding: 0;
  flex: 0 1 auto;
  margin: 0;
}
