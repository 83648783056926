.customer-tabs {
  &._height {
    height: auto;
  }

  &__head-wrap {
    position: relative;
    &::after {
      content: '';
      height: 0.2rem;
      background-color: #e7e7e7;
      width: 100%;
      left: 0;
      bottom: 0;
      position: absolute;
    }
  }

  &__head {
    padding: 0 2rem;
    //border-bottom: 0.2rem solid #E7E7E7;
    mask-image: linear-gradient(
      to right,
      fade(black, 0),
      black 2rem,
      black 2rem,
      black calc(100% - 2rem),
      fade(black, 0) 100%
    );
    position: relative;
    z-index: 5;

    overflow-y: hidden;
    overflow-x: scroll;
    overscroll-behavior-x: contain;
    .hide-scrollbar();
  }

  &__head-container {
    display: flex;
    width: 100%;
  }

  &__head-inner {
    display: flex;
    width: 100%;
  }

  &__tab-item {
    margin-right: 1rem;
    display: flex;
    flex-shrink: 0;
    padding-bottom: 0.2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__tab {
    position: relative;
    padding: 0.9rem 1rem;
    text-decoration: none !important;
    font-weight: @w-semi-bold;
    flex-shrink: 0;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 100%;
      height: 0.2rem;
      background: @c-green;
      transform: translateX(-50%) scaleX(0);
      z-index: 5;
      transition: @time;
    }

    &:hover::after,
    &._active::after {
      transform: translateX(-50%) scaleX(1);
    }
  }

  &__plus.btn._small {
    align-self: center;
    //margin-left: -1rem;
    //margin-right: 2rem;

    &:hover {
      background-color: @c-green-light;
      border-color: @c-green-light;
    }

    & svg {
      width: 1rem;
      height: 1rem;
    }

    &:hover svg {
      fill: white;
    }
  }
}
