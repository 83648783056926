@media (max-width: @breakpoints[md]) {
  table {
  }

  th,
  td {
    &:first-child {
      padding-left: @page-sides-padding-sm;
    }

    &:last-child {
      padding-right: @page-sides-padding-sm;
    }
  }

  th {
  }
}
