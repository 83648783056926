.steps {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  counter-reset: counter;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background: @c-blue;
    transform: translateY(-1px);
  }

  &__step {
    position: relative;
    counter-increment: counter;
    border: 0;
    padding: 0;
    background: white;
    display: flex;
    //flex-direction: column;
    align-items: center;
    border-radius: 1.5rem;
    cursor: pointer;
    margin-right: 0.5rem;
    box-shadow: inset 0 0 0 1px @c-grey-lighter;

    transition: @time;

    &:last-child {
      margin-right: 0;
    }

    &._filled {
      background-color: @c-blue;
      //box-shadow: inset 1px 0 0 0 white;
      box-shadow: none;

      color: white;
    }

    &:hover,
    &._active {
      box-shadow: inset 0 0 0 1px @c-blue;
    }

    &::before {
      content: counter(counter);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 1.5rem;
      //padding-bottom: 0.2rem;
      background: @c-grey-lighter;
      box-sizing: border-box;
      //box-shadow: inset 0 0 0 1px white;
      flex-shrink: 0;

      font-size: 1.2rem;
      line-height: 1.6rem;
      //color: white;
      font-weight: @w-semi-bold;

      transition: @time;
    }

    &:hover::before {
      box-shadow: inset 0 0 0 1px @c-blue;
    }

    &._active::before {
      box-shadow: none;
    }

    &._filled::before,
    &._active::before {
      background-color: @c-blue;

      color: white;
    }

    &._filled::before {
      box-shadow: inset 0 0 0 1px white;
    }

    &._active::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: 0.5rem solid @c-blue;
      border-bottom: 0;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }

  &__step-value {
    padding: 0.4rem 1.4rem 0.6rem 0.8rem;

    text-align: left;
    font-size: 1.2rem;
  }
}
