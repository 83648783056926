.root-page {
  @media (max-width: @breakpoints[xl]) {
    &__content-wrap {
      padding-right: 0;
    }
  }

  @media (max-width: @breakpoints[lg]) {
    &__content-wrap {
      padding: 0;
    }

    &__side-nav {
      //display: none;
    }

    &._less &__main,
    &__main {
      width: 100%;
    }
  }

  @media (max-width: @breakpoints[sm]) {
    &__content {
      //background-color: transparent;
    }
  }
}
