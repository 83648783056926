.commodity-info {
  &__customer-row {
    display: flex;
    align-items: center;
  }

  &__vin-wrap {
    display: flex;
    align-items: flex-start;
  }

  &__vin-btn {
    margin-right: 1rem;
  }

  &__vin {
    flex-grow: 1;
  }

  &__other-type-textarea {
    width: 100%;

    & textarea {
      min-height: 8.8rem;
    }
  }

  &__document {
    margin-top: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-top: 1px dashed @c-grey-lighter;
    border-bottom: 1px dashed @c-grey-lighter;
  }

  &__document + &__document {
    border-top: 0;
    margin-top: 0;
  }

  &__documents-table {
    width: 100%;
    margin-bottom: 3rem;
  }

  &__notes {
    list-style: none;
    margin: 0;
    padding: 0;

    & li {
      margin: 0.6rem 0;
      position: relative;
      padding-left: 1rem;

      &::before {
        position: absolute;
        content: '•';
        left: 0;
      }
    }
  }

  & .editr {
    border-color: @c-grey-lighter;
    border-radius: @radius;
    overflow: hidden;
  }

  & .editr--toolbar a svg {
    opacity: 0.7;
    border-bottom: @c-grey-lighter;
  }
}
