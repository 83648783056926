.file-show {
  position: relative;
  line-height: 0;
  cursor: auto;
  width: 100%;
  height: 100%;
  background-color: @c-grey-lightest;

  &:not(._clickable) {
    cursor: pointer;

    & > * {
      pointer-events: none;
    }
  }

  &._rect {
    padding-top: 100%;
  }

  &__file {
    .reset-button();
    color: inherit;
    width: 100%;
    height: 100%;
    object-fit: contain;

    &._placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }

    .file-show__file-ico,
    & > svg {
      width: 13rem;
      height: 16rem;
      z-index: 2;
      object-fit: cover;
    }
  }

  &._rect &__file {
    position: absolute;
    left: 0;
    top: 0;
  }

  & svg,
  &__file-ico {
    width: 4rem;
    height: 4rem;
    fill: @c-grey;
  }

  &._large {
  }

  &__name {
    padding-top: 0.4rem;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    .no-wrap();
    max-width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    z-index: 2;

    svg {
      width: 1.2em !important;
      height: 1.2em !important;
      fill: currentColor !important;
      color: currentColor !important;
      vertical-align: middle;
      margin-top: -0.2em;
    }
  }

  &__type-ico {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @c-pdf;
    text-transform: uppercase;

    svg {
      width: 40%;
      height: 40%;
      fill: currentColor;
      background: white;
      border-radius: 3px;
      opacity: 0.8;
      flex-shrink: 0;
    }

    span {
      font-size: 1.4em;
      line-height: 1.4em;
      font-weight: 600;
      background: white;
      flex-shrink: 0;
      padding: 0.1em;
      border-radius: 3px;
      opacity: 0.8;
    }
  }
}
