.ribbon {
  position: absolute;
  top: -0.5rem;
  z-index: 5;
  left: -0.5rem;
  overflow: hidden;
  width: 9.6rem;
  height: 9.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;

  &__inner {
    text-align: center;
    color: #fff;
    top: 2.4rem;
    left: -3.1rem;
    width: 13.5rem;
    padding: 0.3rem;
    position: relative;
    transform: rotate(-45deg);
    //background-color: #f59d00;
    //border-color: #c27c00;
    background-color: @c-grey;
    border-color: darken(@c-grey, 10);

    &::before,
    &::after {
      content: '';
      border-top: 0.5rem solid transparent;
      border-left: 0.5rem solid;
      border-left-color: inherit;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid;
      border-bottom-color: inherit;
      position: absolute;
      top: 2rem;
      transform: rotate(-45deg);
    }

    &::before {
      border-left: 0.2rem solid transparent;
      left: 0;
    }

    &::after {
      right: -0.2rem;
      border-bottom: 0.3rem solid transparent;
    }

    &._blue {
      background-color: @c-blue;
      border-color: darken(@c-blue, 10);
    }

    &._green {
      background-color: @c-green;
      border-color: darken(@c-green, 10);
    }

    &._yellow {
      background-color: @c-yellow;
      border-color: darken(@c-yellow, 10);
    }
  }
}
