.commodities-list {
  @media screen and (max-width: @breakpoints[xl]) {
    &__customer-photo-wrap {
      display: none;
    }

    &__photo-wrap {
      display: none;
    }

    &__table {
    }
  }

  @media screen and (max-width: @breakpoints[ml]) {
    &__header-row {
      background-color: inherit !important;
      border-bottom: none;
    }

    &__search {
      margin-right: unset;
      background-color: white;
    }

    &__filter {
      margin: unset;
      width: 100%;
    }
  }
}
