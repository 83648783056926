.person-files {
  & .dropdown__btn {
    width: 3.4rem;
    padding: 0.4rem 0.4rem 0 0;
    background: @c-blue;
    border: 0;

    &:hover {
      background-color: @c-blue-light;
    }
  }

  & &__btn-ico {
    width: 0.8rem;
    height: 1.7rem;
    margin: auto !important;
    fill: white;
  }

  &__amount {
    position: absolute;
    z-index: 4;
    top: 0.4rem;
    right: 0.4rem;
    border-radius: 1rem;
    height: 1.5rem;
    min-width: 1.5rem;
    background: white;
    padding: 0.2rem 0.2rem 0;

    font-size: 1.2rem;
    line-height: 1em;
    color: @c-blue;
    text-align: center;
  }

  &__main {
    width: 34rem;
    padding: 1.5rem 2rem;
    max-height: 70vh;
    //min-height: 40rem;
    display: flex;
    flex-direction: column;
  }

  &__list {
    height: 40%;
    flex: 1 1 auto;
    overflow-y: auto;
    margin-right: -2rem;
    padding-right: 2rem;
  }

  &__file {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @c-grey-lighter;
    padding: 1.6rem 0;

    &:first-child {
      padding-top: 0;
    }
  }

  &__file-ico-wrap {
    position: relative;
    width: 3rem;
    height: 4rem;
    margin-right: 1.4rem;
    flex-shrink: 0;

    & svg {
      width: 100%;
      height: 100%;
      fill: @c-grey;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: zoom-in;
    }
  }

  &__file-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: zoom-in;
  }

  &__file-content {
    width: 50%;
    flex-grow: 1;
  }

  &__file-title-wrap {
    display: flex;
    align-items: center;
  }

  &__file-title {
    width: auto;
    margin: 0 0.5rem 0 0;
    .no-wrap();
    font-size: @f-size;
    font-weight: @w-semi-bold;

    & a {
      font: inherit;
      text-decoration: none;
    }
  }

  &__file-title-input {
    width: 50%;
    flex-grow: 1;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  &__file-save,
  &__file-cancel {
    margin-left: 0.5rem;

    & svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &__file-edit {
    .reset-button();
    flex-shrink: 0;
    padding: 0.2rem 0.4rem 0;
    cursor: pointer;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: @c-blue;
    }

    &:hover svg {
      fill: @c-blue-light;
    }
  }

  &__file-weight {
    margin: 0;

    color: @c-grey;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__file-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__file-action {
    .reset-button();
    display: flex;
    margin: 0.6rem 2rem 0 0;
    cursor: pointer;

    text-decoration: none;
    color: @c-green;

    &:last-child {
      margin-right: 0;
    }

    & svg {
      width: 1.4rem;
      height: 1.2rem;
      margin-right: 0.5rem;
      fill: @c-green;
    }

    &._delete {
      color: @c-red;
    }

    &._delete svg {
      fill: @c-red;
    }

    &:hover {
      color: @c-green-light;
      text-decoration: underline;
    }

    &:hover svg {
      fill: @c-green-light;
    }

    &._delete:hover {
      color: @c-red-light;
    }

    &._delete:hover svg {
      fill: @c-red-light;
    }
  }

  &__upload-wrap {
    flex-shrink: 0;

    text-align: center;
  }

  &__upload-note {
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    padding: 2rem;
    cursor: pointer;

    transition: @time;

    & input {
      display: none;
    }

    & svg {
      width: 2.4rem;
      height: 2.4rem;
      opacity: 0.4;
      margin-bottom: 1rem;

      transition: @time;
    }

    &:hover {
      border-color: #cdcccc;
    }

    &:hover svg {
      opacity: 0.6;
    }
  }

  &__error {
    margin-bottom: 1rem;
  }
}
