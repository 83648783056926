.customer-sales {
  @media (max-width: @breakpoints[md]) {
    &__header {
      align-items: center;
      padding: 1.6rem @page-sides-padding-sm 2rem;
      flex-wrap: wrap;
    }

    &__select-wrap {
      width: 100%;
      display: flex;
      align-items: center;

      .select {
        flex-grow: 1;
      }
    }

    &__search {
      width: 100%;
      margin-top: 1rem;
      background-color: white;
    }

    &__select-add {
      min-height: 3rem;
      min-width: 3rem;
      padding: 0;
      margin-left: 3rem;

      font-size: 2rem;
      line-height: 1em;
    }
  }

  @media (max-width: @breakpoints[sm]) {
    height: auto;
    &__content {
      display: block;
      overflow: visible;
      height: auto;
    }
  }
}
