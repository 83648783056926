//.dropdown-enter {
//  opacity: 0;
//  transform: scale(0.8);
//}
//
//.dropdown-leave-active {
//  opacity: 0;
//  transform: scale(0.8);
//}

.dropdown-enter-active,
.dropdown-leave-active {
  //transition: opacity @time-modal, transform @time-modal;
  transition: opacity 0.15s, transform 0.15s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}
