.page-options-menu {
  font-size: 1.2rem;

  & .dropdown__btn-text {
    text-transform: uppercase;
  }

  & .dropdown__dropdown-inner {
    min-width: 15rem;
  }

  & .dropdown__item {
    text-align: left !important;
  }
}
