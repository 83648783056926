@import '~@/assets/css-blanks/blank-base.less';
@size: 12px;
@line-height: @f-lineheight-small;

//html {
//  font-size: 12px;
//}
//
//body {
//  background: white;
//
//  font-size: 12px;
//}
//

* {
  box-sizing: border-box;
  outline: none;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.page-break-inside-avoid {
  page-break-inside: avoid;
  page-break-after: auto;
  page-break-before: auto;
}

table {
  width: 100%;
  margin: 0;
  table-layout: fixed;
  border-collapse: collapse;

  font: inherit;

  & th,
  & td {
    padding: 0;
    border: 0;
    background: transparent;

    font: inherit;
    text-align: left;
    vertical-align: top;
  }
}

.blank-deposit {
  zoom: var(--blank-preview-zoom, 1);
  width: @blank-width;
  min-height: @blank-height;
  margin: 0 auto;
  background: white;
  padding: @blank-side-top @blank-side-right @blank-side-bottom @blank-side-left;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.25);

  &__content {
    //padding: 0 @blank-side-right 0 @blank-side-left;
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-before: auto;

    color: @c-grey-darkest;
    font-size: @size;
    line-height: @line-height;
    font-family: 'Open Sans', system-ui, sans-serif;
    white-space: normal;
    word-break: break-word;
  }

  & &__content {
    padding: 0;
    zoom: @blank-zoom;
  }

  &__head {
    margin-bottom: 15px;
    border-bottom: 1px dashed @c-grey-light;

    & td {
      padding-bottom: 15px;
      vertical-align: top;
    }
  }

  &__logo {
    display: block;
    width: 130px;
    height: 40px;
    //max-height: 40px;
    object-fit: contain;
  }

  &__name,
  &__sub-name {
    margin: 0 0 5px;

    font-size: 22px;
    line-height: 1em;
    text-align: right;
    text-transform: uppercase;
  }

  &__sub-name {
    margin-bottom: 10px;

    font-size: 14px;
  }

  &__number {
    margin: 0;

    text-align: right;
    font-size: 16px;
    line-height: @line-height;
    color: @c-grey;
  }

  &__company-name {
    margin: 0 0 10px;

    font-size: 14px;
    line-height: @line-height;
  }

  &__company-address {
    margin: 0;

    font-size: 12px;
    line-height: @line-height;
    color: @c-grey;
  }

  // - - - -

  &__info {
    padding-top: 30px;
    margin-bottom: 45px;
  }

  &__main-info {
    margin-bottom: 50px;
  }

  &__note {
    margin-top: 30px;

    &-title {
      margin: 0 0 15px;
      font-size: 16px;
      color: @c-grey;
    }
  }

  //&__info-main-grid {
  //  border-collapse: collapse;
  //  padding: 0;
  //  margin: 0;
  //  border: 0;
  //
  //  & > tbody > tr > td {
  //    width: 50%
  //  }
  //}

  &__info-table {
    width: 90%;
    margin: 0;

    font-size: 14px;

    & td {
      padding: 20px 0 2px;
      border-bottom: 1px solid @c-grey-lighter;
    }

    & td:first-child {
      width: 150px;
      border-bottom-color: transparent;

      color: @c-grey;
    }

    & tr:first-child td {
      padding-top: 0;
    }
  }

  &__amount-wrap {
    width: 180px;
    padding: 30px 20px;
    background: @c-green-light;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;

    text-align: center;
    color: white;
  }

  &__amount-title {
    margin: 0 0 10px;

    font-size: 14px;
  }

  &__amount {
    margin: 0;

    font-size: 20px;
    font-weight: @w-semi-bold;
  }

  &__payments-wrap {
    border-top: 1px dashed @c-grey-light;
    padding: 40px 0 20px;
  }

  &__payments-title {
    margin: 0 0 15px;

    font-size: 20px;
  }

  &__payments-table {
    width: 100%;

    font-size: 12px;

    & th {
      background: #dedede;
      border: 0;

      font-weight: @w-semi-bold;
    }

    & th,
    & td {
      padding: 12px 6px;
    }

    & th:first-child,
    & td:first-child {
      padding-left: 12px;
    }

    & th:last-child,
    & td:last-child {
      padding-right: 12px;
    }

    & th {
      &._payment {
        width: 100px;
      }

      &._customer {
        width: 130px;
      }

      &._amount {
        width: 80px;
      }

      &._bank-instr {
        width: 90px;
      }

      &._date {
        width: 90px;
      }

      &._agent {
        width: 120px;
      }

      //& ._note {
      //  width: 100px;
      //}
    }
  }

  &__description {
    border-top: 1px dashed @c-grey-light;
    padding: 40px 0 20px;

    &-title {
      margin: 0 0 15px;
      //color: #808080;
      font-size: 16px;
    }

    &-text {
      font-size: 16px;
    }
  }
}
