@customer-commodities-filter-item-marg: 1rem;

.customer-commodities {
  position: relative;
  background: white;
  height: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.8rem 2rem 1rem;
  }

  &__filter {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
  }

  &__filter-items {
    margin-top: -@customer-commodities-filter-item-marg;
    margin-left: -1rem;
  }

  &__filter-item {
    background: transparent;
    border: 1px solid @c-grey-lighter;
    min-height: 3rem;
    margin: @customer-commodities-filter-item-marg 0 0 1rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    color: @c-grey-darker;
    font-weight: @w-regular;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: none;

    &:hover {
      border-color: @c-green;
      background: transparent;

      color: @c-green;
    }

    &:last-child {
      margin-right: 0;
    }

    &._active {
      background-color: @c-green;
      border-color: @c-green;

      color: white;
    }

    & > span:last-child {
      margin-left: 0.4rem;
    }
  }

  &__add-commodity {
    min-height: 3rem;
    min-width: 3rem;
    padding: 0;
    margin-left: 3rem;

    font-size: 2rem;
    line-height: 1em;
  }

  &__content-wrap {
    height: 20rem;
    flex-grow: 1;
    display: flex;
    //flex-direction: column;

    //overflow-y: scroll;
    //overflow-x: hidden;
    overflow-y: auto;
    overflow-x: auto;
  }

  &__table-wrap {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__scroll-block {
    //height: 20rem;
    flex-grow: 1;
    //overflow-y: scroll;
    //overflow-x: hidden;
  }

  &__table {
    //width: 100%;
    margin: 0;

    display: block;

    thead,
    tbody {
      display: block;
    }

    .hide-particle__placeholder {
      &:after,
      &:before {
        background: transparent !important;
      }
    }

    & tr {
      display: flex;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }
    }

    & th,
    & td {
      flex-shrink: 0;
    }

    & th {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & th:first-child,
    & td:first-child {
      flex-grow: 1;
      width: 22rem;
    }

    & th:nth-child(2),
    & td:nth-child(2) {
      width: 18rem;

      & .search-table-col {
        width: 18rem - 2rem;
      }
    }

    & th:nth-child(3),
    & td:nth-child(3) {
      width: 22rem;

      & .search-table-col {
        width: 22rem - 2rem;
      }
    }

    & th._status-location,
    & td._status-location {
      width: 22rem;

      & .search-table-col {
        width: 22rem - 2rem;
      }
    }

    & th._actions,
    & td._actions {
      width: 8.4rem;

      text-align: right;

      & .search-table-col {
        width: 8.4rem - 2rem;
      }
    }
  }

  &__scroll-block &__table {
    & th._actions,
    & td._actions {
      width: 8.4rem;
    }
  }

  & a {
    text-decoration: none;
  }

  &__help-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 16rem;

    & li {
      margin: 0.6rem 0;
    }
  }

  //descr

  &__status {
    margin-bottom: 0.4rem;
  }

  &__cel-row-wrap {
    border-bottom: 1px dashed @c-grey-lighter;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &__cel-row {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem 0 0.5rem -0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__cel-row &__cel-row-item {
    margin: 0.5rem 0 0 0.5rem;
  }
}
