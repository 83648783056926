.notes-btn {
  position: relative;
  overflow: visible;
  margin-bottom: 1rem;

  & svg {
    margin-right: 0 !important;
  }

  &__quantity {
    position: absolute;
    top: -0.9rem;
    right: -0.9rem;
    background: @c-blue;
    min-width: 1.8rem;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;

    text-align: center;
    color: white;
    padding: 0 0.4rem;
    white-space: nowrap;
  }
}
