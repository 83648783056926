.shipping-modal {
  min-height: 15rem;

  &__select {
    margin-bottom: 2rem;
  }

  &__content {
    padding: 10px 0;
    margin-bottom: 2rem;
  }

  &__details-title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__details {
    width: 100%;

    & td:first-child {
      font-weight: @w-semi-bold;
      width: 35%;
    }
  }

  &__row {
    margin-bottom: 10px;
  }

  &__label {
    display: inline-block;
    width: 50%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__button-cancel {
    margin-right: 1.5rem;
  }
}
