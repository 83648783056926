.file-commodity {
  @media (max-width: @breakpoints[ml]) {
    display: block;
    min-width: auto;
    max-width: none;

    &__card {
    }
  }
}
