.logo {
  display: inline-block;
  height: 3rem;
  max-height: 100%;

  line-height: 0;
  text-transform: none;

  &__img {
    height: 100%;
  }
}
