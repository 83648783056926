.customer-reports {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  &__head {
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    display: flex;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    gap: 1.5rem 2rem;
  }

  &__category-select {
    width: 30rem;
  }

  &__dates-select {
    width: 20rem;

    .select__option._disabled {
      display: none;
    }
  }

  &__dates-range {
    width: 25rem;
  }

  &__actions {
    margin-left: auto;
  }

  &__main-content {
    padding: 2rem 2rem 4rem;
    overflow-x: auto;
    overflow-y: hidden;
    flex-shrink: 0;
  }

  &__default {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    &-title {
      color: @c-grey;
    }
  }

  @media (max-width: @breakpoints[lg]) {
    &__head {
      flex-wrap: wrap;
    }

    &__category-select {
      flex: 1 1 100%;
    }
  }
  @media (max-width: @breakpoints[md]) {
    &__dates-select {
      flex: 1 1 100%;
    }

    &__dates-range {
      flex: 1;
    }
  }
}
