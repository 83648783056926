.new-expense-page {
  &__main-content {
    position: relative;
    min-height: 8rem;
  }

  &__form {
  }

  &__form-section {
    padding: 3rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__row {
    margin-bottom: 3rem;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__scan {
    display: flex;
    align-items: center;
  }

  &__scan-title {
    font-size: 1.4rem;
    margin: 0 0 1rem;
  }

  &__scanned-file {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 8rem;
    height: 8rem;
    border-radius: @radius;
    border: 1px solid @c-grey-lighter;
    overflow: hidden;
    margin-right: 2rem;

    &-delete {
      position: absolute;
      z-index: 5;
      top: 0.2rem;
      right: 0.2rem;
      width: 2rem;
      height: 2rem;
      border: 1px solid white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.8;
      padding: 0;
      background: @c-grey-light;
      cursor: pointer;

      transition: @time;

      & svg {
        fill: white;
      }

      &:hover {
        opacity: 1;
        border-color: @c-red-light;
        background-color: @c-red-light;
      }
    }
  }

  &__select-wrap {
    display: flex;
    align-items: center;
  }

  &__light-btn {
    padding: 0;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    margin-right: 2.2rem;
    background: transparent;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-semi-bold;
    text-transform: uppercase;

    &:disabled {
      cursor: not-allowed;
    }

    &:hover {
      text-decoration: none;

      color: @c-blue;
    }
  }

  &__check {
  }

  &__result-info-wrap {
    padding: 0 2rem;
  }

  &__result-info {
    margin-top: 3rem;
    margin-bottom: 2rem;
    margin-left: auto;
    width: 30rem;
  }

  &__footer {
    padding: 3rem 2rem;
    border-top: 1px solid @c-grey-lighter;
  }

  &__logs {
    margin-bottom: 2.4rem;
  }

  &__footer-main {
    display: flex;
    justify-content: space-between;
  }

  &__footer-btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__add-bills {
    position: relative;
    margin-right: 3rem;
    //background-color: @c-yellow;

    &-label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    &-item {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__uploading-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  p {
    margin: 0;
  }

  &__print-checkbox {
    width: 30rem;
    .text {
      margin-top: 1rem;
    }
  }

  &__total:first-child &__total-value {
    font-size: 1.2em;
    line-height: 1em;
    font-weight: 600;
  }
}
