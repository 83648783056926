.commodity-list-table-need-to-be-dispatch {
  & th:first-child,
  & td:first-child {
    width: 17rem;
  }

  & th._origin-destination,
  & td._origin-destination {
    width: 20rem;
  }

  & th._trailer-type,
  & td._trailer-type {
    width: 14rem;
  }

  & th:nth-child(6),
  & td:nth-child(6) {
    width: 12rem;
  }

  & th:nth-child(7),
  & td:nth-child(7) {
    width: 6rem;
  }
}
