.one-setting-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &__header {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: @breakpoints[ml]) {
      padding-top: 1rem;
    }
    @media (max-width: @breakpoints[sm]) {
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
    }
  }

  &__title {
    margin: 0;

    font-size: 2rem;
    color: @c-grey;
  }

  &__add-btn {
    margin-left: 2rem;
    flex-shrink: 0;

    & svg {
      width: 1rem;
    }

    @media (max-width: @breakpoints[ml]) {
      padding: 0 1.1rem;
      svg {
        margin: 0;
      }
      span {
        display: none;
      }
    }
  }

  &__select-referrer {
    margin-bottom: 2rem;

    //& .select__container {
    //  border-color: @c-green;
    //}
  }

  &__note {
    margin-bottom: 1rem;

    font-size: 1.2rem;

    &._accent {
      color: @c-green;
    }
  }

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 2rem;
    @media (max-width: @breakpoints[sm]) {
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
    }
  }

  &__alert {
    margin-bottom: 2rem;
  }

  &__table-wrap {
    flex-grow: 1;
    position: relative;
    //min-height: 20rem;
  }

  &__table {
    width: 100%;
    margin: 0;
    border: 1px solid @c-grey-lighter;
    border-top: 0;

    & td._actions {
      width: 5.4rem;
    }

    &._items {
      & td:first-child {
        width: 14rem;
      }

      & td:nth-child(2) {
        width: 9rem;
      }
    }
  }

  &__table-mark {
    margin-top: 0.4rem;
    fill: @c-green;

    &._not {
      fill: @c-grey-light;
    }
  }

  &__img-wrap {
    display: block;
    width: 4rem;
    height: 4rem;
    background: @c-grey-lightest;

    line-height: 0;

    a&:hover {
      opacity: 0.8;
      cursor: zoom-in;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__actions-wrap {
    display: flex;
  }

  &__see {
    margin-right: 0.5rem;
  }

  &__no-content {
    text-align: center;
  }
}
