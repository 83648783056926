.time-kiosk-page {
  &__content {
    padding: 3rem 2rem;
    flex-grow: 1;
  }

  &__inline-preloader {
    margin: 2rem 0;
    display: flex;
    align-items: center;

    color: @c-grey;

    & .preloader {
      margin-right: 1rem;
    }
  }

  &__alert {
    margin-bottom: 2rem;
  }

  &__note {
    color: @c-grey;
  }

  &__btn {
  }

  &__actions {
    display: flex;
    grid-gap: 1rem;
    margin-bottom: 4rem;
    flex-wrap: wrap;
  }

  &__tables {
    display: flex;
    grid-gap: 5rem;
    flex-wrap: wrap;
  }

  &__table-item {
    width: 48rem;
    max-width: 100%;
  }

  &__table-title {
    margin: 0 0 2rem;
  }

  &__table {
    width: 100%;
    //margin-bottom: 3rem;
    table-layout: fixed;

    & td:first-child {
      font-weight: @w-bold;
      color: @c-grey;
    }

    & td:last-child {
      text-align: right;
    }
  }
}
