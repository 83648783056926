.email {
  font-size: 1.2rem;

  &__subject {
    margin: 0 0 1.2rem;

    font-size: 1.8rem;
  }

  &__group {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__group-title {
    margin: 0 0.6rem 0 0;

    font-size: 1.2rem;
    line-height: @f-lineheight-small;
    font-weight: @w-bold;
    text-transform: none;
  }

  &__group-content {
    line-height: @f-lineheight;
  }

  &__message,
  &__files {
    margin-top: 2rem;
  }

  &__files-title,
  &__message-title {
    margin: 0 0 1rem;

    font-size: 1.4rem;
    text-transform: none;
  }

  &__message-content {
  }

  &__files-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
  }

  &__file {
    display: inline-flex;
    align-items: center;
    padding: 0.4rem 1rem;
    border-radius: 1.5rem;
    border: 1px solid @c-grey-lighter;
    background: @c-grey-lightest;
    max-width: 12rem;
    margin: 0.5rem 0 0 0.5rem;
    text-decoration: none;
    .no-wrap();

    & svg {
      width: 1.2rem;
      height: 1.4rem;
      margin-right: 0.6rem;
      fill: @c-grey-light;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  &__agent,
  &__time {
    display: flex;
    align-items: center;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.6rem;
      fill: @c-grey;
    }
  }
}
