.chat-preview {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: @radius;
  background: @c-grey-lightest;
  border: 1px solid @c-grey-lighter;
  height: 4.8rem;
  padding: 0.4rem 4rem 0.4rem 0.5rem;
  cursor: pointer;

  transition: border-color @time;

  &:hover {
    border-color: @c-grey-light;
  }

  &__ico {
    width: 3.1rem;
    height: 3.1rem;
    fill: @c-grey-light;
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  &__main {
    width: 50%;
    flex-grow: 1;
  }

  &__time {
    color: @c-grey;
  }

  &__message {
    .no-wrap();
    width: 100%;
  }

  &__unread-messages-quantity {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    min-width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 0.2rem;
    border-radius: 1.1rem;
    background: @c-blue;

    color: white;
    font-size: 1.2rem;
    line-height: 1.2em;
    font-weight: @w-bold;
  }
}
