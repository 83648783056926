.company-profile-page {
  padding: 2rem;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2rem;
  }

  &__item {
    margin-bottom: 2rem;
    background-color: @c-grey-lightest;
    padding: 2rem;
    border-radius: @radius;
  }

  &__main-info-row {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
  }

  &__logo {
    width: 10rem;
    position: relative;
    flex-shrink: 0;
    margin-right: 2rem;

    &::before {
      content: '';
      width: 100%;
      display: block;
      padding-top: 30%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__logo-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px dashed @c-grey-lighter;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: @c-grey-lighter;
    }
  }

  &__name {
    margin: 0;
    font-size: @f-size;
    line-height: @f-lineheight;
    flex: 1;
  }

  &__active {
    margin-right: 1rem;
    flex-shrink: 0;
  }
}
