.persons-list {
  position: relative;
  background: white;

  &__header {
    position: sticky;
    z-index: 32;
    top: @header-height;
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
  }

  &._no-offset &__header,
  &._short &__header {
    top: 0;
  }

  &._short &__table {
    table-layout: fixed;
  }

  &._no-offset &__table thead {
    top: 5.5rem;
  }

  &__drop-menu {
    margin-right: 1rem;
  }

  &__drop-menu .dropdown__dropdown-inner {
    width: 15rem;
  }

  &__search {
    width: 21.8rem;
    margin-right: 1rem;
  }

  &__search-toggle {
    background: transparent;
    margin-right: 1rem;
    border: 1px solid @c-grey-lighter;

    & svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: fade(black, 65%);
    }

    &._open,
    &:hover {
      background-color: @c-green-light;
      border-color: @c-green-light;
    }

    &._open svg,
    &:hover svg {
      fill: white;
    }
  }

  &__main-search-collapse {
    position: sticky;
    top: 5.5rem;
    z-index: 2;
    background: white;
  }

  &__main-search-short-wrap {
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__main-search-short {
    width: 100%;
  }

  &__sort {
    max-width: 60%;
    width: 18rem;
    margin-left: auto;
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__head-btn {
    margin-right: 1rem;
  }

  // - - - -

  &__table-wrap {
    position: relative;
  }

  &__table {
    width: 100%;
    margin: 0;

    & thead {
      position: sticky;
      top: @header-height + 5.5rem;
      z-index: 2;

      &._no-sticky {
        position: static;
      }
    }

    & th {
      background: @c-grey-lightest;
    }

    & td._checkbox {
      width: 4.8rem;
    }

    & td a {
      //text-decoration: none;
    }

    & td._actions {
      width: 5.4rem;
    }
  }

  //&._short &__table td:first-child {
  //  vertical-align: top;
  //}

  //&._short &__table tr {
  //  min-height: 8.6rem;
  //}

  &__person {
    cursor: pointer;

    transition: background-color @time;

    &:hover {
      background-color: @c-grey-lightest;
    }
  }

  &._short &__person {
    //background-color: @c-grey-lightest;
  }

  &._short &__person._selected {
    background-color: @c-blue-2;
  }

  &__person-profile {
    display: flex;
    align-items: center;
  }

  &__photo-wrap {
    background: @c-grey-light-2;
    border-radius: 50%;
    overflow: hidden;
    width: 4.5rem;
    height: 4.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 1.5rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__photo-placeholder {
    opacity: 0.5;

    font-size: 1.6rem;
    font-weight: @w-bold;
    text-transform: uppercase;
  }

  &__profile-info {
    width: 50%;
    flex-grow: 1;
  }

  &._short {
    border-right: 1px solid @c-grey-lighter;
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }
}
