.inventor-list {
  &__header {
    position: sticky;
    z-index: 32;
    top: @header-height;
    background: white;
    padding: 2rem;
    display: flex;
    align-items: center;
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__filter {
    display: flex;
    margin-top: -1rem;
    margin-left: auto;
  }

  &__filter-item {
    background: transparent;
    border: 1px solid @c-grey-lighter;
    min-height: 3rem;
    margin: @filing-list-filter-item-marg 1.5rem 0 0;

    color: @c-grey-darker;
    font-weight: @w-regular;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: none;

    &:hover {
      border-color: @c-green;
      background: transparent;

      color: @c-green;
    }

    &:last-child {
      margin-right: 0;
    }

    &._active-exact {
      background-color: @c-green;
      border-color: @c-green;

      color: white;
    }
  }

  &__table-wrap {
  }

  &__table {
    width: 100%;
    margin: 0;

    thead {
      position: sticky;
      //top: 10.5rem;
      top: 12.4rem;
      z-index: 32;
    }

    & th._price {
      width: 10rem;
    }

    & th._notify {
      width: 4.4rem;
    }

    & td:first-child {
      width: 5rem;
    }

    & td:nth-child(2) {
      width: 7.5rem;
      padding-right: 0;
    }

    & td:last-child {
      width: 4.4rem;
    }

    & td._vendor {
      border-left: 1px dashed @c-grey-lighter;
    }

    & td:nth-last-child(-n + 3) {
      border-bottom: 0;
    }

    & td {
      border-bottom: 1px dashed @c-grey-lighter;
    }

    &._used td {
      border-bottom: 0;
    }

    & tr:last-child td {
      border-bottom: 1px solid @c-grey-lighter;
    }
  }

  &__photo-wrap {
    position: relative;
    background: @c-grey-light-2;
    overflow: hidden;
    width: 6.5rem;
    height: 4.8rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__light-btn {
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-semi-bold;
    text-transform: uppercase;
    outline: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    background: none;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;

      color: @c-blue;
    }
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }
}
