@side-nav-min-width: @side-nav-width - @page-sides-padding;
@link-icon-width: 2rem;
@link-icon-margin: 1rem;
@link-padding-left: 1.5rem;
@link-padding-right: 5rem;
.side-nav {
  position: fixed;
  z-index: 82;
  left: 0;
  top: @header-height;
  width: @side-nav-width;
  height: calc(100vh - @header-height);
  @supports (height: 100dvh) {
    height: calc(100dvh - @header-height);
  }
  background: white;
  box-shadow: 0 0.6rem 0.8rem 0 rgba(140, 140, 140, 0.5);
  display: flex;
  flex-direction: column;

  transition: width @time-modal;

  &._is-modal {
    position: static;
    z-index: auto;
    width: 100%;
    height: auto;

    .side-nav {
      &__content {
        padding-left: 0;
      }

      &__link {
        &::before,
        &::after {
          border-radius: 0;
        }
      }

      &__list-wrap {
        height: auto;
        overflow: hidden;
      }
    }

    .sublist,
    .sublist::after {
      border-radius: 0;
    }
  }

  &._less {
    width: 6rem;
  }

  &__content {
    width: 100%;
    height: 50%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-left: @page-sides-padding;

    transition: padding-left @time-modal;
  }

  &._less &__content {
    padding-left: 0;
  }

  &__toggle {
    flex-shrink: 0;
    width: 100%;
    height: 4rem;
    background: @c-grey-lightest;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 7.4rem;
    cursor: pointer;

    text-decoration: none !important;

    transition: padding-right @time-modal;

    .no-selection();

    & svg {
      width: 1rem;
      height: 1.8rem;
      opacity: 0.3;
      transform: rotate(180deg);

      transition: opacity @time, transform @time-modal;
    }

    .hoverOnly({
      &:hover svg {
        opacity: 0.5;
      }
    });
  }

  &._less &__toggle {
    padding-right: 2.1rem;

    & svg {
      transform: rotate(180deg) rotateY(180deg);
    }
  }

  &__logo {
    margin: 1.5rem 2rem;
    flex-shrink: 0;
  }

  &__list-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 1;
    flex-grow: 1;
    height: 50%;
    padding: 2rem 0;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    position: relative;
    width: 100%;
    border-bottom: 1px solid @c-grey-lightest;

    //&:last-child {
    //  border-bottom: 1px solid @c-grey-lightest;
    //}

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 0.2rem;
      background: transparent;

      transition: @time;
    }

    &._open::before {
      background: white;
    }
  }

  &__link {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0.6rem @link-padding-right 0.8rem @link-padding-left;
    min-height: 4.8rem;
    cursor: pointer;

    color: @c-grey;
    text-decoration: none !important;

    transition: @time;
    font-size: @f-size;
    line-height: @f-lineheight;
    font-weight: inherit;

    & > * {
      position: relative;
      z-index: 4;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: -1px;
      right: 0;
      width: 0;
      height: calc(100% + 0.2rem);
      background: transparent;
      border-radius: 2rem 0 0 2rem;

      transition: 0.3s;
    }

    &::before {
      width: 100%;
      border-radius: 0;
    }

    .hoverOnly({
      &:hover {
        color: if((@IS_CONSTRUCTION = true),
        @primary-main-construction,
        @c-red);
        text-decoration: none;
      }

      &:hover::after {
        background-color: @c-grey-lightest;
        width: 100%;
      }
    });

    &._active {
      color: white;
    }

    &._active::after {
      background-color: if(
        (@IS_CONSTRUCTION = true),
        @primary-main-construction,
        @c-red
      );
      width: 100%;
    }
  }

  &._less &__link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    justify-content: center;
  }

  &__item._open &__link::before,
  &__link._active::before {
    background: white;
  }

  .hoverOnly({
    &__link:hover::before {
      background: white;
    }
  });

  &__item._open &__link::after {
    border-bottom-left-radius: 0;
    background-color: @c-grey-lightest;
    width: 100%;
  }

  &__item &__link._active::after {
    background-color: if(
      (@IS_CONSTRUCTION = true),
      @primary-main-construction,
      @c-red
    );
  }

  &__link-content {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;

    transition: width @time-modal;
  }

  &._less &__link-content {
    width: 2rem;
  }

  &__link-icon {
    width: @link-icon-width;
    height: 2rem;
    margin-right: @link-icon-margin;
    flex-shrink: 0;
    fill: fade(@c-grey, 80);

    transition: fill @time;
  }

  .hoverOnly({
    &__link:hover &__link-icon {
      fill: if((@IS_CONSTRUCTION = true), @primary-main-construction, @c-red);
    }
  });

  &__link._active &__link-icon {
    fill: white;
  }

  &__plus,
  &__show-more {
    position: absolute;
    top: 1.3rem;
    left: @side-nav-min-width - @link-padding-right + 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 2.2rem;
    height: 2.2rem;
    border: 1px solid fade(@c-grey, 50%);
    border-radius: 50%;
    cursor: pointer;

    color: fade(@c-grey, 50%);
    font-size: 1.7rem;
    text-decoration: none !important;

    transition: @time;
    z-index: 5;

    &::before {
      content: '';
      position: absolute;
      inset: -0.6rem;
    }
  }

  &._is-modal {
    .side-nav {
      &__plus,
      &__show-more {
        left: auto;
        right: 1.5rem;
      }
    }
  }

  &__plus._active {
    color: if(
      (@IS_CONSTRUCTION = true),
      @primary-main-construction,
      @c-red
    ) !important;
    border-color: if(
      (@IS_CONSTRUCTION = true),
      @primary-main-construction,
      @c-red
    ) !important;
    background: white;
  }

  .hoverOnly({
    &__plus:hover {
      color: if((@IS_CONSTRUCTION = true), @primary-main-construction, @c-red) !important;
      border-color: if((@IS_CONSTRUCTION = true),
      @primary-main-construction,
      @c-red) !important;
      background: white;
    }
  });

  &__show-more {
    border: 0;
    pointer-events: none;
  }

  &._less &__plus,
  &._less &__show-more {
    opacity: 0;
    pointer-events: none;
  }

  &__show-more svg {
    position: relative;
    left: -1px;
    width: 0.6rem;
    height: 0.8rem;
    transform: rotate(90deg);
    fill: fade(black, 50%);

    transition: @time;
  }

  .hoverOnly({
    &__link:hover ~ &__show-more svg {
      fill: if((@IS_CONSTRUCTION = true), @primary-main-construction, @c-red);
    }
  });

  &__item._open &__show-more {
    border-color: if(
      (@IS_CONSTRUCTION = true),
      @primary-main-construction,
      @c-red
    );
  }

  &__item._open &__show-more svg {
    transform: rotate(270deg);
  }

  &__link._active ~ &__plus,
  &__link._active ~ &__show-more {
    color: white;
    border-color: white;
  }

  &__link._active ~ &__plus svg,
  &__link._active ~ &__show-more svg {
    fill: white;
  }

  &__link-name {
    min-width: @side-nav-min-width - @link-icon-width - @link-icon-margin -
      @link-padding-left - @link-padding-right;
    //.no-wrap();
  }

  &._less &__link-name {
    //max-width: 0;
  }
}

.sublist {
  position: relative;
  z-index: 4;
  margin: 0;
  padding: 1rem 0;
  list-style: none;
  background: #fafafa;
  border-radius: 0 0 0 2rem;
  overflow: hidden;
  min-width: @side-nav-min-width;

  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    right: -1rem;
    bottom: 0;
    top: -1rem;
    box-shadow: inset 0.2rem 0.3rem 0.8rem rgba(0, 0, 0, 0.25);
    border-radius: 0 0 0 2rem;
  }

  &__item {
    position: relative;
    z-index: 3;
    min-height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.3rem 0 1.5rem;
  }

  &__link {
    padding: 0.4rem 0.4rem 0.6rem 0;
    font-size: 1.2rem;
    text-decoration: none;

    &._active {
      color: if((@IS_CONSTRUCTION = true), @primary-main-construction, @c-red);
    }

    .hoverOnly({
      &:hover {
        text-decoration: underline;
      }
    });
  }

  &__link-content {
    flex-grow: 1;
  }

  &__plus {
    position: relative;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 0.2rem;
    border: 1px solid transparent;
    border-radius: 50%;
    cursor: pointer;

    color: fade(@c-grey, 50%);
    border: 1px solid fade(@c-grey, 50%);

    font-size: 1.7rem;
    text-decoration: none;

    transition: @time;

    &._active {
      color: if(
        (@IS_CONSTRUCTION = true),
        @primary-main-construction,
        @c-red
      ) !important;
      border-color: if(
        (@IS_CONSTRUCTION = true),
        @primary-main-construction,
        @c-red
      ) !important;

      text-decoration: none;
      background: white;
      //color: if((@IS_CONSTRUCTION = true), @primary-main-construction, @c-red);
    }

    &::before {
      content: '';
      position: absolute;
      inset: -0.6rem;
    }

    .hoverOnly({
      &:hover {
        color: if((@IS_CONSTRUCTION = true),
        @primary-main-construction,
        @c-red) !important;
        border-color: if((@IS_CONSTRUCTION = true),
        @primary-main-construction,
        @c-red) !important;

        text-decoration: none;
        background: white;
      }
    });
  }
}
