.auth-form {
  position: relative;
  width: 40rem;
  max-width: 100%;
  padding: 3rem 2rem;
  border: 1px solid @c-grey-lighter;
  border-radius: @radius;
  background: white;

  &__input {
    margin-bottom: 2rem;
  }

  &__error {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    font-size: 2rem;
    margin: 0 0 2rem;
  }
}
