.files-page {
  &__main,
  &__main-content {
    position: relative;
    //height: 28rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    //flex-direction: column;
  }

  &__files-list {
    min-width: 29rem;
    flex: 1 1 29rem;
    border-right: 1px solid @c-grey-lighter;
  }

  .files-list__table {
    thead {
      position: sticky;
      top: 10.5rem;
      z-index: 32;
    }
  }

  &__files-list._short,
  &__one-file {
    height: 45rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__files-list._short {
    width: 30rem;
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
    height: 100%;
  }

  &__one-file {
    margin-left: auto;
    width: calc(100% - 30rem);
    height: 45rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
