.upload-files {
  &._disabled &__btn {
    opacity: 0.5;
    pointer-events: none;
  }
  &__supported {
    font-size: @f-size-small;
    color: @c-grey;
    margin-top: 0.5rem;
  }

  &__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: -1rem 0 0 -1rem;
  }

  &._centered &__list {
    justify-content: center;
  }

  &._large &__list {
    margin: -2rem 0 0 -2rem;
  }

  &__item,
  &__btn {
    margin: 1rem 0 0 1rem;
  }

  &__item {
    flex: 0;
    &:first-child:last-child {
      flex: 0 1 auto;
    }

    &._error &-content {
      border-color: @c-red;
    }

    .preloader {
      z-index: 4;
    }

    &-progress {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.6rem;
      z-index: 6;

      &-inner {
        width: 0;
        height: 100%;
        transition: width @time;
        background-color: @c-green;
      }
    }

    &._loading {
      .upload-files__checkbox,
      .upload-files__name,
      .upload-files__name-edit {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &__item-content,
  &__btn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 8rem;
    height: 8rem;
    border-radius: @radius;
    border: 1px solid @c-grey-lighter;
    overflow: hidden;
  }

  &._large &__item {
    //width: 12rem;
  }

  &._large &__item-content,
  &._large &__btn {
    width: 12rem;
    height: 12rem;
  }

  &._large &__item,
  &._large &__btn {
    margin: 2rem 0 0 2rem;
  }

  &__item-content {
  }

  &__img {
    width: 100%;
    height: 100%;
    cursor: zoom-in;
  }

  & input {
    display: none;
  }

  &__file-placeholder {
    //position: absolute;
    //left: 50%;
    //top: 50%;
    width: 3.4rem;
    height: 3.4rem;
    //transform: translate(-50%, -50%);
    opacity: 0.4;
    margin-top: 0.3rem;
  }

  &__file-name {
    margin: 0.6rem auto 0;
    width: calc(100% - 1rem);
    .no-wrap();

    font-size: 1rem;
    font-weight: @w-regular;
    text-align: center;
    text-transform: none;
    min-width: 12rem;
  }

  &__btn {
    cursor: pointer;
    padding: 0.4rem 0.2rem 0.2rem;

    text-align: center;
    font-size: 1rem;
    line-height: @f-lineheight-small;
    color: @c-grey;

    transition: @time;

    &:hover {
      border-color: @c-blue;

      color: @c-blue;
    }

    & svg {
      width: 1rem;
      height: 1.6rem;
      margin-bottom: 0.4rem;
      fill: @c-grey;
    }

    &:hover svg {
      fill: @c-blue;
    }

    &._drop-area {
      flex: 1 1 100%;
    }
  }

  &__delete {
    position: absolute;
    z-index: 5;
    top: 0.2rem;
    right: 0.2rem;
    width: 2rem;
    height: 2rem;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    padding: 0;
    background: @c-grey-light;
    cursor: pointer;

    transition: @time;

    & svg {
      fill: white;
    }

    &:hover {
      opacity: 1;
      border-color: @c-red-light;
      background-color: @c-red-light;
    }
  }

  &__checkbox {
    position: absolute;
    z-index: 2;
    top: 0.2rem;
    left: 0.2rem;
    opacity: 0.8;
    transition: opacity @time;

    &._checked {
      opacity: 1;
    }

    & .checkbox__mark {
      border-color: white;
      background: fade(@c-grey-darker, 20);
    }
  }

  &__name {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 0.4rem;
    width: 100%;

    font-size: 1.2rem;
    color: @c-grey-darkest;
    .wrap();
    transition: opacity @time;
    min-width: 12rem;
  }

  &__name-edit-btn {
    .reset-button();
    cursor: pointer;
    width: 1.8rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.4rem;

    & svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: @c-grey-dark;

      transition: @time;
    }

    &:hover svg {
      fill: @c-blue;
    }

    &._accept svg {
      width: 1.2rem;
    }

    &._accept:hover svg {
      fill: @c-green;
    }

    &._cancel svg {
      width: 1rem;
    }

    &._cancel:hover svg {
      fill: @c-red;
    }
  }

  &__name-edit {
    margin-top: 0.4rem;
    display: flex;
    align-items: flex-start;
    transition: opacity @time;
    min-width: 12rem;
    flex: 1;
  }

  &__name-edit-input {
    width: 50%;
    flex-grow: 1;
    padding: 0.5rem;
  }
}
