.link {
  .reset-button();
  align-items: center;
  position: relative;
  text-decoration: underline;
  cursor: pointer;
  transition: color @time;

  &:not(:disabled):hover {
    text-decoration: none;
    color: @c-blue;
  }

  &._dashed {
    text-decoration-style: dashed;
  }

  & svg {
    position: relative;
    fill: currentColor;
    //stroke: currentColor;
    flex-shrink: 0;
    margin-right: 0.8rem;
    margin-left: -1px;
    width: 2rem;
    height: 2rem;

    //transition: @time;

    &:last-child {
      margin-left: 0.8rem;
      margin-right: -1px;
    }

    &:last-child:first-child {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &._primary {
    color: @c-primary-text;
  }
  &._green {
    color: @c-green;
  }

  &._blue {
    color: @c-blue;
  }

  &:disabled {
    cursor: auto;
    opacity: 0.6;
    color: @c-grey;
    //text-decoration: none;
  }
}
