@filling-list-content-height: 4.6rem;
@filing-list-filter-item-marg: 1rem;

.files-list {
  .notes-btn {
    margin-bottom: 0;
  }

  ._no-link {
    cursor: auto;
  }

  &__header-row {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;

    border-bottom: 1px solid @c-grey-lighter;

    &._sticky {
      position: sticky;
      z-index: 32;
      top: @header-height;
      background-color: white;
    }
  }

  &._short &__header-row._sticky {
    top: 0;
  }

  &__header-content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__search._full-width {
    width: 100%;
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
    margin-right: 2rem;
    margin-left: 1rem;
  }

  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__drop-menu {
    margin-right: 1rem;
  }

  &__drop-menu .dropdown__dropdown-inner {
    width: 15rem;
  }

  &__filter {
    //display: flex;
    margin-top: -@filing-list-filter-item-marg;
    margin-left: -@filing-list-filter-item-marg;
  }

  &__filter-item {
    background: transparent;
    border: 1px solid @c-grey-lighter;
    min-height: 3rem;
    margin: @filing-list-filter-item-marg 0 0 @filing-list-filter-item-marg;

    color: @c-grey-darker;
    font-weight: @w-regular;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: none;

    &:hover {
      border-color: @c-green;
      background: transparent;

      color: @c-green;
    }

    &._active {
      background-color: @c-green;
      border-color: @c-green;

      color: white;
    }
  }

  &__table-wrap {
  }

  &__table {
    width: 100%;
    margin: 0;
    table-layout: fixed;

    & td {
      vertical-align: top;
    }

    & th._check,
    & td._check {
      width: 4.8rem;
    }

    & th:last-child,
    & td:last-child {
      width: 9rem;
    }

    //& tr:nth-child(even) td {
    //  background-color: @c-grey-lightest;
    //}
  }

  &__table-row {
    cursor: pointer;
  }

  &__table-row td {
    transition: background-color @time;
  }

  @media (min-width: @breakpoints[ml] + 1px) {
    &__table-row:hover td,
    &__table-row._open td {
      background-color: @c-grey-lightest;
    }
  }

  & .hide-particle__placeholder::after {
    display: block;
    opacity: 0;
  }

  &__table-row:hover .hide-particle__placeholder::after,
  &__table-row._open .hide-particle__placeholder::after {
    opacity: 1;
  }

  &__amount {
    display: inline-block;
    min-width: 1.7rem;
    height: 1.7rem;
    background: white;
    box-shadow: 0 0 0.7rem fade(#a2a2a2, 50);
    border-radius: 0.3rem;
    opacity: 1;

    padding: 0 0.4rem;
    text-align: center;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-semi-bold;

    transition: @time;

    &._absolute {
      position: absolute;
      top: 1.2rem;
      left: 1.5rem;
    }
  }

  &__table-row._open &__amount {
    opacity: 0;
  }

  &__container-item {
    padding: 0;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    transition: border-collor @time;
    display: flex;

    &:last-child {
      margin-bottom: -1px;
    }
  }
  &__container-item-row {
    flex: 1;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    transition: border-collor @time;

    &:last-child {
      border-right: none;
    }
  }

  &__container-item &__cell-content {
    border-bottom: 1px dashed transparent;
    transition: border-bottom @time;
    &._customer {
      border-right: 1px dashed transparent;
    }
  }

  &__table-row._open
    &__container-item
    &__commodity-row:not(:last-child)
    &__cell-content {
    &:not(._customer) {
      border-color: @c-grey-lighter;
    }
  }

  &__table-row._open &__container-item &__cell-content {
    &._customer {
      border-right-color: @c-grey-lighter;
    }
  }

  &__table-row._open &__container-item {
    border-color: @c-grey-lighter;
  }

  &__table-row._open &__container-item-row {
    border-color: @c-grey-lighter;
  }

  &__container-item &__commodity-row {
    &::before {
      display: none;
    }
  }

  &__commodity-row {
    position: relative;
    display: flex;
    padding: 0;
    flex-grow: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 1rem;
      right: 2rem;
      height: 1px;
      border-bottom: 1px dashed #dbdbdb;
      opacity: 0;

      transition: @time;
    }

    &._main::before {
      display: none;
    }
  }

  &__table-row._open &__commodity-row::before {
    opacity: 1;
  }

  &__toggle {
    padding: 0;
    min-width: 2.2rem;
    min-height: 2.2rem;
    background: transparent;
    box-shadow: 0 0 0.6rem fade(#bbbbbb, 50);

    & svg {
      width: 0.8rem;
      height: 1rem;
      transform: rotate(90deg);
      fill: @c-grey-darker;
    }

    &:hover {
      background-color: @c-blue;
    }

    &:hover svg {
      fill: white;
    }
  }

  &__table-row._open &__toggle,
  &__card._open &__toggle {
    background: @c-blue;

    & svg {
      transform: rotate(270deg);
      fill: white;
    }
  }

  &__commodity-cell {
    position: relative;
    transition: background-color @time;
    padding: 0;
  }

  &__commodity-cell._sum {
    background-color: white;
  }

  &__cell-content {
    position: relative;
    padding: 1.2rem 1rem;

    &._center {
      align-self: center;
    }
  }

  &__cell-list &__cell-content {
    padding-top: 1.3rem;
  }

  &__head-cell._file,
  & td._file {
    width: 18rem;
    flex-grow: 1;
  }

  //&__head-cell._commodity-info,
  &__cell-content._commodity-info {
    //width: 20rem;
    flex: 1;
    width: 0;
    //cursor: zoom-in;
  }

  &__head-cell._customer,
  &__cell-content._customer {
    width: 20rem;
    flex-shrink: 0;
  }

  &__head-cell._invoice,
  &__cell-content._invoice {
    width: 10rem;
  }

  &__head-cell._balance,
  &__cell-content._balance {
    width: 10rem;
  }

  &__head-cell._status,
  &__cell-content._status {
    width: 12rem;
  }

  &__cell-content._status {
    //text-transform: uppercase;
  }

  &__head-cell._commodity-status,
  &__cell-content._commodity-status {
    width: 14rem;
  }

  &__head-cell._commodity-container-return-locations,
  &__cell-content._commodity-container-return-location {
    width: 12rem;
  }

  &__head-cell._commodity-notes,
  &__cell-content._commodity-notes {
    width: 6rem;
    flex-shrink: 0;
  }

  &__head-cell._date,
  & td._date {
    width: 10rem;
  }

  &__head-cell._container-loaded-drop-location,
  & td._container-loaded-drop-location {
    width: 14rem;
  }

  &__head-cell._container-size-and-weight,
  & td._container-size-and-weight {
    width: 12rem;
  }

  &__cell-container-item {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }

  // - - - -

  &__short-filter {
    width: 100%;
    margin-bottom: 1rem;

    & .dropdown__btn {
      border-color: @c-green;
      background-color: @c-green;

      color: white;
    }

    & .dropdown__btn:hover {
      border-color: @c-green-light;
      background-color: @c-green-light;
    }

    & .dropdown__arrow svg {
      fill: white;
    }
  }

  &__short-filter .dropdown__dropdown {
    width: 100%;
  }

  &._short &__header {
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__short-item {
    display: flex;
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    //background: @c-grey-lightest;

    cursor: pointer;

    &._selected {
      background-color: @c-blue-2;
    }
  }

  &__short-checkbox {
    flex-shrink: 0;
    margin-right: 2rem;
  }

  &__short-item-content {
    flex-grow: 1;
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  &__short-el {
    font-size: 1.2rem;

    & strong {
      font-weight: @w-regular;
      color: @c-grey;
    }

    //&::after {
    //  content: '|';
    //  margin: 0 0.4rem;
    //
    //  color: @c-grey-light;
    //}
    //
    //&._last::after,
    //&:last-child::after {
    //  display: none;
    //}
  }

  &__short-filter-select {
    width: 100%;
  }

  &__cards {
    padding: 1.6rem;
  }

  &__card {
    min-height: 9rem;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 1.1rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    background-color: white;
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    &:last-child {
      margin-bottom: 0;
    }

    &-info {
      padding-right: 3rem;

      &-item {
        margin-bottom: 0.3rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-actions {
      position: absolute;
      padding: 1.1rem;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > * {
        margin-bottom: 1.2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-commodity {
      padding: 1rem 3rem 1rem 0;
      position: relative;
      &-actions {
        position: absolute;
        right: 0;
        top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > * {
          margin-bottom: 1rem;
        }
      }
      &._first {
        margin-top: 1rem;
        border-top-style: solid;
      }
      &:not(._first) {
      }
      border-top: 1px dashed #e0e0e0;

      &:last-child {
        padding-bottom: 0;
      }

      &-row {
        //margin-bottom: .3rem;
        position: relative;
        //&:last-child {
        //  margin-bottom: 0;
        //}
      }

      .files-list__amount {
        right: auto;
        left: 0.2rem;
        top: 0.2rem;
      }

      .preview-commodity__photo-wrap {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
