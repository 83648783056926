.booking-list {
  @media (max-width: @breakpoints[ml]) {
    &__header {
    }
  }
  @media (max-width: @breakpoints[md]) {
    //padding: 1rem;

    &__header {
      padding: 1rem 1.6rem;
    }

    &._short {
      display: none !important;
    }

    &__item {
      padding: 1.1rem;
      background-color: white;
      border: 1px solid #e0e0e0;
      border-radius: 0.4rem;
      margin-bottom: 1.4rem;
      position: relative;
      font-size: 1.2rem;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__item-actions {
      position: absolute;
      top: 0.7rem;
      right: 0.7rem;
    }

    &__mob-list {
      padding: 1.6rem;
    }
  }

  @media (max-width: @breakpoints[sm]) {
    &__header {
      flex-direction: column;
    }
    &__only-booking {
      font-size: 1.2rem;
      margin-top: 0.5rem;
    }
  }
}
