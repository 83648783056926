.aes-itn-edit {
  &__steps {
    margin-bottom: 1.6rem;
  }

  &__step-block {
    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    background: white;

    transition: @time;
  }
}
