.commodity-customer {
  @media (max-width: @breakpoints[md]) {
    &__options-wrap {
      .page-options-menu {
        .dropdown__btn {
          padding-left: 0;
        }

        .dropdown__btn-text {
          display: none;
        }

        .dropdown__dropdown-inner {
          min-width: 16rem;
        }
      }
    }

    &__status {
      width: max-content;
    }
  }

  @media (max-width: @breakpoints[sm]) {
    &__main-info-wrap {
      padding-bottom: 3rem;
    }

    &__main-info-row {
      flex-wrap: wrap;
    }

    &__main-info-row._wrap {
      flex-direction: column;
    }
  }
}
