.notes-list-simple {
  list-style: none;
  margin: 0;
  padding: 0;

  font-size: 1.2rem;

  & li {
    margin: 0.6rem 0;
    position: relative;
    padding-left: 1rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      position: absolute;
      content: '•';
      left: 0;
    }
  }
}
