.payments-received-page {
  &__main {
    // padding-right: @page-sides-padding;
  }

  &__main-content {
    background: white;
    display: flex;
  }

  &__payments-list {
    min-width: 29rem;
    flex: 1 1 29rem;
    border-right: 1px solid @c-grey-lighter;
  }

  &__one-payment {
    flex: 1 0 calc(100% - 29rem);
  }
}
