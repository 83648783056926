.one-person {
  position: relative;

  &__info {
    margin-bottom: 2rem;
  }

  &__tabs {
    margin-top: 1.5rem;
    height: 20rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &._full {
      margin-top: 0;
      height: auto;
    }
  }
}
