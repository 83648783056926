.new-commodity-page {
  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    //padding-right: @page-sides-padding;
  }

  &__main-content {
    position: relative;
    background: white;
    flex-grow: 1;
  }

  &__head {
    padding: 1.5rem 2rem 0;
  }

  &__back-btn {
    .reset-button;
    font-size: 1.2rem;
    align-items: center;
    font-weight: @w-semi-bold;
    color: @c-grey-darkest;

    svg {
      width: 0.6rem;
      height: 1rem;
      transform: scaleX(-1);
      fill: currentColor;
      margin-right: 0.9rem;
      flex-shrink: 0;
    }
  }
}
