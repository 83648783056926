//@full-gallery-preview-width: 7rem;

.full-gallery {
  height: 50%;
  flex-grow: 1;
  //overflow-y: auto;

  &__inner {
    padding: 3rem 2.4rem 6rem;
  }

  &__manipulation {
    position: sticky;
    z-index: 11;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: fade(white, 90%);
    bottom: 1rem;
    left: 0;
    box-shadow: 0 0 1rem fade(black, 20%);
    border-radius: 0 @radius @radius 0;

    transition: @time-modal;
  }

  &__short-btn {
    margin-right: 1rem;

    &.btn {
      border-color: @c-grey-light;
    }

    &._print {
      & svg {
        fill: @c-print;
      }

      &:hover {
        border-color: @c-print;
        background-color: @c-print;
      }
    }

    &._email {
      & svg {
        fill: @c-email;
      }

      &:hover {
        border-color: @c-email;
        background-color: @c-email;
      }
    }

    &._viber {
      & svg {
        fill: @c-viber;
      }

      &:hover {
        border-color: @c-viber;
        background-color: @c-viber;
      }
    }

    &._whatsapp {
      & svg {
        fill: @c-whatsapp;
      }

      &:hover {
        border-color: @c-whatsapp;
        background-color: @c-whatsapp;
      }
    }
  }

  &__selected {
    color: @c-grey;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__unselected-all {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: @c-grey;

      transition: @time;
    }

    &:hover svg {
      fill: @c-red;
    }
  }

  &__main-gallery {
    width: 100%;
    max-width: 50rem;
    margin: 0 auto 2rem;

    & .gallery-main__carousel {
      padding-top: 80%;
    }

    &._open-in-lightbox {
      .gallery-main__carousel {
        padding-top: 0;
        height: 37.2rem;
      }
    }
  }

  &__previews-gallery {
    margin-bottom: 4rem;
  }

  &__previews-gallery,
  &__section-gallery {
    & .gallery-previews__carousel {
      display: flex;
      justify-content: center;
    }

    & .gallery-previews__list {
      max-width: 100%;
      width: auto;
    }

    & .gallery-previews__item {
      width: 10rem;
      margin-right: 1rem;
      padding-top: 7.3rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__section {
    position: relative;
    margin-bottom: 4.3rem;
    padding: 1rem;

    border: 1px solid @c-grey-lighter;
    border-radius: @radius;
    //border-bottom: 1px dashed @c-grey-lighter;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__section-title {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: @c-grey-lighter;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5rem 1rem;
    border-radius: @radius @radius 0 0;

    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;

    & a {
      color: inherit;
      text-decoration: none;
    }

    & a:hover {
      text-decoration: underline;
    }
  }

  &__section-title-side-cols {
    width: 0;
    flex-grow: 1;

    &._left {
      text-align: left;
      padding-right: 2rem;
    }

    &._right {
      text-align: left;
      padding-left: 2rem;
    }
  }

  &__section-jump-to {
    margin-right: auto;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-regular;
  }

  &__section-gallery {
  }

  &__section-gallery {
  }
}
