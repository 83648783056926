.person-info {
  @media (max-width: @breakpoints[sm]) {
    padding-left: @page-sides-padding-sm;
    padding-right: @page-sides-padding-sm;

    &--separate-content {
      height: auto;
      overflow: visible;
    }

    &__header {
      //padding-top: 0;
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
      display: block;
      //border-bottom: 0;
    }

    &__photo-wrap {
      margin-top: 0;
      margin-bottom: 0;
      width: 4.8rem;
      height: 4.8rem;
    }

    &__account-number {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0.8rem;
    }

    &__vendor-categories {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0.8rem;
    }

    &__profile-wrap {
      width: 100%;
      margin-bottom: 0.8rem;
    }

    &__header-content {
      width: 100%;
      border: 1px solid @c-grey-light-2;
      border-left: 0;
      border-right: 0;
      padding-bottom: 0.8rem;
      padding-top: 0.8rem;
    }

    &__status {
      margin-right: auto;
    }

    &__section {
    }
  }
}
