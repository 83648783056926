.info-block {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: @c-grey-lightest;
    min-height: 4rem;
    padding: 0.6rem 2rem;

    &._toggle {
      cursor: pointer;
    }
  }

  &__header._blue-light {
    background: @c-blue-lighter;

    color: white;
  }

  &__header._blue {
    background: @c-blue;

    color: white;
  }

  //&__header._error {
  //  background: @c-orange;
  //
  //  color: white;
  //}

  &__header-photo-wrap {
    height: 4rem;
    width: 4rem;
    background: @c-grey-light-2;
    margin-right: 0.8rem;
    margin-top: -0.6rem;
    margin-bottom: -0.6rem;
  }

  &__header-photo {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__ico {
    margin-right: 0.8rem;
  }

  &__title-wrap {
    flex-grow: 1;
    margin-right: 2rem;
    display: flex;
    align-items: center;
  }

  &__title {
    padding-top: 0.2rem;
    margin: 0 2rem 0 0;
    flex-grow: 1;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    text-transform: uppercase;
  }

  &__title-wrap &__title {
    flex-grow: 0;
    margin-right: 1rem;
  }

  &__title-wrap .notes-btn {
    margin: 0;
  }

  &__title-edit-btn {
    border: 0;
    padding: 0.4rem 0.4rem 0.2rem;
    background: transparent;
    cursor: pointer;

    & svg {
      fill: @c-grey-darker;

      transition: @time;
    }

    &:hover svg {
      fill: @c-green-light;
    }
  }

  &__header-error-ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background: @c-orange;
    margin-left: 0.5rem;
    margin-right: 1rem;

    color: white;
    font-size: 1.5rem;
    font-weight: @w-bold;
  }

  &__filled {
    display: flex;
    margin-right: 1rem;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
  }

  &__filled-list {
    opacity: 0.7;
    margin-left: 0.6rem;
  }

  &__toggle {
    margin-left: 1rem;

    & svg {
      transform: rotate(90deg);
    }
  }

  &._open > &__header &__toggle svg {
    transform: rotate(270deg);
  }

  &__header-btn {
    margin-left: 1rem;
  }

  &__main {
    padding: 3rem 2rem;
    position: relative;
    font-size: @f-size-small;
    line-height: @f-lineheight-small;

    transition: padding-top @time-modal, margin-top @time-modal;

    &._inner {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__link {
    text-decoration: underline;
    color: @c-blue;
  }

  &__row {
    margin-top: 3rem;
    position: relative;

    @media (max-width: @breakpoints[md]) {
      margin-top: 2rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__cell-with-delete {
    display: flex;
    align-items: flex-start;
  }

  &__delete.btn {
    margin-right: 1rem;
    margin-top: 0.6rem;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      background: @c-red-light;

      & svg {
        fill: white;
      }
    }
  }

  &__note-checkbox {
    margin-top: 0.7rem;
    margin-left: 1rem;
  }

  &__error-wrap {
    padding: 0 2rem;
  }

  &__error {
    margin-bottom: 2rem;
  }

  &__footer {
    padding: 1rem 2rem 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &._inner {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    &._marg-top {
      margin-top: 2rem;
    }

    &-row {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-wrap: wrap;
      grid-row-gap: 2rem;
    }

    &-actions {
      margin-left: auto;
    }
  }

  &__footer-separator {
    margin: 0 auto;
    width: 1rem;
  }

  &__footer-checkbox {
    margin-left: 2rem;
  }

  &__light-btn {
    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    font-weight: @w-semi-bold;
    text-transform: uppercase;
    margin-right: 2.2rem;
    outline: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    background: none;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;

      color: @c-blue;
    }

    &:last-child {
      margin-right: 0;
    }

    &._disabled {
      //pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__btn-additional.btn {
    position: relative;
    z-index: 3;
    background: white;
    //padding-right: 0.6rem;
  }

  &__btn-additional-wrap {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 1%;
      width: 99%;
      height: 0;
      transform: translateY(-1px);
      border-bottom: 1px dashed @c-grey-lighter;
    }
  }

  &__btn {
    margin-left: 1rem;
  }

  &__btn-left {
    margin-right: 1rem;
  }

  // - - - -

  &__nested-info-block {
    border: 1px solid @c-grey-lighter;
    width: calc(100% - 1rem);
    margin: 0.5rem auto;

    &:first-child {
      //margin-top: 3rem;
    }
  }

  &__nested-info-block + &__main {
    margin-top: 3rem;
    border-top: 1px solid @c-grey-lighter;
  }

  &__nested-info-block._open + &__main {
    margin-top: 0;
    padding-top: 6.5rem;
    border-top: 1px solid @c-grey-lighter;
  }

  &__nested-info-block + &__footer {
    padding-top: 2rem;
  }

  &__nested-info-block._open {
    //margin: 3rem 0 2rem;
  }

  &__logs {
    margin: -1rem 2rem 1.4rem;
  }

  // - - - -

  &__info-table {
    margin: 0;
    //width: 100%;

    & td {
      padding: 1.2rem 0;
      //border: 0;
    }

    & td:first-child {
      position: relative;
      padding-right: 1rem;
      width: 21rem;
      vertical-align: top;
      max-width: 40%;

      color: @c-grey;

      &::after {
        content: ':';
        position: relative;
        z-index: 2;
        background: white;
        padding-right: 0.3rem;
      }

      & > span {
        position: relative;
        background: white;
        z-index: 2;
      }

      & > span::before {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 0.4rem;
        left: 0;
        width: 20.5rem;
        height: 1px;
        border-bottom: 1px dotted @c-grey-lighter;
      }
    }

    & tr:last-child td {
      //padding-bottom: 0;
    }
  }

  &__section-title {
    margin: 2rem 0 2rem;

    font-size: 1.8rem;

    &:first-child {
      margin-top: 0;
    }
  }

  // - - - -

  &__group {
    position: relative;
    width: calc(100% - 4rem);
    margin: 3.5rem auto 2rem;
    border: 1px solid @c-grey-lighter;
  }

  &__main &__group {
    width: calc(100% - 2rem);
  }

  &__group-title {
    position: absolute;
    top: -1rem;
    left: 1.7rem;
    background: @c-grey-lightest;
    background: white;
    padding: 0.3rem 0.5rem;
    margin: 0;

    text-transform: none;
    font-size: 1.2rem;
  }

  &__group-delete.btn {
    position: absolute;
    top: -1.3rem;
    right: 2rem;
    background: white;
  }

  &__group-edit.btn {
    position: absolute;
    top: -1.3rem;
    right: 5.4rem;
    background: white;
  }

  &__group + &__main {
    padding-top: 1rem;
  }

  // - - - -

  &__light-table {
    width: 100%;
  }

  // - - - -

  &__table-wrap {
    padding: 1rem;
    overflow: auto;
  }

  // - - - -

  &__cell-title {
    margin: 0 0 0.7rem;

    font-size: 1.2rem;
    color: #8a8a8a;
    font-weight: @w-regular;
  }

  &__actions {
    width: 100%;
  }

  &__checkbox {
    margin-top: 0.5rem;

    .checkbox__text {
      font-size: 1.1rem;
    }
  }
}
