.customer-commodities {
  @media (max-width: @breakpoints[xxl]) {
    &__table {
      & th._status-location,
      & td._status-location {
        width: 16rem;

        & .search-table-col {
          width: 16rem - 2rem;
        }
      }
    }

    &__scroll-block &__table {
      & th._status-location,
      & td._status-location {
        width: 16rem;
      }
    }
  }

  @media (max-width: @breakpoints[xl]) {
    &__table {
      & th:nth-child(2),
      & td:nth-child(2) {
        width: 15rem;

        & .search-table-col {
          width: 15rem - 2rem;
        }
      }

      & th:nth-child(3),
      & td:nth-child(3) {
        width: 18rem;

        & .search-table-col {
          width: 18rem - 2rem;
        }
      }
    }

    &__photo-wrap {
      display: none;
    }
  }

  @media (max-width: @breakpoints[md]) {
    &__head {
      align-items: center;
      padding: 1.6rem @page-sides-padding-sm 2rem;
      flex-wrap: wrap;
    }

    &__select-wrap {
      width: 100%;
      display: flex;
      align-items: center;

      .select {
        flex-grow: 1;
      }
    }

    &__search {
      width: 100%;
      margin-top: 1rem;
      background-color: white;
    }

    &__content-wrap {
      //display: block;
      //overflow: visible;
      //height: auto;
    }

    &__content-mob {
      padding-left: @page-sides-padding-sm;
      padding-right: @page-sides-padding-sm;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;

      .list-footer {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__list-mob {
      position: relative;

      &-item {
        padding: 1.1rem;
        background-color: white;
        border: 1px solid #e0e0e0;
        border-radius: 0.4rem;
        margin-bottom: 0.8rem;
        position: relative;
        font-size: 1.2rem;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }

        &-params {
          display: flex;
          flex-wrap: wrap;
          margin-right: -1rem;

          & > div {
            flex-grow: 1;
            width: 40%;
            margin-top: 1rem;
            margin-right: 1rem;
            flex-basis: 28rem;
          }
        }

        .customer-commodities__notes-btn {
          position: absolute;
          top: 1.1rem;
          right: 1.1rem;

          & ~ .preview-commodity {
            margin-right: 4rem;
          }
        }
      }
    }
  }

  @media (max-width: @breakpoints[sm]) {
    height: auto;
    background-color: transparent;

    &__content-wrap {
      display: block;
      overflow: visible;
      height: auto;
    }

    &__content-mob {
    }
  }
}
