.add-new-document-type {
  &__input {
    margin-bottom: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
