.shipping-rates-destinations {
  @media (max-width: @breakpoints[lg]) {
    &__groups-list {
      position: static;
      width: 100%;
      height: auto;
      overflow-y: unset;
    }
    &__select-wrapper {
      padding: 10px 18px;
    }
  }
}
