.commodity-table {
  width: 100%;

  & th._checkbox {
    width: 5rem;
  }

  & th._descr {
    width: 24%;
  }

  & th._note {
    width: 20%;
  }

  &__help-list {
    list-style: none;
    margin: 0;
    padding: 0;

    & li {
      margin: 0.6rem 0;
    }
  }

  & tr._selected {
    background: rgba(255, 233, 177, 0.4);
  }

  &__group-amount {
    display: inline-block;
    min-width: 2.5rem;
    min-height: 2.5rem;
    background: white;
    border-radius: 50%;
    text-align: center;
    padding: 0.4rem 0.3rem 0;

    font-weight: @w-semi-bold;
    color: @c-grey-dark;
  }

  &__groups-table tr._current &__group-amount {
    //background: @c-grey-lightest;
  }

  &__customer-profile {
    display: flex;
    align-items: center;
  }

  &__customer-photo-wrap {
    background: @c-grey-light-2;
    border-radius: 50%;
    overflow: hidden;
    width: 4.5rem;
    height: 4.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 1.5rem;
  }

  &__customer-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // - - - -

  &__descr {
    display: flex;
    align-items: center;
  }

  &__descr-main {
    width: 20rem;
    flex: 1 1 20rem;
  }

  &__photo-wrap {
    background: @c-grey-light-2;
    overflow: hidden;
    width: 6.5rem;
    height: 4.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 1.5rem;
    flex-shrink: 0;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__vin {
    margin-top: 0.4rem;
  }

  &__name,
  &__vin {
    width: 100%;
  }

  &__status {
    margin-bottom: 0.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
