.v-lightbox {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  overflow: hidden;

  transition-duration: @time-modal;
  transition-property: opacity;
  overscroll-behavior-x: contain;

  color: white;

  &__head {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 0 0 0 2rem;
    width: 100%;
  }

  &__show-count {
    margin: 0 3rem 0 0;
    font-weight: 600;
    color: mix(@c-blue, #ffffff, 15%);
  }

  &__btn,
  &__btn-slide {
    .reset-button();
    display: flex;
    align-items: center;
    justify-content: center;

    width: 5rem;
    height: 5rem;
    padding: 0;
    min-height: 0;
    border-radius: 0;
    //background-color: fade(black, 60);
    background-color: fade(@c-blue, 80);
    cursor: pointer;

    transition: background-color @time, opacity @time;

    @media (max-width: @breakpoints[md]) {
      background-color: fade(@c-blue, 95);

      width: 3.6rem;
      height: 3.6rem;
    }

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      opacity: 0.8;
      margin: 0;
      color: fade(white, 80);
      transition: color @time;
    }

    &._disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    &:not(._disabled):hover {
      background-color: @c-blue;
    }

    &:not(._disabled):hover svg {
      color: white;
    }

    &._lock {
      display: none;
    }
  }

  &__btn {
  }

  &__main {
    position: relative;
    width: 100%;
    max-height: calc(100% - 8rem);
    display: flex;
    flex-grow: 1;

    &._single {
      max-height: 100%;
    }
  }

  &__btn-slide {
    position: absolute;
    z-index: 20;
    top: 50%;
    transform: translateY(-50%);
    overflow: visible;

    &._prev {
      left: 0;
    }

    &._next {
      right: 0;
    }

    &._prev svg {
      transform: rotate(180deg);
    }

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 300%;
      height: 500%;
    }
  }

  &__list {
    width: 100%;
    max-height: 100%;
    display: flex;
    margin: 0;
    padding: 0;
  }

  &__item {
    position: relative;
    width: 100%;
    max-height: 100%;
    flex-shrink: 0;
    //box-shadow: inset 0 0 0 0.2rem red;
    display: flex;
    //padding: 4rem 0;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;
    //box-shadow: inset 0 0 0 1px blue;

    transition-duration: @time-modal;
    transition-property: transform;
    transform: translate3d(0, 0, 0);
  }

  //&__img-file {
  //  display: flex;
  //  flex-direction: column;
  //  align-items: center;
  //
  //  & svg {
  //    width: 10rem;
  //    height: 16rem;
  //    fill: white
  //  }
  //}

  &__item._zoom-out {
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 0;
    min-width: 0;

    .v-lightbox {
      &__img-wrap {
        width: 100%;
        height: 100%;
        max-height: none;
        max-width: none;
        min-height: 0;
        min-width: 0;
        overflow: hidden;
        justify-content: center;
        align-items: center;
      }

      &__img:not(._video) {
        max-width: 100%;
        max-height: 100%;
        min-height: 0;
        min-width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .file-show__file {
          max-width: 100%;
          max-height: 100%;
          min-height: 0;
          min-width: 0;
          width: auto;
          height: auto;
          display: inline-block;
          object-fit: fill;
          overflow: hidden;
        }
      }
    }
  }

  &__img-wrap {
    width: 100%;
    //max-height: calc(100% - 6rem);
    max-width: 100vw;
    max-width: 100dvw;
    max-height: 100vh;
    max-height: 100dvh;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;
    //height: 50%;
    flex: 0 1 auto;
    flex-shrink: 0;
    //min-height: 0;
    //max-width: 0;
    overflow: auto;
    margin: auto;
    padding: 4rem 2rem;
    overscroll-behavior-x: contain;
  }

  &__img {
    margin: auto;
    height: auto;
    width: auto;
    //max-width: 100%;
    //height: 100%;
    //max-height: 100%;
    //object-fit: contain;
    transition: opacity @time-modal;
    background: fade(black, 20%);
    //overflow: auto;

    &._loading {
      opacity: 0;
    }

    &._pdf {
      //height: 100%;
      //width: calc(100% - 10rem);
      width: 100% !important;
      height: 100% !important;
      .file-show__file {
        width: 100% !important;
        height: 100% !important;
      }
    }

    &._other {
      padding: 2rem;
      border-radius: 1rem;
    }

    &._other svg,
    &._other .file-show__file-ico {
      width: 13rem;
      height: 16rem;
      fill: white;
      object-fit: cover;
    }

    &._image._zoom .file-show__file {
      cursor: zoom-out;
    }

    .file-show__file {
      width: auto;
      height: auto;
    }

    &._image .file-show__file {
      cursor: zoom-in;
    }
  }

  //&__img-pdf {
  //  height: 100%;
  //  width: calc(100% - 10rem);
  //}

  &__title {
    margin: 2rem 0;
    flex: 0 0;

    font-size: 1.4rem;
    color: white;
    font-weight: @w-regular;
  }

  &__img-preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__footer {
    flex-shrink: 0;
  }

  &__img-error {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 0.6rem 1.2rem;
    background: @c-red;
    border-radius: @radius;

    & > span:last-child {
      font-size: 1.2rem;
      opacity: 0.8;
    }
  }

  // SUBLIST

  &__sublist {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    transition: opacity @time, visibility @time;
    //box-shadow: inset 0 0 0 2px red;

    &._zoom-active {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__sublist-container {
    width: 100%;
    //display: flex;
    //justify-content: center;
    overflow: hidden;
  }

  &__sublist-list {
    display: flex;
    width: 100%;
  }

  &__sublist-item {
    flex-shrink: 0;
    height: 8rem;
    width: 8rem;
    border-radius: @radius;
    border: 1px solid fade(white, 10%);
    overflow: hidden;
    margin-right: 1rem;
    background: fade(black, 50%);

    transition: @time;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-color: fade(white, 30%);
    }

    &.swiper-slide-thumb-active {
      border-color: fade(@c-blue, 60%);
    }
  }

  &__zoom-btn {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.8;

    svg {
      width: 3rem;
      height: 3rem;
      fill: currentColor;
    }
  }
}
