.settings-person {
  position: relative;

  &__section-title {
    margin: 0 0 1.5rem;

    font-size: 1.8rem;
  }

  &__referrer {
    margin-bottom: 2rem;

    font-size: 1.2rem;

    & p {
      margin: 0;
    }
  }

  &__main-info-table {
    width: 100%;
    margin: 0 0 3rem;

    font-weight: @w-semi-bold;

    & td:first-child {
      width: 30%;

      color: @c-grey;
      font-weight: @w-regular;
    }

    & tr:first-child td {
      border-top: 1px solid @c-grey-lighter;
    }
  }

  &__contacts-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  &__contact {
    width: 46%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px dashed @c-grey-lighter;

    &:nth-child(-n + 2) {
      border-top: 1px dashed @c-grey-lighter;
    }
  }

  &__contact-title {
    margin: 0 0 0.8rem;

    font-size: 1.4rem;
    font-weight: @w-regular;
  }

  &__contact-item {
    margin-bottom: 1rem;

    font-size: 1.2rem;
    font-weight: @w-semi-bold;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__contact-table {
    width: 100%;
    margin: 0;

    & td:first-child {
      font-weight: @w-regular;
      color: @c-grey;
    }

    & tr:first-child td {
      padding-top: 0;
    }

    & tr:last-child td {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
