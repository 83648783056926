.file-customer-documents {
  position: relative;

  //&__tabs {
  //  align-self: flex-start;
  //  display: flex;
  //  flex-direction: column;
  //  background: @c-grey-lightest;
  //  width: 24rem;
  //  border: 1px solid @c-grey-lighter;
  //  border-right: 0;
  //  border-radius: @radius 0 0 @radius;
  //  overflow: hidden;
  //}
  //
  //&__tab {
  //  .reset-button();
  //  cursor: pointer;
  //  padding: 1.1rem 1.5rem;
  //
  //  font-size: 1.4rem;
  //  font-weight: @w-semi-bold;
  //
  //  &:hover,
  //  &._active {
  //    background-color: white;
  //  }
  //}
  //
  //&__content-wrap {
  //  width: 30%;
  //  flex-grow: 1;
  //  background: white;
  //  border: 1px solid @c-grey-lighter;
  //  border-left: 0;
  //  border-radius: 0 @radius @radius 0;
  //}

  &__content {
    padding: 2rem 1rem;
    margin: -2rem;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  &__item {
    padding: 1rem;
  }

  &__add,
  &__file {
    width: 9rem;
    cursor: pointer;
  }

  &__add-main,
  &__file-main {
    width: 9rem;
    height: 9rem;
    border-radius: @radius;
    border: 1px dashed @c-grey-lighter;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    background: white;

    transition: @time;
  }

  &__add:hover &__add-main,
  &__file:hover &__file-main {
    border-color: @c-grey-light;
  }

  &__add-btn {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: @radius;
    background: @c-blue;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: white;
    }
  }

  &__add-name,
  &__file-name {
    text-align: center;
    font-weight: @w-semi-bold;
    line-height: 1.2em;
  }

  &__file-main {
    position: relative;
    border-style: solid;
    padding: 2.2rem 1rem 0.5rem;
    justify-content: flex-start;

    text-align: center;
    font-size: 1rem;
    line-height: 1.2em;
    color: @c-grey;
  }

  &__file-main-ico {
    width: 2.8rem;
    height: 2.8rem;
    fill: @c-blue;
    margin-bottom: 0.5rem;
  }

  &__file-check-wrap {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
  }

  &__error {
    margin-top: 1rem;
  }
}
