.booking-page {
  //min-height: 45rem;

  &._modal-content {
    //width: 100%;
    //margin-left: auto !important;
    //margin-right: auto !important;
  }

  .single-page__blank-wrap {
    &._modal-block-content {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__drop-menu {
    margin-left: 1rem;

    .dropdown__item {
      white-space: nowrap;
    }
  }
}
