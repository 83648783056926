.invoices-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  &__header {
    position: sticky;
    z-index: 32;
    top: @header-height;
    background: white;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @c-grey-lighter;
  }

  &__actions-wrap {
    display: flex;
    align-items: center;
  }

  .files-list__selected {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    @media (max-width: @breakpoints[md]) {
      flex-direction: row;
    }
  }

  @media (max-width: @breakpoints[md]) {
    .files-list__selected {
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      background-color: white;
      width: 100%;
      padding: 0.9rem 1.6rem;
    }
  }

  &__selection-toolbar {
    display: flex;
    align-items: center;
    transition: @time;
  }

  &__search,
  &__head-btn {
    margin-right: 1rem;
  }

  &__selected {
    margin: 0;

    font-size: @f-size-small;
    line-height: @f-lineheight-small;
    color: @c-grey;
  }

  &__table-wrap {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__table {
    width: 100%;
    margin: 0;

    & thead {
      position: sticky;
      top: @header-height + 5.5rem;
      z-index: 2;
    }

    th {
      border-bottom: 0;
    }

    & tbody tr:nth-child(even) td {
      background-color: @c-grey-lightest;
    }

    & td:not(._no-link) {
      cursor: pointer;

      transition: background-color @time;
    }

    & th:first-child,
    & td:first-child {
      width: 4.8rem;
    }

    & th:last-child,
    & td:last-child {
      width: 5.4rem;
    }
  }

  &__filters {
    background-color: white;
    position: sticky;
    bottom: 0;
    z-index: 2;
    border: 1px solid @c-grey-lighter;
    border-left: 0;
    border-right: 0;
    padding: 1.2rem 2rem;
    display: flex;
    gap: 1.5rem 2rem;
    margin-top: auto;
  }

  &__filter-select {
    width: 20rem;

    .select__option._disabled {
      display: none;
    }
  }

  &__dates-range {
    width: 25rem;
  }

  &__footer {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__showing-info {
    margin: 0 auto 0 0;

    font-size: 1.2rem;
  }

  &__info {
    padding-top: 1px;

    & > * {
      margin-bottom: 0.4rem;
    }
  }

  // - - - -

  &._short &__header {
    border-bottom: 1px solid @c-grey-lighter;
    top: 0;
  }

  &__short-item {
    display: flex;
    padding: 1rem 2rem;
    border-bottom: 1px solid @c-grey-lighter;
    //background: @c-grey-lightest;
    font-size: 1.2rem;
    cursor: pointer;

    &._selected {
      background-color: @c-blue-2;
    }
  }

  &__short-checkbox {
    flex-shrink: 0;
    margin-right: 2rem;
  }

  &__short-item-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
    width: 50%;
  }

  &__short-el {
    width: 48%;

    .no-wrap();

    &._right {
      text-align: right;
    }
  }

  // - - -
  &__mob-items-wrap {
    padding: 0 1.8rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__mob-item {
    width: 100%;
    max-width: calc(100% / 2 - 0.8rem);
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 0.4rem;
    padding: 1.2rem 4rem 1.2rem 0.8rem;
    margin-top: 1rem;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: @breakpoints[sm]) {
      max-width: none;
    }
  }

  &__mob-item-row {
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }

  &__mob-item-actions {
    position: absolute;
    padding: 1.1rem;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
      margin-bottom: 1.2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__unapplied-amount {
    color: @c-orange;
    cursor: help;
  }

  &__short-item-col {
    width: 48%;
    font-size: 1.2rem;

    &._right {
      text-align: right;
    }
  }

  &__short-item-cell {
    margin-bottom: 0.3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
