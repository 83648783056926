.customer-notes {
  @media (max-width: @breakpoints[md]) {
    &__btn {
      margin-right: 0;
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__table {
      display: block;

      tbody,
      thead {
        display: block;
      }

      thead {
        background-color: @c-grey-lighter;
      }

      tr {
        display: flex;
      }

      th:last-child {
        font-size: 0;
      }

      td,
      th {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-child {
          width: 24%;
          flex-shrink: 0;
          max-width: 8.7rem;
          padding-right: 0;
        }

        &:nth-child(2) {
          width: 40%;
          flex-grow: 1;
        }

        &:nth-child(3) {
          width: 18%;
          //flex-basis: 6.4rem;
        }

        &:last-child {
          width: auto;
          flex-basis: 5rem;
        }

        &:first-child:last-child {
          flex-basis: 100%;
          max-width: none;
          width: 100%;
        }
      }
    }

    & > &__table {
      tbody tr {
        td {
          width: auto;

          &:first-child,
          &:nth-child(3) {
            display: none;
          }

          &:nth-child(2) {
            padding-left: @page-sides-padding-sm;
          }

          &:last-child {
            width: 8rem;
          }
        }
      }
    }
  }

  @media (max-width: @breakpoints[sm]) {
    height: auto;
    &__scroll-block {
      height: auto;
      overflow: visible;
    }

    &__row {
      position: relative;
      flex-wrap: wrap;
      padding: 1em;
      border-bottom: 1px solid @c-grey-lighter;
    }

    &__table-head {
      display: none !important;
    }

    &__date {
      max-width: unset !important;
      width: 100% !important;
      border-bottom: 0;
      padding: 0 !important;
    }

    &__note {
      order: 4;
      width: 100% !important;
      padding: 0;
      border-bottom: 0;
      margin-top: 0.5em;
    }

    &__agent {
      width: 100% !important;
      order: 2;
      border-bottom: 0;
      padding: 0;
      font-weight: @w-bold;
    }

    &__menu {
      position: absolute;
      right: 0.2em;
      top: 0.2em;
      border: none;
    }
  }
}
