.commodity-label-edit {
  position: relative;

  &__name {
    margin-bottom: 2rem;
  }

  &__error {
    margin-top: 3rem;
  }

  &__footer {
    display: flex;
    margin-top: 3rem;
  }

  &__separator {
    margin: 0 auto;
    padding: 1rem;
  }

  &__btn {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
