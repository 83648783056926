.event-edit {
  position: relative;
  padding: 1rem 0;

  &__date-row {
    display: flex;
    margin-bottom: 2rem;
  }

  &__date-group {
    display: flex;
    flex-grow: 1;
  }

  &__date-separator {
    margin: 1.6rem 1rem 0;
    width: 0.8rem;
    height: 1px;
    background: @c-grey-lighter;
  }

  &__date {
    width: 10%;
    flex-grow: 1;
  }

  &__with-time-btn {
    padding-top: 0.6rem;
    margin-left: 1rem;
  }

  &__input {
    margin-bottom: 2rem;
  }

  &__calendar-list {
    margin-bottom: 2rem;

    & .select__option {
      //padding-top: 0;
    }
  }

  &__current,
  &__calendar-list-option {
    display: flex;
    align-items: center;
  }

  &__calendar-color {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    margin-right: 0.8rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    margin-right: 1rem;
  }
}
